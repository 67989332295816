import {
  auth,
  demo,
  examination,
  others,
  patient,
  purchase,
  settings,
  stripe,
  subscription,
  survey,
  teamMember,
  user,
  glaucoma,
} from './routes';
import { saveIOP } from './routes/glaucoma';

import AltrisRESTClient from './index';

export const bindAllApi = (thisArg: AltrisRESTClient) => {
  return {
    auth: {
      login: auth.login.bind(thisArg),
      logout: auth.logout.bind(thisArg),
      loginUserByGoogle: auth.loginUserByGoogle.bind(thisArg),
      resetConfirmation: auth.resetConfirmation.bind(thisArg),
      registerUser: auth.registerUser.bind(thisArg),
      checkEmailForgotPassword: auth.checkEmailForgotPassword.bind(thisArg),
      getInvitedUserData: auth.getInvitedUserData.bind(thisArg),
      updateInvitedUserData: auth.updateInvitedUserData.bind(thisArg),
    },
    others: {
      getCountries: others.getCountries.bind(thisArg),
      getProfessions: others.getProfessions.bind(thisArg),
      getOctProducers: others.getOctProducers.bind(thisArg),
      sendOcuPlanForm: others.sendOcuPlanForm.bind(thisArg),
    },
    examination: {
      createExamination: examination.createExamination.bind(thisArg),
      getExamination: examination.getExamination.bind(thisArg),
      updateExamination: examination.updateExamination.bind(thisArg),
      deleteExamination: examination.deleteExamination.bind(thisArg),
      getExaminationStatus: examination.getExaminationStatus.bind(thisArg),
      getSegmentationScans: examination.getSegmentationScans.bind(thisArg),
      getExamLayer: examination.getExamLayer.bind(thisArg),
      getExamLayersStatus: examination.getExamLayersStatus.bind(thisArg),
      setExamFavoriteScan: examination.setExamFavoriteScan.bind(thisArg),
      getMainETDRS: examination.getMainETDRS.bind(thisArg),
      getLayerETDRS: examination.getLayerETDRS.bind(thisArg),
      getGccETDRS: examination.getGccETDRS.bind(thisArg),
      getThicknessMap: examination.getThicknessMap.bind(thisArg),
      getMeasurementsScan: examination.getMeasurementsScan.bind(thisArg),
      updateMeasurementsScan: examination.updateMeasurementsScan.bind(thisArg),
      deleteMeasurementsScan: examination.deleteMeasurementsScan.bind(thisArg),
      getMeasManualScan: examination.getMeasManualScan.bind(thisArg),
      updateMeasManualScan: examination.updateMeasManualScan.bind(thisArg),
      getMeasPointsDistance: examination.getMeasPointsDistance.bind(thisArg),
      getMeasManualId: examination.getMeasManualId.bind(thisArg),
      getPathologiesCalcZone: examination.getPathologiesCalcZone.bind(thisArg),
      getProgressCommonPathologiesData:
        examination.getProgressCommonPathologiesData.bind(thisArg),
      getProgressCommonHeatmapData:
        examination.getProgressCommonHeatmapData.bind(thisArg),
      getProgressPathologyScansData:
        examination.getProgressPathologyScansData.bind(thisArg),
      getProgressionState: examination.getProgressionState.bind(thisArg),
      updateProgressionState: examination.updateProgressionState.bind(thisArg),
      getBothEyesOppositeEye: examination.getBothEyesOppositeEye.bind(thisArg),
      getProgressionReportMostSevereScans:
        examination.getProgressionReportMostSevereScans.bind(thisArg),
    },
    demo: {
      demoSendEmail: demo.demoSendEmail.bind(thisArg),
      demoReSendEmail: demo.demoReSendEmail.bind(thisArg),
      demoSendVerificationCode: demo.demoSendVerificationCode.bind(thisArg),
      demoSignUp: demo.demoSignUp.bind(thisArg),
    },
    patient: {
      createPatient: patient.createPatient.bind(thisArg),
      getSinglePatient: patient.getSinglePatient.bind(thisArg),
      updatePatient: patient.updatePatient.bind(thisArg),
      getAllPatients: patient.getAllPatients.bind(thisArg),
    },
    purchase: {
      confirmPurchase: purchase.confirmPurchase.bind(thisArg),
      getAllPurchaseType: purchase.getAllPurchaseType.bind(thisArg),
      getPurchaseTypeBySlug: purchase.getPurchaseTypeBySlug.bind(thisArg),
      paymentForPurchase: purchase.paymentForPurchase.bind(thisArg),
      resetConfirmation: purchase.resetConfirmation.bind(thisArg),
    },
    settings: {
      getSettings: settings.getSettings.bind(thisArg),
      saveSettings: settings.saveSettings.bind(thisArg),
    },
    stripe: {
      deletePaymentMethod: stripe.deletePaymentMethod.bind(thisArg),
      getPaymentIntent: stripe.getPaymentIntent.bind(thisArg),
      getPaymentMethod: stripe.getPaymentMethod.bind(thisArg),
    },
    subscription: {
      getPaymentStatistics: subscription.getPaymentStatistics.bind(thisArg),
      setSubscribePlan: subscription.setSubscribePlan.bind(thisArg),
      getSubscription: subscription.getSubscription.bind(thisArg),
      getSubscriptionDetails: subscription.getSubscriptionDetails.bind(thisArg),
      getSubscriptionServices:
        subscription.getSubscriptionServices.bind(thisArg),
      getUnsubscribePlan: subscription.getUnsubscribePlan.bind(thisArg),
    },
    survey: {
      sendSurvey: survey.sendSurvey.bind(thisArg),
    },
    teamMember: {
      deleteTeamMember: teamMember.deleteTeamMember.bind(thisArg),
      getAllTeamMembers: teamMember.getAllTeamMembers.bind(thisArg),
      inviteTeamMember: teamMember.inviteTeamMember.bind(thisArg),
      reinviteTeamMember: teamMember.reinviteTeamMember.bind(thisArg),
    },
    user: {
      checkUserEmail: user.checkUserEmail.bind(thisArg),
      createUserBackup: user.createUserBackup.bind(thisArg),
      deleteUserBackup: user.deleteUserBackup.bind(thisArg),
      getCodeUpdateUserPassword: user.getCodeUpdateUserPassword.bind(thisArg),
      getProgressionSettings: user.getProgressionSettings.bind(thisArg),
      getUserBackup: user.getUserBackup.bind(thisArg),
      getUserInfo: user.getUserInfo.bind(thisArg),
      updateProgressionSettings: user.updateProgressionSettings.bind(thisArg),
      updateReportSettings: user.updateReportSettings.bind(thisArg),
      updateUserInfo: user.updateUserInfo.bind(thisArg),
      updateUserPassword: user.updateUserPassword.bind(thisArg),
      verifyUser: user.verifyUser.bind(thisArg),
      sendContactUsForm: user.sendContactUsForm.bind(thisArg),
    },
    glaucoma: {
      getData: glaucoma.getGlaucomaData.bind(thisArg),
      getAllExams: glaucoma.getGlaucomaExaminations.bind(thisArg),
      getProgressState: glaucoma.getGlaucomaProgState.bind(thisArg),
      putProgressState: glaucoma.updateGlaucomaProgState.bind(thisArg),
      saveIOP: glaucoma.saveIOP.bind(thisArg),
    },
  };
};
