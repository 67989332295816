export default {
  dashboard: {
    error: {
      try_again: 'Try again',
    },
    banner: {
      title1: 'Please, contact',
      link: 'Altris Sales Team',
      title2: 'to extend your Subscription',
    },
    empty_data: {
      title: 'There are no patient’s added',
      subtitle: 'You can add new patient by click on',
      add_new_patient: 'add new patient',
    },
    header: {
      close_filter: 'Close Filter',
      open_filter: 'Filter',
      search_placeholder: 'Search Patient',
      examination_filter: 'Examination filter',
      filter_form: {
        show_scan_by_date: 'Show scan by date',
        all: 'All',
        today: 'Today',
        range: 'Range',
        from: 'From',
        to: 'To',
        created_by: 'Patients filter',
        doctor_select: 'Select doctor',
        apply: 'Apply',
        clear: 'Clear filter',
      },
      add_new_patient: 'Add new patient',
      add_new_examination: 'Add new examination',
      back: 'Back',
    },
    patient_list: {
      header: {
        patient_id: 'Patient ID',
        patient_name: 'Patient Name',
        birth_date: 'Birth date',
        gender: 'Gender',
        last_upload: 'Last upload',
        severity_level: 'Severity Level',
        description: 'Description',
      },
      item: {
        patient_id: 'Patient ID',
        patient_name: 'Patient Name',
        birth_date: 'Birth date',
        gender: 'Gender',
        last_upload: 'Last upload',
        severity_level: 'Severity Level',
        description: 'Description',
        edit: 'Edit',
        delete: 'Delete',
        back: 'Back',
        show_examinations: 'Show examinations',
      },
    },
  },
  options: {
    buttons: {
      add: 'add',
      examination: 'examination',
    },
  },
  contact_us_page: {
    contact_us: 'Contact us',
    fill_the_form: 'Please fill the form below',
    example_short: 'Ex',
    first_name: 'First name',
    email: 'Email',
    message: 'Your message',
    submit_form: 'Send the form',
    attach_file: 'Attach file',
    attach_screenshot:
      'You can attach a screenshot to your message to show any difficulties you encountered. This could help our support team find the right option for you more quickly',
  },
  demo_user_registration: {
    back: 'Back',
    banner_text_1: 'Any OCT',
    banner_text_2: 'Explore how AI analyses OCT:\nthe scans are inside',
    banner_text_3: 'OCT scan with Altris AI',
    first_step: {
      welcome: 'Welcome to Altris AI',
      email_label: 'Type your email to validate:',
      email: 'Email',
      info: 'Continuing implies your automatic consent for Altris AI to retain your email for the purpose of sending marketing materials.',
      submit_form: 'Send confirmation',
    },
    second_step: {
      enter_security_code: 'Enter security code',
      send_to: 'This security code was sent to:',
      submit: 'Confirm',
      resend_code: 'Send code again',
      seconds_short: 'sec',
    },
    third_step: {
      add_some_info: 'Add some info',
      full_name_label: 'Type your full Name',
      full_name_placeholder: 'Full Name',
      country_label: 'Country',
      country_placeholder: 'Choose country',
      oct_label: 'OCT system',
      oct_placeholder: 'Select your OCT system',
      profession_label: 'Profession',
      profession_placeholder: 'Choose a profession',
      decision_maker_label:
        'Are you a Decision maker in your Clinic/Institution?',
      promo_code_label: 'Promo code (optional)',
      promo_code_info: 'Contact Altris distributor or Altris Sales team',
      promo_code_placeholder: 'Enter Promo code',
      submit: 'Complete',
    },
  },
  education: {
    title: 'Join Altris Education',
    subtitle1:
      'Altris Education OCT will become your guide in OCT interpretation education. This mobile app is interactive, free, and available anytime and anywhere. Traditional educational methods, such as webinars, conferences, and atlases are not enough for modern learners: it is time to use free mobile apps.',
    education: 'Education',
    subtitle2:
      'In Altris Education OCT you will have access to millions of labeled OCT scans and will be a part of a community of eye care specialists at the same time. You will get a unique chance to upload controversial OCT scans and discuss them with colleagues from all over the world. With Altris Education OCT we set higher diagnostic standards which lead to better patient outcomes as a result.',
    learn_more: 'Learn more',
    download: 'Download Altris Education App',
    oct_scans: 'OCT scans',
    experts: 'Experts',
    retina_experts: 'Retina Experts label each scan',
    eye_care_specialists: 'Eye care specialists in the community',
    pathological_signs: 'pathological signs',
    pathologies: 'pathologies',
  },
  forgot_password_page: {
    first_step: {
      enter_email: 'Enter your email',
      email: 'Email',
      confirm: 'Confirm',
      or_sign_in: 'or Sign In',
    },
    second_step: {
      congratulations: 'Congratulations!',
      check_email: 'Please check your Email',
      back_to_sign_in: 'Back to Sign In',
    },
  },
  maintenance_mode: {
    title: 'Website under maintenance!',
    subtitle:
      'This website is currently undergoing scheduled maintenance. We should be back shortly.',
    refresh_page: 'Refresh page',
  },
  mobile_availability: {
    title: 'Welcome on Altris AI',
    subtitle:
      'Altris AI is only available to be used from PC. Please, open the Platform on your Personal Computer to get full experience.',
    link: 'Link',
    copy_link: 'Copy Link',
    mobile_subscription: {
      title: 'Subscriptions',
      subtitle: 'Choose your subscription plan to start using Altris AI.',
      services: 'Services',
    },
  },
  offline: {
    title: 'Your connection is lost!',
    subtitle: 'Please, check your Internet connection...',
  },
  examination: {
    unavailable: {
      title: 'Not enough data',
      subtitle:
        'There are not enough data in DICOM to review examination. Please, fill empty form at the top of the page.',
    },
    header: {
      patient_id: 'Patient ID',
      patient_name: 'Patient name',
      patient_name_placeholder: 'FirstName',
      patient_surname: 'Patient surname',
      patient_surname_placeholder: 'LastName',
      birth_date: 'Birth date',
      gender: 'Gender',
      gender_placeholder: 'Choose',
      examination_date: 'Examination date',
      notes: 'Notes',
      report_unavailable: 'Unfortunately, Reports aren’t available.',
      select_plan: 'Select plan',
      to_use_this_feature: 'to use this feature.',
      report: 'Report',
      export_unavailable:
        'Unfortunately, Export is not available for this scan.',
      export: 'Export',
      save: 'Save',
      patient_info: 'Patient info',
      male: 'Male',
      female: 'Female',
      other: 'Other',
      other_patient_examinations: "Other patient's examinations",
      examination_label:
        '{{name}} / exam.: {{data}} / Eye: {{eye}} / scan: {{scan}}',
    },
    tabs: {
      single_scan: 'SINGLE SCAN',
      pathologies_detection: 'PATHOLOGIES DETECTION',
      measurements: 'MEASUREMENTS',
      all_scans: 'ALL SCANS VIEW',
      both_eyes: 'BOTH EYES VIEW',
      comparison: 'THICKNESS COMPARISON',
      progression: 'PATHOLOGY PROGRESSION',
      glaucoma: 'GLAUCOMA RISK ANALYSIS',
    },
    single_scan: {
      severity: 'Severity',
      off: 'Off',
      thickness_map_off: 'Thickness map off',
      on: 'On',
      thickness_map_on: 'Thickness map on',
      average_thickness: '{{metrics}} mm average thickness, μm',
      layers_failed: {
        title: 'Something gone wrong while we analyzed layers.',
        subtitle: 'Please, write about this problem to support.',
      },
      retina_thickness_map: 'Retina thickness map',
    },
    pathologies_detection: {
      classification: 'Classification',
      no_access:
        'You have no access to segmentations. Please use contact us form inside your profile.',
    },
    measurements: {
      not_available: 'Measurements are not available for current examination.',
      failed:
        'Measurements failed, please write about this problem to support.',
      in_progress:
        'Measurements in progress, please check this page little bit later.',
      no_plan:
        "Your plan doesn't include Measurements analysis. Please upgrade your plan.",
      scan: {
        measurements: 'Measurements',
        auto: 'Auto',
        manual: 'Manual',
        segmentation: 'Segmentation',
        length: 'Length',
        add_measurements: 'Add measurements',
        no_lines: 'No lines for current scan',
        highlight: 'Highlight',
        horizontal: 'Horizontal',
        vertical: 'Vertical',
        reset_measurements: 'Reset measurements',
        no_pathologies: 'No pathologies for current scan',
      },
      table: {
        segmentation_area: "Segmentation's Area",
        segmentation_volume: "Segmentation's Volume",
        combined: 'Combined',
        separate: 'Separate',
        area_headings: {
          all_segmentations: 'All segmentations',
          b_scan_area: 'B-scan area',
          b_scan_area_view: 'B-scan area view',
          en_face_area_view:
            '<span>En face area view (mm<sup>2</sup> * 1000)</span>',
        },
        volume_headings: {
          all_segmentations: 'All segmentations',
          measured_volume_wrap: 'Measured volume<br />(ETDRS {{number}}mm)',
          measured_volume: 'Measured volume (ETDRS {{number}}mm)',
          en_face_area_view:
            '<span>En face volume view<br> (mm<sup>3</sup> * 1000)</span>',
          en_face_area_view_nl: 'En face volume view',
        },
        no_pathology: 'No pathologies for current scan',
      },
    },
    all_scans: {
      no_scans: {
        title: 'No scans with such parameters.',
        subtitle: 'Please, try another filters.',
      },
      severity: 'Severity',
    },
    both_eyes: {
      add_fellow_eye: 'Add fellow eye',
      no_plan:
        "Your plan doesn't include Both Eyes analysis. Please upgrade your plan.",
      severity: 'Severity',
      left_eye: 'Horizontal, Left (OS)',
      right_eye: 'Horizontal, Right (OD)',
      average_thickness: '{{metrics}} mm average thickness, μm',
      sync: 'Sync.',
      different_scan_number:
        'Two examinations have different number of scans so scroll can’t be synchronised.',
    },
    comparison: {
      add_examination: 'Add examination to compare',
      no_plan:
        "Your plan doesn't include Comparison analysis. Please upgrade your plan.",
      retina_thickness_difference: 'Retina thickness difference',
      retina_thickness_difference_tooltip:
        'Shows retina thickness difference between examinations, from the newest examination to oldest.',
      average_thickness: '{{metrics}} mm average thickness, μm',
      sync: 'Sync.',
      different_scan_number:
        'Two examinations have different number of scans so scroll can’t be synchronised.',
      severity: 'Severity',
    },
    progression: {
      no_plan:
        "Your plan doesn't include Progression analysis. Please upgrade your plan.",
      severity: 'Severity',
      exam_does_not_have_date: "One of the examinations doesn't have a date.",
      oldest_to_newest: 'Oldest - Newest',
      newest_to_oldest: 'Newest - Oldest',
      sort_by_date: 'Sort by date',
    },
    glaucoma: {
      add_fellow_eye: {
        button: '+ Add fellow eye',
        tooltip:
          "There is no Fellow eye in Patient's examination. Please add before proceed",
      },

      add_examination: 'Add another Examination',
      unavailable: {
        heading:
          'Glaucoma Risk analysis is<span> unavailable for this examination.</span> This could be due to:',
        pathologies_present:
          'Pathologies present in the scans affecting the GCC complex, making accurate calculation impossible.',
        other_factors: 'Other factors preventing accurate analysis.',
        add_exam: 'Add examintion',
      },

      alert: {
        red: {
          title: 'High level of glaucoma risk',
          description:
            'The noticeable difference in the thickness of the GCC between Superior(S) and Inferior (I) hemispheres indicate potential signs of glaucoma',
        },
        yellow: {
          title: 'Medium level of glaucoma risk',
          description:
            'While the variations detected in the thickness of the GCC of the Superior (S) and Inferior (I) hemispheres are not significant enough to cause concern, further examination and monitoring is recommended for a comprehensive understanding of risk',
        },
        green: {
          title: 'No risk of glaucoma',
          description:
            'The calculated thickness difference between the Superior (S) and Inferior (I) hemispheres thickness GCC falls within clinically acceptable parameters.',
        },
      },
      target: {
        gcc_analysis: 'GCC Analysis',
        gcc_thickness: 'GCC thickness',
        superior_inferior: 'Superior / Inferior',
      },
      info: {
        gcc_asymmetry: 'GCC Asymmetry',
        od: 'OD (Right)',
        os: 'OS (Left)',
        tooltip: {
          title1: 'The GCC Asymmetry Analysis',
          title2: ' section evaluates two indicators:',
          first_item1:
            'Asymmetry Index: This parameter gauges the imbalance between Superior and Inferior sectors and changes color according to the S/I Proportion:',
          first_item2: 'Green',
          first_item3: ' - No asymmetry',
          first_item4: 'Yellow',
          first_item5: ' - Moderate asymmetry',
          first_item6: 'Red',
          first_item7: ' - Asymmetry',
          second_item1: 'The ',
          second_item2: 'thickness of Superior and Inferior',
          second_item3: ' sectors separately.',
          description:
            'Based on a deviation of these indicators from the expected ranges, we calculate and display the Glaucoma risk alert.',
        },
        asymmetry_index: 'Asymmetry index',
        superior_thickness: 'Superior thickness',
        inferior_thickness: 'Inferior thickness',
        normal: 'Normal',
        glaucoma_alert: 'Glaucoma alert',
        iop: 'Intra Ocular <br />Pressure (IOP)',
        superior: 'Superior',
        inferior: 'Inferior',
      },
      hemisphere: {
        title: 'S-I Hemisphere GCC thickness asymmetry',
        heading: 'Hemisphere asymmetry',
        superior: 'Superior',
        inferior: 'Inferior',
        description: {
          description_items: {
            posterior_pole_asymmetry_analysis:
              '<strong>Posterior Pole Asymmetry Analysis</strong> combines mapping of the posterior pole retinal thickness with asymmetry analysis between eyes and between hemispheres of each eye.',
            posterior_pole_retina_thickness_map:
              '<strong>Posterior Pole Retina Thickness Map</strong> Displays the retinal thickness over the entire posterior pole (30° x 25° OCT volume scan) for each eye. Compressed Color Scale Used to localize even the smallest differences in retinal thickness. OS-OD Asymmetry Map Compares the left eye to the right eye.',
            analysis_grid_8x8:
              '<strong>8x8 Analysis Grid</strong> 8x8 grid is positioned symmetrically to the foveadisc axis. For each cell of the grid the mean retinal thickness is given.',
            hemisphere_analysis_displays:
              '<strong>Hemisphere Analysis Displays</strong> the asymmetry between the superior and inferior hemisphere. The fovea-disc axis is the horizontal symmetry line. For each cell of one hemisphere, the mean retina thickness is compared to the value in the corresponding cell for the opposite hemisphere.',
          },
          title: '<strong>Description</strong>',

          item1: 'S-I Hemisphere GCC thickness asymmetry',
          item2:
            ' llustrates GCC (RNFL+GCL+IPL) thickness asymmetry between Superior and Inferior Hemisphere.',
          item3: 'An 8x8 grid',
          item4:
            ' is imposed on a 6x6 mm macular area to illustrate the asymmetry in GCC thickness between the symmetrical quadrants of the Superior and Inferior Hemispheres.',
          item5:
            'The difference in GCC thickness is given in the symmetrical squares.',
          item6: 'NB!',
          item7:
            ' For a qualitative assessment of GCC thickness, it is necessary to take into account the presence of exudative changes in the macular zone of the retina and evaluate the GCC thickness data in a fellow eye.',
        },
      },
      progression: {
        heading: 'Glaucoma Risk Progression',
        add_exam: 'Add another examination to review Glaucoma progression',
        add_all_exam: 'All examinations',
        swiper: {
          total_change: 'Total Change',

          item: {
            edit: 'EDIT',
            normal: 'Normal',
            glaucoma_alert: 'Glaucoma alert',
          },
          tooltip: {
            type_error: 'Value must be a number',
            positive: 'The value must be greater than 0',
            max: 'Value is too high',
          },
        },
        modal: {
          title: 'Select examinations you want to add',
          description:
            'You have more than 8 allowed examinations, which can be added to Progression. Please ,select which you want to add',
          select_exam: 'Select all examinations',
          test: 'Test',
          scans: 'Scans',
          selected: 'Selected',
          select_button: 'Select',
        },
        graph: {
          superior_inferior_graph: {
            title: 'Superior / Inferior Thickness',
            tooltip: {
              asymmetry_index: 'Asymmetry index',
              superior_thickness: 'Superior thickness',
              inferior_thickness: 'Inferior thickness',
            },
            legend: {
              superior: '- Superior',
              inferior: '- Inferior',
            },
          },
          ocular_graph: {
            title: 'Intra Ocular Pressure (IOP)',
          },
        },
      },
    },
  },
  onboarding_page: {
    title: 'Onboarding',
    all_scans: 'All scans',
    both_eyes: 'Both eyes',
    comparison: 'Comparison',
    create_examination: 'Create examination',
    create_patient: 'Create patient',
    header: 'Header',
    measurements: 'Measurements',
    pathologies_detection: 'Pathologies Detection',
    progression: 'Progression',
    report: 'Report',
    single_scan: 'Single scan',
  },
  register_user_page: {
    step_one: {
      sign_up_with_google: 'Sign up with Google',
      or: 'or',
      sing_up_with_email: 'Create an account with email',
      email_label: 'Corporate E-mail',
      website_label: 'Institution Website',
      first_name_label: 'First Name',
      last_name_label: 'Last Name',
      next: 'Next',
      or_sign_in: 'or Sign in',
    },
    step_two: {
      create_an_account: 'Create an account',
      password_label: 'Create a password',
      repeat_password_label: 'Repeat a password',
      next: 'Next',
      back: 'Back',
    },
    step_three: {
      create_an_account: 'Create an account',
      profession_placeholder: 'Choose a profession',
      country_placeholder: 'Your country',
      i_agree_with: 'I agree with',
      privacy_policy: 'Privacy Policy',
      and: 'and',
      terms_and_conditions: 'Terms and conditions',
      back: 'Back',
    },
    step_thankyou: {
      title: 'Congratulations!',
      subtitle: 'Your account has been created successfully!',
      button: "Let's get started",
    },
  },
  reset_password_page: {
    enter_new_password: 'Enter your new password',
    password_label: 'Create a password',
    repeat_password_label: 'Repeat a password',
    next: 'Next',
  },
  settings_page: {
    tabs: {
      profile: 'Profile',
      report_settings: 'Report settings',
      progression_settings: 'Progression settings',
      functions: 'Functions',
      change_password: 'Change password',
      accounts_data: "Account's data",
    },
    accounts_data: {
      title: "Account's data",
      subtitle:
        'Create a copy of content in your Altris AI Account to back it up.',
      no_examinations: 'You have no examinations created to create a backup',
      request_data: 'Request personal data',
      in_progress: 'Your data is creating. Please wait a little bit',
      cancel_backup: 'Cancel create user backup',
      download_data: 'Download data',
      download_info:
        'Here is your data, you can download it by clicking the button above. This zip archive will be saved to {{date}}. After this date, you can create backup one more time',
      delete_data: 'Delete personal data',
      delete_info:
        'Also you can delete this zip archive and create backup one more time',
      code_send: 'Confirmation code was sent on your Email.',
      attempts_left: 'Attempts left',
      example_short: 'Ex',
      confirm: 'Confirm',
      cancel: 'Cancel',
    },
    change_password: {
      password_label: 'Your previous password',
      password_placeholder: 'Enter your password',
      new_password_label: 'Your new password',
      new_password_placeholder: 'Enter your password',
      repeat_new_password_label: 'Repeat a password',
      repeat_new_password_placeholder: 'Enter your password',
      unable_to_change_password: "Sorry, you can't change your password",
      submit: 'Update my password',
      code_send: 'Confirmation code was sent on your Email.',
      attempts_left: 'Attempts left',
      example_short: 'Ex',
      confirm: 'Confirm',
      cancel: 'Cancel',
    },
    functions_settings: {
      title: 'Settings',
      subtitle: 'Change settings from the form below',
      save: 'Save settings',
      on: 'on',
      off: 'off',
    },
    profile: {
      example_short: 'Ex',
      first_name: 'First name',
      last_name: 'Last Name',
      email: 'Email',
      save: 'Save changes',
      country: 'Country',
      country_placeholder: 'Your country',
      profession: 'Profession / specialization',
      profession_placeholder: 'Choose a profession',
      language: 'Language',
    },
    progression_report: {
      title:
        'Select Pathologies you want to remove from tracking in Pathology Progression',
      subtitle:
        "If you turn off the pathology, it won't be shown in the pathology progression.",
      on: 'on',
      off: 'off',
      save_settings: 'Save settings',
    },
    report_settings: {
      clinic_name: 'Clinic name',
      clinic_email: 'Clinic email',
      save: 'Save changes',
    },
  },
  sign_in_page: {
    email_label: 'Corporate E-mail',
    password_label: 'Password',
    forgot_password: 'Forgot a password?',
    sign_in: 'Sign in',
    or: 'or',
    sign_in_with_google: 'Sign in with Google',
  },
  subscription_page: {
    b2c: {
      subscription: 'Subscription',
      billing: 'Billing',
      subscription_expired:
        'The subscription you are currently using has expired. Please upgrade to a new',
      subscription_page: 'Subscription page',
      unsubscribe: 'You can unsubscribe.',
      already_unsubscribed:
        'You have already unsubscribed from your old subscription plan. Your current plan will be active until {{date}}',
      choose_plan: 'Chose your perfect plan. No contracts. No surprise fees.',
    },
    common: {
      retina_layers_detected: 'Retina conditions detected by Altris AI',
    },
  },
  team_members_page: {
    title: 'Team members',
    invite_doctor: 'Invite doctor',
    max_doctors:
      'There is a maximum doctors you can invite. If you want to invite someone new, you must delete one from your account first',
  },
  components: {
    aside: {
      back: 'Back',
    },
    context_modal: {
      smart_report: {
        create_examination_report: 'Create examination report',
        standard: 'Standard',
        standard_plus: 'Standard+',
        advanced: 'Advanced',
        custom: 'Custom',
        progression: 'Progression',
        creating_report: 'Creating report...',
        loading_resources_for_report:
          'Loading resources for report{{percentage}}',
        cancel: 'Cancel',
        preview: 'Preview',
        components_loaded: 'Components loaded:',
        images_loaded: 'Images loaded:',
        heatmaps_loaded: 'Heatmaps loaded:',
        progression_report: {
          description1:
            'Visualizing the evolution of pathologies over time using B-Scan area, En-face Area, and Volumes. Please choose the specific pathologies of interest to include in the report.',
          important: 'Important Note:',
          description2:
            'Only select pathologies that are points of interest to prevent the report from becoming overly extensive.',
          select_pathologies: 'Select pathologies:',
          all: 'All',
          on: 'ON',
          off: 'OFF',
        },
        preset: {
          report_includes: 'Report includes:',
          severity_detection: 'Severity Detection',
          severity_detection_description:
            'Shows how many Severe Scans in the examination and How Severe they are',
          oct_description: 'Description of Altris AI OCT analysis',
          oct_description_description:
            'Includes Text description of Examination Analysis',
          retina_layers: 'Retina Layers Segmentation',
          retina_layers_description:
            'Represents all retina layers, highlighted in different colors for convenient use.',
          both_eyes: 'Both Eyes View',
          both_eyes_description:
            'Shows two opposite eyes to compare clinical condition.',
          both_eyes_unavailable:
            'You should add second scan inside "Both Eyes View" to enable this.',
          etdrs_calculations: 'ETDRS Calculations',
          etdrs_calculations_description:
            'Includes 1, 3, 6 mm Average thickness; Thickness of Several layers (If selected on the platform); Thickness of selected layer (If selected on the platform).',
          comparison: 'Thickness Comparison',
          comparison_description:
            'Includes Two examination of the same eye and thickness changes on ETDRS.',
          comparison_unavailable:
            'You should add second scan inside "Comparison" to enable this.',
          auto_measurements: 'Auto measurements',
          auto_measurements_description:
            'Includes Linear measurements of pathologies, calculated automatically.',
          manual_measurements: 'Manual measurements',
          manual_measurements_description:
            'Includes Linear measurements of pathologies, calculated manually (if Selected on the platform).',
          segmentation_area: 'Segmentation area',
          segmentation_area_description:
            'Shows the list of all pathologies in examination and their B-Scan and En face Areas.',
          segmentation_volume: 'Segmentation volume',
          segmentation_volume_description:
            'Shows the list of all pathologies in examination and their Volume (in mm^3 or nL).',
          pathology_detection:
            'Pathology Detection (3 most pathological Scans)',
          pathology_detection_description:
            'Includes three most pathological Scans, All Segmentations and Classification for those Scans.',
          referral_urgency_score: 'Referral Urgency Score',
          referral_urgency_score_description:
            'Shows how urgently further referral needed for this case depending on recognized Classifications.',
          glaucoma: 'Glaucoma risk analysis',
          glaucoma_description:
            'Glaucoma risks analysis is only available for examinations, where pathological signs don’t have affect on GCC',
        },
        custom_report: {
          analyze_other_scan: 'Analyze other scan',
          check_scan_that_you_want_to_analyze:
            'Check scan that you want analyze.',
          etdrs_calculations_description:
            'It is allowed only for Dicom files that include ETDRS information. Please, wait until ETDRS analysis has been successfully completed, for correct view in report.',
          auto_measurements_description:
            'Your measurement analysis should be finished to enable this.',
          auto_measurements_unavailable:
            'This service isn’t available in your Subscription. Please, Upgrade to Pro to use',
          manual_measurements_description:
            'Your measurement analysis should be finished, and you should draw a line inside manual measurements to enable this.',
          manual_measurements_unavailable:
            'This service isn’t available in your Subscription. Please, Upgrade to Pro to use',
          segmentation_area_description:
            'Your measurement analysis should be finished to enable this.',
          segmentation_area_unavailable:
            'This service isn’t available in your Subscription. Please, Upgrade to Pro to use',
          both_eyes: 'Both eyes',
          segmentation_volume_description:
            'Your measurement analysis should be finished to enable this.',
          segmentation_volume_unavailable:
            'This service isn’t available in your Subscription. Please, Upgrade to Pro to use',
          comparison: 'Comparison',
          selected_scan: 'Selected scan:',
          select_other: 'Select Other',
          added_scans: 'Added scans:',
          add_other: 'Add Other',
          no_selected_layers: 'No selected layers.',
          select_layers: 'Select Layers',
        },
      },
    },
    countdown_timer: {
      days: 'Days',
      hours: 'Hours',
      minutes: 'Mins',
      seconds: 'Seconds',
    },
    create_examination: {
      upload_archived_file:
        'Upload Archived folder (.zip), which was exported from your OCT. Mostly relevant for Heidelberg Engineering OCT.',
      create_patient_from_dicom: 'Create a New patient from a DICOM',
      create_patient_from_dicom_description:
        'Information about patient will be pulled from DICOM file',
      notes_label: 'Ex.: My note',
      notes_placeholder: 'Notes for examination',
      file_label: 'Upload DICOM to complete',
      multiple_file_label: 'Upload up to two DICOM files of the same patient',
      multiple_file_description:
        'Make sure they belong to one patient and include Right and Left eyes',
      scan_width_label: 'Scan width',
      scan_width_placeholder: 'Width',
      scan_width_message:
        'Make sure you selected the correct scan width as it affects on measurements in examination',
      eye_label: 'Eye',
      eye_placeholder: 'Type',
      examination_date: 'Examination date',
      upload_images_to_complete: 'Upload images to complete',
      right: 'Right',
      left: 'Left',
    },
    current_plan_info: {
      current_plan: 'Current plan',
      examinations_available: 'Examinations available',
      examinations_per_month: '{{count}} / Month',
      start_date: 'Start date',
      expiration_date: 'Expiration date',
      period: 'Period',
      amount: 'Amount',
      status: 'Status',
    },
    detailed_scan: {
      scan_detail: 'Scan detail',
    },
    drag_drop_list: {
      different_scan_count:
        'Two examinations have different number of scans so scroll can’t be synchronised',
      add: 'Add',
      examination: 'examination',
      no_scans_with_such_severity: 'No scans with such severity',
      average_thickness: '{{metrics}} mm average thickness, μm',
    },
    error_fallback: {
      something_went_wrong: 'Something went wrong',
      fix_problem: 'Fix problem',
    },
    etdrs_target: {
      single_layer_thickness: 'Single layer thickness',
      multiple_layers_thickness: 'Multiple layers thickness',
      gcc_thickness: 'GCC thickness',
    },
    examination_list: {
      empty: {
        title: 'Your project list is empty',
        subtitle: 'You can add new examination by pressing',
        add_new_examination: 'Add new examination',
      },
      header: {
        examinations: 'Examinations',
        type: 'Type',
        date: 'Date',
      },
      item: {
        eye: 'Eye',
        examination_failed: 'Examination failed',
        right_short: 'R',
        left_short: 'L',
        image: 'Image',
        edit: 'Edit',
        delete: 'Delete',
      },
    },
    exam_stat_info: {
      no_severity: 'No Severity (Green):',
      moderate_severity: 'Moderate Severity (Yellow):',
      severe: 'Severe (Red):',
      scans: 'Scans',
      severity: 'Severity',
    },
    feedback: {
      title: 'Customers feedbacks',
    },
    first_subscription: {
      subscription: 'Subscription',
      chose_plan: 'Chose your perfect plan. No contracts. No surprise fees.',
    },
    form_components: {
      file_upload: {
        upload_your_date_here: 'Upload your data here',
        add_more_examinations: 'Add more examinations',
        remove_file: 'Remove file{{ending}}',
        upload_file: 'upload file',
        you_added_files_one: 'You added {{count}} file',
        you_added_files_other: 'You added {{count}} files',
      },
    },
    form_wrap: {
      help: 'Help',
    },
    header: {
      view_my_account: 'View my account',
      sign_out: 'Sign out',
      subscriptions: 'Subscriptions',
      examinations_left: 'Examinations left',
      unlimited: 'Unlimited',
      add_new_patient: 'Add new patient',
      add_new_examination: 'Add new examination',
      menuItems: {
        patients: 'Patients',
        settings: 'Settings',
        subscription: 'Subscription',
        team_members: 'Team members',
        contact_us: 'Contact us',
        onboarding: 'Onboarding',
      },
    },
    layers_filter: {
      hide: 'Hide',
      show_more: 'Show more',
      no_items: 'AI haven’t defined any area to be segmented.',
      all: 'All',
      layers: 'Layers:',
      segmentations: 'Segmentations:',
      highlight: 'Highlight',
    },
    layout: {
      private_layout: {
        skip: 'Skip',
        demo_banner_title: 'Start 14 days free Trial after Video presentation',
        book_a_demo: 'Book a call',
      },
      simple_layout: {
        sign_out: 'Sign out',
      },
    },
    modal: {
      analyze_scans: {
        severity: 'Severity',
        analyze_all_scans: 'Analyze all scans',
        no_scans: 'No scans with such parameters.',
        try_another_filter: 'Please, try another filters.',
        submit: 'Submit',
      },
      booking_meeting: {
        title: 'We’re sure that we can find a perfect solution for you!',
        book_a_demo: 'Book Demo',
      },
      buy_additional_items: {
        buy_additional_examinations: 'Buy additional {{number}} examinations',
        will_be_cost: '{{description}} will be coast {{symbol}}{{price}}',
      },
      create_examination: {
        title1: 'Fill the form below to add new Examination. Upload ',
        title2: 'ONLY Macula Zone Horizontal B-Scans',
        title3: ' (Fundus image, Scans of Optic disc ',
        title4: 'ARE NOT',
        title5: ' supported)',
        select_exam_label: 'Select the Examination',
        patient_id_label: 'Patient ID',
        patient_id_placeholder: 'The patient will be created from DICOM file',
        remaining_examinations: 'Remaining examinations: {{number}}',
        unlimited: 'Unlimited',
        images: 'Images',
        folder: 'Folder',
        add_examination: 'Add examination',
        right: 'Right',
        left: 'Left',
        label: '{{name}} / exam.: {{data}} / Eye: {{eye}} / scan: {{scan}}',
      },
      create_patient: {
        no_exam_left1: 'No Examinations left. You can ',
        no_exam_left2: 'upgrade your plan',
        no_exam_left3: ' to add more examinations',
        create_new_patient_from_dicom: 'Create a New patient from a DICOM',
        create_new_patient_from_dicom_description:
          'Information about patient will be pulled from DICOM file',
        patient_id_label: 'Patient ID',
        example_short: 'Ex',
        first_name_label: 'Patient First Name',
        last_name_label: 'Patient Second Name',
        birth_date_label: 'Patient Birth Date',
        gender_label: 'Gender',
        gender_placeholder: 'Choose...',
        notes_label: 'Notes',
        add_examination: 'Add Examination',
        add_patient: 'Add Patient',
        add_patient_and_examination: 'Add Patient and examination',
        male: 'Male',
        female: 'Female',
        other: 'Other',
      },
      current_plan: {
        annual: 'Annual',
        monthly: 'Monthly',
        days_left: 'Days left:',
        examinations_left: 'Examinations left:',
        unlimited: 'Unlimited',
        invitations_left: 'Invitations left:',
        no_scheduled_payment:
          'You don’t have next scheduled payment as you didn’t subscribe on any plan',
        next_payment_related_to_your_upcoming_plan:
          'Your next payment is related to your upcoming plan and is ',
        it_will_automatically_charge: 'It will be automatically charged on ',
        your_next_payment_is: 'Your next payment is ',
        to_be_charged: ', to be charged on ',
        payment_will_be_charged_automatically:
          'The payment will be charged automatically',
        you_successfully_unsubscribed: 'You successfully unsubscribed from a ',
        plan: 'plan',
        your_subscription_is_active: 'Your subscription is active till ',
        subscribe: 'Subscribe',
        unsubscribe: 'Unsubscribe',
        continue_subscriptions: 'Continue Subscriptions',
      },
      delete_exam: {
        cancel: 'Cancel',
        delete: 'Delete',
      },
      delete_patient: {
        cancel: 'Cancel',
        delete: 'Delete',
      },
      delete_team_member: {
        cancel: 'Cancel',
        delete: 'Delete',
      },
      download_dcm: {
        dont_have_dicom_file: 'Don’t have DICOM file right now?',
        use_this: 'Use this!',
        download: 'Download',
        i_have_dicom: 'I have DICOM on my PC',
        download_dicom: 'Download DICOM',
      },
      edit_scan_description: {
        oct_description_title: 'Description of Altris AI OCT analysis',
        save: 'Save changes',
      },
      export_json: {
        dicom_presentation_state: 'DICOM presentation state',
        file: 'File',
      },
      glaucoma_disclaimer: {
        title: 'Disclaimer',
        i_acknowledged: 'I Acknowledged',
      },
      image_cropper: {
        cancel: 'Cancel',
        crop: 'Crop',
      },
      invite_team_member: {
        email_label: 'Email',
        first_name_label: 'Doctor First Name',
        last_name_label: 'Doctor Second Name',
        example_short: 'Ex',
        send_the_invitation: 'Send the invitation',
      },
      no_objects_left: {
        examinations: {
          title: 'You can’t add more examinations',
          subtitle:
            'New examination can not be added until {{endData}} as the examination limit for your subscription plan has been exceed for this month',
          subtext:
            'Starting from {{endData}} you’ll be able to add new examinations again.',
        },
        plan: 'plan',
        annual: 'Annual',
        monthly: 'Monthly',
        days_left: 'Days left:',
        renewal_starts_at: ' (Renewal starts at: {{endData}})',
        examinations_left: 'Examinations left:',
        invitations_left: 'Invitations left:',
        go_to_contact_page: 'Go to contact page',
        please_contact_account_holder1: 'Please contact your ',
        please_contact_account_holder2: 'Account holder',
        please_contact_account_holder3: ' for help.',
        b2c: {
          buy_examinations_or_upgrade_plan1: 'You can also ',
          buy_examinations_or_upgrade_plan2: 'buy extra examinations',
          buy_examinations_or_upgrade_plan3: ' or ',
          buy_examinations_or_upgrade_plan4: ' upgrade your plan',
          upgrade_plan1: 'You can ',
          upgrade_plan2: 'upgrade your plan',
          buy_plan: 'Buy {{name}}',
          upgrade_to:
            'Upgrade to {{name}} plan with {{count}} Examination / Month',
          renew_subscription: 'Renew Subscription',
          upgrade_to_custom_plan: 'Upgrade to Custom plan',
        },
      },
      onboarding_tour: {
        cancel: "Cancel (I'm familiar with Altris AI)",
        submit: 'Take a Quick tour',
      },
      renew_subscribe_reject: {
        please_contact_account_holder1: 'Please contact your ',
        please_contact_account_holder2: 'Account holder',
        please_contact_account_holder3: ' for help.',
        not_renew_subscription1:
          "If you're not going to renew the subscription, please send request,",
        not_renew_subscription2: ' download your data ',
        not_renew_subscription3:
          'to do not loose it. If there are any questions, feel free to contact us via email: ',
        not_renew_subscription4: 'support@altris.ai',
      },
      renew_subscribe_warning: {
        please_contact_account_holder1: 'Please contact your ',
        please_contact_account_holder2: 'Account holder',
        please_contact_account_holder3: ' for help.',
        not_renew_subscription1:
          "If you're not going to renew the subscription, please send request,",
        not_renew_subscription2: ' download your data ',
        not_renew_subscription3:
          'to do not loose it. If there are any questions, feel free to contact us via email: ',
        not_renew_subscription4: 'support@altris.ai',
      },
      resend_team_member: {
        cancel: 'Cancel',
        send_the_invitation: 'Send the invitation',
      },
      select_layers: {
        layers_grayscale: 'Layers grayscale',
        layers_grayscale_description: 'Your scan inside report will be gray.',
        confirm: 'Confirm',
      },
      subscribe_plan: {
        subscription_update: 'Subscription update',
        are_you_sure_subscribe_free_plan:
          'Are you sure you want to subscribe to a Free plan?',
        no: 'No',
        yes: 'Yes',
        subscribe_with: 'Subscribe',
        with_5_free_days: 'with 5 free days',
        plan_charge:
          'A {{interval}}ly charge will be {{symbol}}{{price}}{{additional_item}}',
        monthly_plan_description:
          '*The discount is valid only for the six {{interval}} of subscription',
        daily_plan_description:
          '*The discount is valid only for the first {{interval}} of subscription',
      },
      survey_unsubscribe: {
        reason: 'Reason',
        leave_your_feedback: 'Leave your feedback',
        submit: 'Submit',
      },
      change_single_scan_tab: {
        dont_show_hint:
          "Don't show hint every time when I open Single Scan tab (this option can be changed in settings)",
        later: 'Later',
        open_pathologies_detection_tab: 'Open Pathologies Detection tab',
      },
      trial_period_end: {
        title: 'Trial Period Expired',
        subtitle:
          'Your trial period has ended on <0>{{date}}</0> To discuss purchasing the full version of the product or for more information, please contact our <1>sales team</1>.',
        button: 'I acknowledged',
      },
      update_exam: {
        patient_id: 'Patient',
        width: 'Width',
        scan_width: 'Scan width',
        right: 'Right',
        left: 'Left',
        scan_width_message:
          'Make sure you selected the correct scan width as it affects on measurements in examination',
        eye: 'Eye',
        type: 'Type',
        examination_date: 'Examination date',
        notes_label: 'Notes',
        example_short: 'Ex',
        update_examination: 'Update examination',
      },
      update_patient: {
        patient_id_label: 'Patient ID',
        example_short: 'Ex',
        first_name_label: 'Patient First Name',
        last_name_label: 'Patient Second Name',
        birth_date_label: 'Patient Birth Date',
        gender_label: 'Gender',
        gender_placeholder: 'Choose...',
        notes_label: 'Notes',
        add_examination: 'Add Examination',
        update_patient: 'Update Patient',
        male: 'Male',
        female: 'Female',
        other: 'Other',
      },
    },
    no_pathology: {
      title: 'All is good!',
      subtitle: 'No pathology detected',
    },
    opacity_changer: {
      opacity: 'Opacity',
    },
    open_exam_button: {
      text: 'Open this examination',
    },
    pathologies_progression: {
      title: 'Pathologies Progression',
      add_another_examination:
        'Add another examination to review pathologies progression',
      no_pathologies:
        'No option to show pathologies progression as there are no pathologies detected',
      selected_pathology: 'Selected pathology',
      volume: 'Volume',
      units_in_etdrs: 'Units in ETDRS',
      b_scan_area: 'B-Scan Area',
      en_face_area: 'En Face Area',
      total_change: 'Total Change',
      search_pathologies: 'Search Pathologies',
      number_of_pathological: 'Number of pathological areas: {{number}}',
    },
    pay_by_stripe: {
      add_new_card: 'or add new Credit/Debit Card',
      pay_via: 'Pay via Credit/Debit Card',
    },
    payment_info: {
      no_payments: 'There are no payments yet',
      you_can_subscribe:
        'You can subscribe to the plan by clicking the button below',
      subscribe_plan: 'Subscribe plan',
      description: 'Description',
      date: 'Date',
      time: 'Time',
      charged: 'Charged',
      status: 'Status',
    },
    range_filter: {
      left: 'Left',
      right: 'Right',
      horizontal: 'Horizontal',
    },
    referral_score: {
      title: 'Referral Urgency Score',
      good_message: 'No referral needed',
      bad_message: 'Urgent referral needed',
    },
    scan_description: {
      title: 'Description of Altris AI OCT analysis',
      left: 'Left',
      right: 'Right',
      eye: 'Eye',
      average_rnfl_rpe:
        'Average RNFL-RPE thickness in a central foveal ring with 1mm diameter',
      following_pathological_signs:
        'Following pathological signs have been visualized',
      changes_could_be_classified_as: 'These changes can be classified as',
      edit: 'Edit',
      copy: 'Copy',
      show_more: 'Show more',
      hide: 'Hide',
    },
    scan_overlay: {
      length: 'Length',
    },
    segmentation_table: {
      low_confidence: 'Low confidence',
      low_confidence_description:
        'Altris AI detected this Classification with low confidence.',
      no_classification: 'No classification for this scan.',
      banner_tooltip:
        'Only diseases covered in your plan are shown. However, several other pathologies have been identified. If you want to highlight them, please Upgrade your plan.',
      more_pathologies_found:
        'There are more pathologies found, which your plan is missing. To review them Upgrade your plan.',
      upgrade_plan: 'Upgrade my plan',
    },
    severity_level: {
      no_severity: 'No severity in Examination',
      no_severity_plural: 'No severity in Examinations',
      yellow_severity_scan: 'Examination includes Yellow severity scan',
      yellow_severity_scan_plural: 'Yellow severity scan in Examinations',
      red_severity_scan: 'Examination includes Red severity scan',
      red_severity_scan_plural: 'Red severity scan in Examinations',
    },
    severity_select: {
      sort: 'Sort',
      sort_by: 'Sort by',
      high_low: 'Severity: High - Low',
      low_high: 'Severity: Low - High',
      in_order: 'In order',
    },
    single_scan: {
      add_to_report: 'Add to report',
      review_other_scans: 'Review other scans',
      scan_quality_tooltip1:
        'Note that only scan with quality 4 or more could be considered as quality scan.',
      scan_quality_tooltip2:
        'Altris AI still capable to classify and segment them, but results can be inaccurate.',
      central_scan: 'Central scan',
      severity: 'Severity',
      severity_tooltip:
        'Scans are marked according to severity of pathological signs. Green - normal retina, Yellow - moderate severity, and Red - severe.',
      yellow: 'yellow',
      red: 'red',
      green: 'green',
      draw_a_line: 'Draw a line',
      draw_a_line_info: 'Press left button on the mouse and pull',
      segmentations: 'Segmentations',
      highlight: 'Highlight',
    },
    smart_report: {
      average_thickness: '1/3/6 mm average thickness, μm',
      gcc_thickness: 'GCC thickness',
      both_eyes_view: 'Both eyes view',
      thickness_comparison: 'Thickness comparison',
      retina_thickness_difference: 'Retina thickness difference',
      etdrs_calculations: 'ETDRS calculations',
      from: 'From',
      to: 'to',
      etdrs_titles: {
        average_thickness: 'Average thickness',
        single_layer_thickness: 'Single layer thickness',
        multiple_layer_thickness: 'Multiple layer thickness',
        gcc_thickness: 'GCC thickness',
      },
      glaucoma: 'Glaucoma',
      auto: 'Auto',
      auto_measurements: 'Auto measurements',
      manual: 'Manual',
      manual_measurements: 'Manual measurements',
      segmentation_area_title: 'Segmentation area | Scan: {{number}}/{{total}}',
      segmentation_volume_title:
        'Segmentation volume | Scan: {{number}}/{{total}}',
      retina_layers: 'Retina layers',
      segmentation: 'Segmentation',
      classification: 'Classification',
      single_scan: 'Single scan',
      right: 'Right',
      left: 'Left',
      report_results: 'Report results',
      clinic: "Clinic's Name",
      clinic_email: "Clinic's Email",
      doctor: 'Doctor',
      patient_name: 'Patient name',
      birth_day: 'Birth day',
      gender: 'Gender',
      eye: 'Eye',
      exam_date: 'Examination date',
      total_scans: 'Total scans',
      pathology_progression: 'Pathology progression',
      selected_pathology: 'Selected pathology:',
      en_face_area: 'En face Area',
      b_scan_area: 'B-scan Area',
      volume: 'Volume',
      total_change: 'Total change',
    },
    spinner_svg: {
      retry: 'Retry',
    },
    subscription_conditions: {
      back: 'Back',
      detection: 'Pathological signs detection with AI (Segmentation)',
      detection_and_visualization:
        'Pathological signs detection and visualization with AI (Segmentation)',
      view_all_pathological_signs: 'View all pathological signs',
    },
    subscription_plan_card: {
      need_better_solution: 'Need better solution for your clinic?',
      personal_assistant: 'Personal Assistant',
      unlimited_examinations: 'Unlimited Examinations',
      unlimited_patients: 'Unlimited Patients',
      separate_accounts_for_doctors: 'Separate accounts for doctors',
      onboarding_trainings_for_the_whole_team:
        'Onboarding trainings for the whole team',
      platform_customization:
        'Platform customization up to your needs and more.',
      contact: 'Contact',
      month: 'Month',
      you_saving: "You're saving",
      starting_from: 'Starting from',
      details: 'Details',
      current_plan: 'Current plan',
      active_till: 'Active till',
      subscribe: 'Subscribe',
      days_free_trial: '{{number}} days free trial',
      best_choice: 'Best choice',
      monthly_description:
        '*The discount is valid only for the six {{pricingType}} of subscription',
      daily_description:
        '*The discount is valid only for the first {{pricingType}} of subscription',
      unlimited: 'Unlimited',
      examinations_for_ai_screening:
        'Examinations for AI SCREENING, AI LAYERS SEGMENTATION',
      pathological_signs_detection:
        'Pathological signs detection and visualization with AI',
      try_for_free: 'Try for free',
      month_small: 'month',
      year_small: 'year',
    },
    subscription_plans: {
      subscription: 'Subscription',
      price: 'Price',
      examinations_available: 'Examinations available',
      detect: 'Detect',
      retina_conditions: 'Retina Conditions',
      tooltip_text:
        'This functionality is currently in development and will appear in the platform when it`s ready',
      services: 'Services',
      service: 'Service',
    },
    team_member_list: {
      doctors: 'Doctors',
      email: 'Email',
      status: 'Status',
      access_level: 'Access level',
      examination_created: 'Examination created',
      actions: 'Actions',
      no_doctors: 'No doctors have been invited yet',
      you_can_add_team_member: 'You can add new team member by pressing',
      invite_doctor: 'Invite doctor',
      me: 'ME',
      no_actions: 'No actions',
      resend_invitation: 'Resend invitation',
      delete: 'Delete',
      active: 'ACTIVE',
      pending: 'PENDING',
      invite_expired: 'INVITE EXPIRED',
    },
    titled_switcher: {
      on: 'ON',
      off: 'OFF',
    },
    upload_image: {
      upload_photo: 'Upload photo',
      info: 'Format: {{formats}}. The maximum file size is {{size}}.',
    },
    welcome: {
      title: 'Make OCT scan interpretation easy with the Altris AI platform',
      subtitle1:
        'Altris AI platform only works on a Windows or Mac (NOT a Mobile app).',
      subtitle2: 'Although registration can be through your mobile phone',
      list_item_title1:
        'Differentiation between pathological and non-pathological b-scans in 1 minute',
      list_item_title2:
        '70+ retina pathologies detection, including rare pathologies',
      list_item_title3: 'Retina layers thickness analysis',
      how_it_works: 'How it works?',
      description1:
        'Altris AI adheres fully to GDPR, ensuring compliance. All user data is encrypted and inaccessible to third parties. We hold a CE certificate and FDA 510 clearance, affirming our commitment to regulatory standards.',
      description2:
        'The platform was created by a team of retina experts under the supervision of the Medical Director, a Ph.D. in Ophthalmology.',
      description3:
        'Altris AI is featured in the Ophthalmology Times, the Eyewire, Eyes on Eyecare, the Ophthalmologist and is supported by the eye care community.',
      features_item_title1: 'Format.',
      features_item_title2: 'Communication.',
      features_item_title3: 'Integration.',
      features_item_text1:
        'The system works with all data formats, such as DICOM, jpg, and png, making the ophthalmic image management system more secure and efficient.',
      features_item_text2:
        'Eye care specialists can share images within the system, and discuss them leaving comments and working on the same images together.',
      features_item_text3:
        'The system can also be integrated with the EHR system or it can also work autonomously as a web application.',
      disclaimer:
        'Disclaimer: Altris AI is available as a research only software in the USA. FDA clearance is in progress.',
      i_acknowledged: 'I acknowledged',
      learn_more: 'Learn more',
      back: 'Back',
      next: 'Next',
      slide_one: {
        title: 'Welcome to the Altris AI',
        subtitle:
          'Analyse OCT examination with Altris AI. Save your time and don’t ever miss Rare, minor or early pathology',
        desktop_available:
          'Altris AI platform only works on a Windows or Mac. Not a mobile app.',
        registration_with_phone:
          'Although registration can be through your mobile phone',
        description:
          'Fully GDPR compliant, data is encrypted and can’t be reached by any 3rd party. We have a CE certificate and FDA clearance is in the process.',
      },
      slide_two: {
        title: 'Severity Scans Severity',
        subtitle:
          'Analyse severity of up to 512 pathological scans in one minute',
        low_severity: 'Low severity',
        medium_severity: 'Medium severity',
        high_severity: 'High severity',
        green: 'green',
        yellow: 'yellow',
        red: 'red',
        description:
          'No needs to waste time on reviwing all scans to find area of interest',
      },

      slide_three: {
        title: 'Pathologies detection and visualization',
        subtitle:
          'Altria AI detect 70+ retina conditions for each scan in examination',
        segmentation: 'Segmentation',
        classification: 'Classification',
      },
      slide_four: {
        title: 'And much more to make the most of the platform',
        item_header1: 'Retina layers segmentation',
        item_header2: 'EDTRS and Retina thickness map',
        item_header3: 'Referral Urgency Score',
        item_header4: 'Pathologies Auto-measurements',
        item_header5: 'Segmentation Area',
        item_header6: 'Segmentation Volume',
        item_header7: 'Smart reports',
        item_text2: '1/3/6 mm average thickness and Retina thickness map',
        item_text3:
          'Altris AI suggestion Referral Urgency Score depending on discovered pathological sings. It ranged from No referral needed to Urgent referral needed',
        item_text4:
          'Altris AI suggestion Referral Urgency Score depending on discovered pathological sings. It ranged from No referral needed to Urgent referral needed',
        item_text5:
          'Altris AI automatically calculates Area off All Segmentations',
        item_text6:
          'Altris AI automatically calculates Area off All Segmentations',
      },
    },
  },
  constants: {
    severity_filter_options: {
      all: 'all',
      green: 'green',
      yellow: 'yellow',
      red: 'red',
    },
  },
  modals: {
    glaucoma_disclaimer:
      'Please note that the Glaucoma Risk analysis only works on OCT examinations where the pathology does not change the thickness of the Ganglion Cell Complex',
    sign_out: {
      title: "You're trying to log out",
      subtitle: 'Are you sure?',
      yes: 'Yes',
      cancel: 'Cancel',
    },
    add_patient: {
      title: 'Add new patient',
      subtitle: 'Fill the form below to add new patient card to the list',
    },
    add_examination: 'Add new examination',
    add_eye_examination: {
      title: 'Add eye’s examination',
      subtitle: 'Fill the form below to add new examination to the list',
    },
    invite_team_member: {
      title: 'Invite a doctor',
      subtitle: 'Fill the form below to add new doctor to the list',
    },
    delete_team_member: {
      title: 'Delete',
      subtitle: 'Do you really want to delete this account?',
    },
    reinvite_team_member: {
      title: 'Resend invitation',
      subtitle: 'Do you really want to resend invitation to this account?',
    },
    delete_patient: {
      title: 'Delete',
      subtitle: 'Do you really want to delete this patient?',
    },
    update_patient: {
      title: 'Edit patient',
      subtitle: 'Fill the form below to edit patient card in the list',
    },
    unsubscribe: {
      title: "You're trying to unsubscribe",
      subtitle: 'Are you sure?',
      yes: 'Yes',
      cancel: 'Cancel',
    },
    subscribe_fake: {
      title: 'Subscription update',
      subtitle: 'You will be subscribe to the free plan',
      yes: 'Yes',
      cancel: 'Cancel',
    },
    renew_subscribe_warning: {
      title1: 'Renew subscription',
      subtitle1:
        "Unfortunately, we didn't receive the payment. The subscription you are currently using has expired. Please upgrade to a new Subscription plan to Altris AI or your access to Altris AI will be removed",
      button_text1: 'Renew Subscription',
      title2: 'Renew subscription',
      subtitle2:
        "Unfortunately, we didn't receive the payment and your plan has expired. Please renew your subscription or your access to Altris AI will be removed",
      button_text2: 'Renew',
    },
    renew_subscribe_warning_b2b: {
      title: 'Renew subscription',
      subtitle:
        "Unfortunately, we didn't receive the payment and your plan has expired. Please renew your subscription or your access to Altris AI will be removed",
      button_text: 'Go to contact page',
    },
    renew_subscribe_reject: {
      title1: 'Renew subscription',
      subtitle1:
        "Unfortunately, we didn't receive the payment. The subscription you are currently using has expired. Please upgrade to a new Subscription plan. We're going to store your data by {{date}}. After that we'll have to delete it.",
      button_text1: 'Renew Subscription',
      title2: 'Renew subscription',
      subtitle2:
        "Unfortunately, we didn't receive the payment and your plan has expired. Please renew your subscription. We're going to store your data by {{date}}. After that we'll have to delete it.",
      button_text2: 'Renew',
    },
    renew_subscribe_reject_b2b: {
      title: 'Renew subscription',
      subtitle:
        "Unfortunately, we didn't receive the payment and your plan has expired. Please renew your subscription. We're going to store your data by  {{date}}. After that we'll have to delete it.",
      button_text: 'Go to Contact Us page',
    },
    analyze_scans: {
      title: 'Analyze other scans',
      subtitle: 'Check scans that you want analyze.',
    },
    select_retina_layers: 'Select retina layers to add to Report',
    delete_examination: {
      title: 'Delete',
      subtitle: 'Do you really want to delete this examination?',
    },
    update_examination: {
      title: 'Edit',
      subtitle: 'Fill the form below to edit examination card in the list',
    },
    onboarding_tour: {
      title: 'Welcome to Altris AI',
      subtitle: 'What can you expect at Altris AI?',
    },

    json_exam_export: {
      title: 'Export your examination data.',
      subtitle: 'Chose what data you want to export.',
    },
    unsubscribe_survey: {
      title: 'What’s gone wrong?',
      subtitle:
        'We noticed that you unsubscribed from Altris Pro. Please, let us know why?',
    },
    booking_meeting: {
      title: 'We can figure it out!',
      subtitle:
        'Book a free short meeting with our expert, who has 15 years in OCT and can answer all your questions regarding Altris AI',
    },
    download_dicom: {
      title: 'Create your first patient and examination!',
      subtitle:
        'To create examination use DICOM file or image exported from your OCT',
    },
    download_dicom_success: {
      title: 'DICOM download started!',
      subtitle:
        'Wait until... After it’s downloaded click “Next” to create new patient. After that, create new Examination and upload DICOM file to be analysed by Altris AI',
    },
    change_single_scan_tab:
      'Don’t forget to review Pathologies Detection (AI Powered!)',
  },
  validation: {
    required: 'Required',
    data_invalid: 'Provided data is invalid',
    email_invalid: 'Provided email is invalid',
    letters_numbers_spaces_hyphens:
      'Only letters (en, ua, ru), numbers, spaces, dots and hyphens("-") are allowed in this field',
    min_chars: 'Must contain minimum {{number}} characters',
    max_chars: 'Must contain maximum {{number}} characters',
    password_latin_lowercase: 'Password should contain Latin lowercase letter',
    password_latin_uppercase: 'Password should contain Latin uppercase letter',
    password_number: 'Password should contain number',
    password_match: 'Passwords must match',
  },
  notifications: {
    you_can_add_only_2_dicom: 'You can add only 2 dicom files',
    your_data_was_added_successfully: 'Your data was added successfully',
    you_cant_add_file_of_this_format: 'You can’t add a file of this format',
    patient_was_added_successfully: 'Patient was added successfully',
    characters_limit_exceeded: '{{number}} characters limit exceeded',
    download_will_started_automatically:
      'The download will be started automatically when all data is ready.',
    please_enter_your_card: 'Please enter your card',
    image_size_changed_successfully: 'Image size changed successfully',
    description_copied: 'Description copied',
    description_not_copied: 'Description not copied',
    you_already_add_this_scan_form_segmentation_report:
      'You already add this scan form segmentation report',
    scan_was_successfully_added_to_the_report:
      'Scan was successfully added to the report',
    image_was_not_uploaded:
      "Image wasn't uploaded as it's size is too big. Upload image less than 3 mb",
    resent_confirmation_email: 'Resent confirmation email',
    something_went_wrong: 'Something went wrong',
    examination_etdrs_calculation_finished:
      'Examination: {{id}}. ETDRS calculation finished',
    examination_etdrs_calculation_failed:
      'Examination: {{id}}. ETDRS calculation failed',
    segmentation_layers_calculation_finished:
      'Examination: {{id}}. Segmentation layers calculation finished',
    segmentation_layers_calculation_failed:
      'Examination: {{id}}. Segmentation layers calculation failed',
    measurements_calculation_finished:
      'Examination: {{id}}. Measurements calculation finished',
    measurements_calculation_failed:
      'Examination: {{id}}. Measurements calculation failed',
    measurements_manual_updated: 'Measurements manual updated',
    link_is_copied: 'Link is copied',
    link_not_copied: 'Link is not copied',
    your_request_sent_successfully: 'Your request was sent successfully',
    you_dont_have_available_pathology_classes:
      "You don't have available pathology classes",
    examination_created_successfully: 'Examination was created successfully',
    examination_deleted_successfully: 'Examination was deleted successfully',
    examination_failed: 'Your examination is failed',
    examination_available: 'Your examination is available',
    examination_updated_successfully: 'Examination was updated successfully',
    scan_added_to_favorites: 'Scan was added to favorites',
    scan_removed_from_favorites: 'Scan was removed from favorites',
    measurements_reset_successfully:
      'Measurements for this scan have been reset',
    measurements_updated: 'Measurements updated',
    you_tried_upload_same_eye: 'You’ve tried to upload the same eye',
    you_tried_upload_opposite_eye: 'You’ve tried to upload opposite eye',
    limit_examination_is_reached: 'The limit of examinations is reached',
    examinations_have_not_enough_data:
      "Your examination hasn't have enough data",
    patient_deleted_successfully: 'Patient was deleted successfully',
    patient_updated_successfully:
      "Patient's information was updated successfully",
    you_successfully_buy_additional_examination:
      'You successfully buy additional examination',
    settings_saved: 'Your settings have been saved',
    subscription_plan_updated: 'Your subscription plan was updated',
    subscription_canceled: 'Your subscription has been canceled',

    doctor_invited: 'Doctor has been invited',
    password_changed: 'Your password has been changed',
    backup_request_created:
      'Request has been created, you got email when your data will be ready',
    data_deleted: 'Your data has been deleted',
    attempts_over: 'Your attempts are over, please try again later',
    company_profile_updated: 'Your company profile has been updated',
    profile_updated: 'Your profile was updated successfully',
    session_expired: 'Your session was expired, please log in',
    site_under_maintenance:
      'lease be aware that currently, the site is under regular maintenance, and all services might temporarily work inaccurately',
    progression_settings_updated: 'Progression settings updated successfully',
    file_folder_is_too_large: 'That file/folder is too large',
  },
  user_popup_menu_links: {
    subscription: 'Subscription',
    contact_support: 'Contact support',
  },
  profile_links: {
    subscriptions: 'Subscriptions',
    team_members: 'Team members',
    contact_us: 'Contact us',
    documentation: 'Documentation',
    join_altris_education: 'Join Altris Education',
    settings: 'Settings',
    onboarding: 'Onboarding',
    storybook: 'Storybook',
  },
  onboarding_links: {
    complete_onboarding_again: 'Complete onboarding again',
    download_test_dicom: 'Download test DICOM',
    create_patient: 'Create patient',
    create_examintaion: 'Create examination',
    header: 'Header',
    single_scan: 'Single scan',
    pathologies_detection: 'Pathologies detection',
    measurements: 'Measurements',
    all_scans: 'All scans',
    both_eyes: 'Both eyes',
    comparison: 'Comparison',
    progression: 'Progression',
    report: 'Report',
  },
  onboarding: {
    create_examination: {
      add_new_examination: 'Add new examination',
      next: 'Next',
      examination_list: 'Examinations list',
      step_1_text_1: 'Click',
      step_1_text_2: 'to add new OCT Examination to your patient',
      step_1_text_3: 'Click to move next',
      step_2_text_1: 'The first step would be to',
      step_2_text_2: 'Select the Patient',
      step_3_text: 'Or select to Create examination AND Patient using DICOM',
      step_4_text_1: 'What is DICOM?',
      step_4_text_2: "I'm familiar with DICOM",
      step_4_text_3: 'You can create examination by uploading',
      step_4_text_4: 'DICOM (Preferred), DICOM folder',
      step_4_text_5: 'or Images',
      step_4_text_6:
        'Important! Make sure you know what is DICOM and how to export it from your OCT',
      step_5_text_1: 'Drag DICOM to the window to select it to be uploaded',
      step_5_text_2: 'Upload DICOM to move next',
      step_6_text_1:
        'After DICOM/Images file were added added you can click on',
      step_6_text_2: 'Click to move next',
      step_7_text:
        'And now Examination was added and will be accessible after system analysed it. You can review the list of all Patient’s examinations after you click on it.',
      step_8_text_1: "Let's review how examination looks like",
      step_8_text_2: 'Click to move next',
      step_dicom_text_1: 'I acknowledged',
      step_dicom_text_2: 'What is DICOM/DCOM/.dcm file?',
      step_dicom_text_3: 'DICOM/DCOM/.dcm',
      step_dicom_text_4: 'is a file format, which you can select',
      step_dicom_text_5: 'to export examination',
      step_dicom_text_6: 'from your OCT.',
      step_dicom_text_7: 'It includes:',
      step_dicom_text_8: 'All scans from OCT Examination',
      step_dicom_text_9:
        'You can select how many scans you want to export into DICOM on your OCT',
      step_dicom_text_10: 'Necessary examination data',
      step_dicom_text_11:
        "We suggest to use DICOM instead of images. As Images doesn't include all necessary examination data, which cause that several main system components won't be able to represent convenient data",
      step_dicom_text_12: "What won't be available if I'll use Images?",
      step_dicom_text_13: 'Necessary data about patient',
      step_dicom_text_14:
        'If you use DICOM instead of Image we can pull all necessary data about patient and create it automatically',
      step_dicom_text_15: 'How can I create patient using DICOM?',
      step_dicom_text_16: 'DICOM Folder',
      step_dicom_text_17:
        'If your OCT can only export data in a folder (ex. Heidelberg Engineering OCT) you can Archive this folder using your standard archiver and upload a .zip file while creating an examination. DO NOT change folder structure or archive any separate folder inside the main one. The folder should be archived entirely.',
      step_dicom_text_18:
        'How to Export DICOM Folder from Heidelberg Engineering OCT?',
      step_dicom_text_19: 'How can I export DICOM file from my OCT?',
      step_dicom_text_20: 'How to Export DICOM from Nidek OCT?',
      step_dicom_text_21: 'How to Export DICOM from Topcon OCT?',
      step_dicom_text_22: 'How to Export DICOM from Optopol OCT?',
      step_scans_text_1: 'Only quality scans!',
      step_scans_text_2:
        "Please, make sure that scans you're uploading are of sufficient quality. Otherwise, result of the analysis will not be satisfactory.",
    },
    create_patient: {
      add_new_patient: 'Add new patient',
      next: 'Next',
      step_1_text_1: "Let's start with adding a new patient!",
      step_1_text_2: 'to create a new patient',
      step_1_text_3: 'Click here to move next',
      step_2_text_1:
        'You can immediately create Patient and Examination by uploading DICOM file',
      step_2_text_2: 'Just select checkbox!',
      step_3_text_1:
        'If you selected to do not upload patient from DICOM you have to fill all information about patient.',
      step_3_text_2: "After all the patient's data has been completed click",
      step_4_text_1:
        'After DICOM uploaded or all information about patient filled you can click',
      step_4_text_2: 'to save it.',
      step_4_text_3: 'Click to move next',
      step_5_text_1: 'Patients list',
      step_5_text_2:
        'And now your Patient was added, so you can create Examination',
      step_5_text_3:
        'Note: If you uploaded patient using DICOM this step will be skipped automatically',
    },
    create_report: {
      create_report: 'Create report',
      next: 'Next',
      step_1_text_1: 'After examination discovered, doctor can create a',
      step_1_text_2: 'Report',
      step_1_text_3: 'Click to move next',
      step_2_text_1: 'Step 1',
      step_2_text_2:
        'Select the scan you want to show as the main (Last reviewed by default)',
      step_2_text_3: 'Step 2',
      step_2_text_4:
        'Select which components you want to be included in the report',
      step_2_text_5: 'Step 3',
      step_2_text_6:
        'Select one or several scans to show pathologies detection',
      step_3_text_1: 'Print or Save PDF',
      step_3_text_2: 'Step 4',
      step_3_text_3: 'Click',
      step_3_text_4: 'Review',
      step_3_text_5:
        'to see preview of generated report and Print it/Save in PDF',
      step_3_text_6: 'Click to move next',
      step_4_text_1: 'Congratulations!',
      step_4_text_2: 'You’ve completed onboarding!',
      step_4_text_3: 'Feel free to create your first Patient!',
      step_4_text_4: 'You can always review this instruction again in',
      step_4_text_5: 'Settings',
    },
    general: {
      skip_onboarding: 'Skip Onboarding',
      are_you_sure_want_to_skip: 'Are you sure you want to skip onboarding?',
      continue_onboarding: 'Continue Onboarding',
    },
    show_examination: {
      tab_menu: 'Tab menu',
      next: 'Next',
      all_scans_text_1: "Let's continue with",
      all_scans_text_2: 'All Scans',
      all_scans_text_3: 'Click to move next',
      all_scans_text_4: 'All Scans',
      all_scans_text_5:
        'You can review all scans and sort them by by Level of Severity.',
      all_scans_text_6:
        'Also you can select to view only the scans that have been categorized as',
      all_scans_text_7: 'green',
      all_scans_text_8: 'yellow',
      all_scans_text_9: 'or',
      all_scans_text_10: 'red',
      all_scans_text_11: 'Severity level.',
      both_eyes_text_1: "Let's continue with",
      both_eyes_text_2: 'Both Eyes',
      both_eyes_text_3: 'Click to move next',
      both_eyes_text_4: 'Both eyes',
      both_eyes_text_5: 'You can add second eye to review full picture',
      comparison_text_1: "Let's continue with",
      comparison_text_2: 'Comparison',
      comparison_text_3: 'Click to move next',
      comparison_text_4: 'Comparison',
      comparison_text_5:
        'Add the same eye, diagnosed earlier, to compare with current examination',
      measurements_text_1: "Let's continue with",
      measurements_text_2: 'Measurements',
      measurements_text_3: 'Click to move next',
      measurements_text_4: 'Measurements',
      measurements_text_5: 'Section Measurements allows doctors to:',
      measurements_text_6: 'Review',
      measurements_text_7: 'Height',
      measurements_text_8: 'and',
      measurements_text_9: 'Length',
      measurements_text_10: 'of segmentations, measured automatically',
      measurements_text_11: 'Draw',
      measurements_text_12: 'Lines to calculate area of interests',
      measurements_text_13: 'Review Segmentation’s Areas',
      measurements_text_14: 'Review Segmentation’s Volumes',
      measurements_text_15:
        'You can review Height and Weight of detected segmentation',
      measurements_text_16: 'Auto-measurements',
      measurements_text_17:
        'Start measurements and review Height and Length of segmentation',
      measurements_text_18: 'Highlight or De-highlight them on scans',
      measurements_text_19: 'Edit line to recalculate',
      measurements_text_20: 'Delete',
      measurements_text_21: "Let's continue with",
      measurements_text_22: 'Auto Measurements',
      measurements_text_23: "Let's continue with",
      measurements_text_24: 'Manual Measurement',
      measurements_text_25: 'Click to move next',
      measurements_text_26: "Let's continue with",
      measurements_text_27: "Segmentation's Area",
      measurements_text_28: 'Click to move next',
      measurements_text_29: 'Manual Measurements',
      measurements_text_30:
        'You can also draw a line to calculate distance between any two point’s on the scan.',
      measurements_text_31: 'Segmentation Area',
      measurements_text_32: 'Review Segmented Areas:',
      measurements_text_33: 'B-Scan',
      measurements_text_34: 'En-face',
      measurements_text_35: "Let's continue with",
      measurements_text_36: "Segmentation's Volume",
      measurements_text_37: 'Click to move next',
      measurements_text_38: 'Measurements',
      measurements_text_39: 'Review segmentation’s volume:',
      pathologies_detection_text_1: "Let's continue with",
      pathologies_detection_text_2: 'Pathologies Detection',
      pathologies_detection_text_3: 'Click to move next',
      pathologies_detection_text_4: 'Segmentation and classification',
      pathologies_detection_text_5:
        'Review last selected scan and Highlight or de-highlight',
      pathologies_detection_text_6: 'segmentation',
      pathologies_detection_text_7: 'Segmentation and classification',
      pathologies_detection_text_8:
        'Depending on the level of Severity, Altris AI suggests you a',
      pathologies_detection_text_9: 'Referral Urgency Score',
      pathologies_detection_text_10: 'This option can be turned off',
      pathologies_detection_text_11: 'Segmentation',
      pathologies_detection_text_12: 'Altris AI analyses scan and suggests all',
      pathologies_detection_text_13: 'Segmentations',
      pathologies_detection_text_14: 'detected on selected scan.',
      pathologies_detection_text_15:
        'You can review them on the scan and Highlight/De-highlight',
      pathologies_detection_text_16: 'Review Other Scans',
      pathologies_detection_text_17: 'You can',
      pathologies_detection_text_18: 'Select other scans',
      pathologies_detection_text_19: 'to be displayed and',
      pathologies_detection_text_20: 'Add to report',
      pathologies_detection_text_21: 'current one',
      pathologies_detection_text_22: 'Сlassification',
      pathologies_detection_text_23: 'Altris AI analyses scan and suggests all',
      pathologies_detection_text_24: 'Classifications',
      pathologies_detection_text_25: 'detected on selected scan',
      progression_text_1: "Let's continue with",
      progression_text_2: 'Progression',
      progression_text_3: 'Click to move next',
      progression_text_4: 'Progression',
      progression_text_5:
        'Add up to 8 examination to review how Segmentations progress',
      single_scan_text_1: 'Patient’s/Examination Information',
      single_scan_text_2:
        'On the top of the screen you can review general information about Patient and Examination',
      single_scan_text_3:
        'Below you can see tab menu with all system components',
      single_scan_text_4: 'Single Scan',
      single_scan_text_5: 'On the right-hand side you can see:',
      single_scan_text_6: 'Scan image',
      single_scan_text_7: 'Scan quality',
      single_scan_text_8: 'Scan number',
      single_scan_text_9: 'Scan Severity level',
      single_scan_text_10: 'Scale button',
      single_scan_text_11: 'Button to select Center scan',
      single_scan_text_12: 'Button to mark scan as "Selected"',
      single_scan_text_13: 'Single Scan',
      single_scan_text_14: 'On the left-hand side you can see:',
      single_scan_text_15: 'Scan type',
      single_scan_text_16: 'Altris supports only Horizontal B-scans',
      single_scan_text_17: 'Eye',
      single_scan_text_18: 'Severity levels and Navigation',
      single_scan_text_19:
        'Click to selected area to review scan or drag the line',
      single_scan_text_20: 'Filter by Severity',
      single_scan_text_21: 'Number of scans of each Severity level',
      single_scan_text_22: 'Button to select Center scan',
      single_scan_text_23: 'Single Scan',
      single_scan_text_24: 'You can see the',
      single_scan_text_25: 'Retina Layers',
      single_scan_text_26: 'segmentation',
      single_scan_text_27: 'for the selected scan.',
      single_scan_text_28: 'You can',
      single_scan_text_29: 'Highlight',
      single_scan_text_30: 'and De-highlight any',
      single_scan_text_31: 'ETDRS and Retina thickness map',
      single_scan_text_32: 'Select Retina thickness map view or Standard view',
      single_scan_text_33: '1/3/6 mm average thickness',
      single_scan_text_34: 'Single Scan',
      single_scan_text_35: 'GCC thickness',
      single_scan_text_36: 'Layer FROM / TO thickness',
      single_scan_text_37: 'Single layer Thickness',
      single_scan_text_38: "Let's start with",
      single_scan_text_39: 'Single Scan',
    },
  },
  common: {
    eyeType: {
      OS: 'Left (OS)',
      OD: 'Right (OD)',
    },
  },
};
