import { Language } from '../i18n';

const translation: Language = {
  dashboard: {
    error: {
      try_again: 'Riprova',
    },
    banner: {
      title1: 'Per favore, contatta',
      link: 'il team commerciale di Altris',
      title2: 'per estendere la tua iscrizione',
    },
    empty_data: {
      title: 'Nessun paziente aggiunto',
      subtitle: 'Puoi aggiungere un nuovo paziente cliccando su',
      add_new_patient: 'aggiungi nuovo paziente',
    },
    header: {
      close_filter: 'Chiudi filtro',
      open_filter: 'Filtro',
      search_placeholder: 'Cerca paziente',
      examination_filter: 'Filtro esami',
      filter_form: {
        show_scan_by_date: 'Mostra scansione per data',
        all: 'Tutti',
        today: 'Oggi',
        range: 'Intervallo',
        from: 'Da',
        to: 'A',
        created_by: 'Filtro paziente',
        doctor_select: 'Seleziona dottore',
        apply: 'Applica',
        clear: 'Cancella filtro',
      },
      add_new_patient: 'Aggiungi nuovo paziente',
      add_new_examination: 'Aggiungi nuovo esame',
      back: 'Indietro',
    },
    patient_list: {
      header: {
        patient_id: 'ID paziente',
        patient_name: 'Nome paziente',
        birth_date: 'Data di nascita',
        gender: 'Sesso',
        last_upload: 'Ultimo caricamento',
        severity_level: 'Livello di gravità',
        description: 'Descrizione',
      },
      item: {
        patient_id: 'ID paziente',
        patient_name: 'Nome paziente',
        birth_date: 'Data di nascita',
        gender: 'Sesso',
        last_upload: 'Ultimo caricamento',
        severity_level: 'Livello di gravità',
        description: 'Descrizione',
        edit: 'Modifica',
        delete: 'Elimina',
        back: 'Indietro',
        show_examinations: 'Mostra esami',
      },
    },
  },
  options: {
    buttons: {
      add: 'aggiungere',
      examination: 'esame',
    },
  },
  contact_us_page: {
    contact_us: 'Contattaci',
    fill_the_form: 'Compila il modulo seguente',
    example_short: 'Es.',
    first_name: 'Nome',
    email: 'Email',
    message: 'Messaggio',
    submit_form: 'Invia modulo',
    attach_file: 'Allega file',
    attach_screenshot:
      "Puoi allegare uno screenshot al tuo messaggio per mostrare eventuali difficoltà riscontrate. Questo potrebbe aiutare il nostro team di supporto a trovare più rapidamente l'opzione giusta per te.",
  },
  demo_user_registration: {
    back: 'Indietro',
    banner_text_1: 'Qualsiasi OCT',
    banner_text_2:
      "Esplora come l'IA analizza gli OCT:\nle scansioni sono all'interno",
    banner_text_3: 'Scansione OCT con Altris AI',
    first_step: {
      welcome: 'Benvenuto su Altris AI',
      email_label: 'Inserisci la tua email per la convalida:',
      email: 'Email',
      info: "Continuando, acconsenti automaticamente che Altris AI memorizzi la tua email per l'invio di materiale promozionale.",
      submit_form: 'Invia conferma',
    },
    second_step: {
      enter_security_code: 'Inserisci il codice di sicurezza',
      send_to: 'Questo codice di sicurezza è stato inviato a:',
      submit: 'Conferma',
      resend_code: 'Invia nuovamente codice',
      seconds_short: 'sec',
    },
    third_step: {
      add_some_info: 'Aggiungi alcune informazioni',
      full_name_label: 'Inserisci il tuo nome completo',
      full_name_placeholder: 'Nome e Cognome',
      country_label: 'Paese',
      country_placeholder: 'Scegli il paese',
      oct_label: 'Sistema OCT',
      oct_placeholder: 'Seleziona il tuo sistema OCT',
      profession_label: 'Professione',
      profession_placeholder: 'Scegli una professione',
      decision_maker_label: 'Sei un decision maker nella tua clinica/istituto?',
      promo_code_label: 'Codice promozionale (opzionale)',
      promo_code_info:
        'Contatta il distributore Altris o il team di vendita Altris',
      promo_code_placeholder: 'Inserisci il codice promozionale',
      submit: 'Completa',
    },
  },
  education: {
    title: 'Unisciti ad Altris Education',
    subtitle1:
      "Altris Education OCT diventerà la tua guida nell'apprendimento dell'interpretazione OCT. Questa app mobile è interattiva, gratuita e disponibile sempre e ovunque. I metodi educativi tradizionali, come webinar, conferenze e atlanti, non sono sufficienti per gli studenti moderni: è ora di utilizzare app mobili gratuite.",
    education: 'Formazione',
    subtitle2:
      'Su Altris Education OCT avrai accesso a milioni di scansioni OCT etichettate e allo stesso tempo farai parte di una community di specialisti della cura degli occhi. Avrai la possibilità unica di caricare scansioni OCT controverse e discuterle con colleghi di tutto il mondo. Con Altris Education OCT stabiliamo standard diagnostici più elevati che si traducono in migliori risultati per i pazienti.',
    learn_more: 'Scopri di più',
    download: "Scarica l'App Altris Education",
    oct_scans: 'Scansioni OCT',
    experts: 'Esperti',
    retina_experts: 'Oculisti esperti etichettano ogni scansione',
    eye_care_specialists: 'Specialisti della cura degli occhi nella community',
    pathological_signs: 'Segni patologici',
    pathologies: 'Patologie',
  },
  forgot_password_page: {
    first_step: {
      enter_email: 'Inserisci la tua email',
      email: 'Email',
      confirm: 'Conferma',
      or_sign_in: 'o Accedi',
    },
    second_step: {
      congratulations: 'Complimenti!',
      check_email: 'Controlla la tua email',
      back_to_sign_in: 'Torna ad Accedi',
    },
  },
  maintenance_mode: {
    title: 'Sito web in manutenzione!',
    subtitle:
      'Questo sito web è attualmente in manutenzione programmata. Dovrebbe tornare disponibile a breve.',
    refresh_page: 'Aggiorna pagina',
  },
  mobile_availability: {
    title: 'Benvenuto su Altris AI',
    subtitle:
      'Altris AI è utilizzabile solo da PC. Per usufruire di tutte le funzionalità, apri la piattaforma sul tuo computer personale.',
    link: 'Collegamento',
    copy_link: 'Copia collegamento',
    mobile_subscription: {
      title: 'Abbonamenti',
      subtitle:
        'Scegli il tuo piano di abbonamento per iniziare a utilizzare Altris AI.',
      services: 'Servizi',
    },
  },
  offline: {
    title: 'Connessione persa!',
    subtitle: 'Controlla la tua connessione internet...',
  },
  examination: {
    unavailable: {
      title: 'Dati insufficienti',
      subtitle:
        "Non ci sono abbastanza dati DICOM per poter effettuare la revisione dell'esame. Si prega di compilare il modulo vuoto nella parte superiore della pagina.",
    },
    header: {
      patient_id: 'ID paziente',
      patient_name: 'Nome paziente',
      patient_name_placeholder: 'Nome',
      patient_surname: 'Cognome paziente',
      patient_surname_placeholder: 'Cognome',
      birth_date: 'Data di nascita',
      gender: 'Sesso',
      gender_placeholder: 'Scegli',
      examination_date: 'Data esame',
      notes: 'Note',
      report_unavailable: 'Purtroppo i referti non sono disponibili.',
      select_plan: 'Seleziona un piano',
      to_use_this_feature: 'per utilizzare questa funzionalità.',
      report: 'Referto',
      export_unavailable:
        "Purtroppo l'esportazione non è disponibile per questa scansione.",
      export: 'Esporta',
      save: 'Salva',
      patient_info: 'Informazioni paziente',
      male: 'Maschio',
      female: 'Femmina',
      other: 'Altro',
      other_patient_examinations: 'Altri esami del paziente',
      examination_label:
        '{{name}} / esame: {{data}} / Occhio: {{eye}} / scansione: {{scan}}',
    },
    tabs: {
      single_scan: 'SINGOLO ESAME',
      pathologies_detection: 'DETEZIONE DELLE PATOLOGIE',
      measurements: 'MISURAZIONI',
      all_scans: 'VISUALIZZAZIONE DI TUTTI GLI ESAMI',
      both_eyes: 'VISUALIZZAZIONE DI ENTRAMBI GLI OCCHI',
      comparison: 'COMPARAZIONE DELLA SPESSORE',
      progression: 'PROGRESSIONE DELLA PATOLOGIA',
      glaucoma: 'ANALISI DEL RISCHIO DI GLAUCOMA',
    },
    single_scan: {
      severity: 'Gravità',
      off: 'Disattivato',
      thickness_map_off: 'Mappa dello spessore disattivata',
      on: 'Attivato',
      thickness_map_on: 'Mappa dello spessore attivata',
      average_thickness: 'Spessore medio {{metrics}} mm, μm',
      layers_failed: {
        title: "Si è verificato un problema durante l'analisi degli strati.",
        subtitle: 'Segnalare questo problema al supporto.',
      },
      retina_thickness_map: 'Mappa dello spessore retinico',
    },
    pathologies_detection: {
      classification: 'Classificazione',
      no_access:
        "Non hai accesso alle segmentazioni. Usa il modulo di contatto all'interno del tuo profilo.",
    },
    measurements: {
      not_available:
        "Le misurazioni non sono disponibili per l'esame corrente.",
      failed:
        'Misurazioni fallite, si prega di scrivere di questo problema al supporto.',
      in_progress:
        'Misurazioni in corso, si prega di controllare questa pagina tra poco.',
      no_plan:
        "Il tuo piano non include l'analisi delle misurazioni. Aggiorna il tuo piano.",
      scan: {
        measurements: 'Misurazioni',
        auto: 'Automatica',
        manual: 'Manuale',
        segmentation: 'Segmentazione',
        length: 'Lunghezza',
        add_measurements: 'Aggiungi misurazioni',
        no_lines: 'Nessuna linea per la scansione corrente',
        highlight: 'Evidenziare',
        horizontal: 'Orizzontale',
        vertical: 'Verticale',
        reset_measurements: ' Azzera misurazioni',
        no_pathologies: 'Nessuna patologia per la scansione corrente',
      },
      table: {
        segmentation_area: 'Area di segmentazione',
        segmentation_volume: 'Volume di segmentazione',
        combined: 'Combinato',
        separate: 'Separato',
        area_headings: {
          all_segmentations: 'Tutte le segmentazioni',
          b_scan_area: 'Area B-scan',
          b_scan_area_view: 'Visualizzazione area B-scan',
          en_face_area_view:
            '<span>Vista frontale (<sup>2</sup>  * 1000)</span>',
        },
        volume_headings: {
          all_segmentations: 'Tutte le segmentazioni',
          measured_volume_wrap: 'Volume misurato<br />(ETDRS {{number}}mm)',
          measured_volume: 'Volume misurato (ETDRS {{number}}mm)',
          en_face_area_view:
            '<span>Vista frontale (mm<sup>3</sup>  * 1000)</span>',
          en_face_area_view_nl: 'Vista frontale',
        },
        no_pathology: 'Nessuna patologia per la scansione corrente',
      },
    },
    all_scans: {
      no_scans: {
        title: 'Nessuna scansione con tali parametri.',
        subtitle: 'Per favore, prova con altri filtri.',
      },
      severity: 'Gravità',
    },
    both_eyes: {
      add_fellow_eye: 'Aggiungi occhio controlaterale',
      no_plan:
        "Il tuo piano non include l'analisi di entrambi gli occhi. Aggiorna il tuo piano.",
      severity: 'Gravità',
      left_eye: 'Orizzontale, Sinistro (OS)',
      right_eye: 'Orizzontale, Destro (OD)',
      average_thickness: 'Spessore medio {{metrics}} mm, μm',
      sync: 'Sincronizza',
      different_scan_number:
        'Due esami hanno un numero diverso di scansioni, quindi lo scorrimento non può essere sincronizzato.',
    },
    comparison: {
      add_examination: 'Aggiungi esame da confrontare',
      no_plan:
        "Il tuo piano non include l'analisi comparativa. Aggiorna il tuo piano.",
      retina_thickness_difference: 'Differenza di spessore retinico',
      retina_thickness_difference_tooltip:
        "Mostra la differenza di spessore retinico tra gli esami, dall'esame più recente al più vecchio.",
      average_thickness: 'Spessore medio {{metrics}} mm, μm',
      sync: 'Sincronizza',
      different_scan_number:
        'Due esami hanno un numero diverso di scansioni, quindi lo scorrimento non può essere sincronizzato.',
      severity: 'Gravità',
    },
    progression: {
      no_plan:
        "Il tuo piano non include l'analisi della progressione. Aggiorna il tuo piano.",
      severity: 'Gravità',
      exam_does_not_have_date: 'Uno degli esami non ha una data.',
      oldest_to_newest: 'Dal più vecchio al più recente',
      newest_to_oldest: 'Dal più recente al più vecchio',
      sort_by_date: 'Ordina per data',
    },
    glaucoma: {
      add_fellow_eye: {
        button: '+ Aggiungi occhio compagno',
        tooltip:
          "Non c'è occhio compagno nell'esame del paziente. Si prega di aggiungerlo prima di procedere",
      },
      add_examination: 'Aggiungi un altro esame',
      unavailable: {
        heading:
          "L'analisi del rischio di glaucoma è<span> non disponibile per questo esame.</span> Questo potrebbe essere dovuto a:",
        pathologies_present:
          'Patologie presenti nelle scansioni che influenzano il complesso GCC, rendendo impossibile un calcolo accurato.',
        other_factors: "Altri fattori che impediscono un'analisi accurata.",
        add_exam: 'Aggiungi esame',
      },

      alert: {
        red: {
          title: 'Alto livello di rischio di glaucoma',
          description:
            'La notevole differenza di spessore della GCL+ tra gli emisferi superiore (S) e inferiore (I) indica potenziali segni di glaucoma',
        },
        yellow: {
          title: 'Livello medio di rischio di glaucoma',
          description:
            'Sebbene le variazioni rilevate nello spessore della GCL+ degli emisferi superiore (S) e inferiore (I) non siano abbastanza significative da destare preoccupazione, si raccomandano ulteriori esami e monitoraggi per una comprensione completa del rischio',
        },
        green: {
          title: 'Nessun rischio di glaucoma',
          description:
            'La differenza di spessore calcolata tra lo spessore degli emisferi superiore (S) e inferiore (I) rientra nei parametri clinicamente accettabili.',
        },
      },
      target: {
        gcc_analysis: 'Analisi GCL+',
        gcc_thickness: 'Spessore GCL+',
        superior_inferior: 'Superiore / Inferiore',
      },
      info: {
        gcc_asymmetry: 'Asimmetria GCL+',
        od: 'OD (Destro)',
        os: 'OS (Sinistro)',
        tooltip: {
          title1: "La sezione di Analisi dell'Asimmetria GCL+ ",
          title2: ' valuta due indicatori:',
          first_item1:
            'Indice di Asimmetria: Questo parametro misura lo squilibrio tra i settori Superiore e Inferiore e cambia colore in base alla Proporzione S/I:',
          first_item2: 'Verde',
          first_item3: ' - Nessuna asimmetria',
          first_item4: 'Giallo',
          first_item5: ' - Asimmetria moderata',
          first_item6: 'Rosso',
          first_item7: ' - Asimmetria',
          second_item1: 'Lo spessore dei settori ',
          second_item2: 'Superiore e Inferiore',
          second_item3: ' separatamente.',
          description:
            "In base a una deviazione di questi indicatori dagli intervalli previsti, calcoliamo e visualizziamo l'allerta rischio Glaucoma.",
        },
        asymmetry_index: 'Indice di asimmetria',
        superior_thickness: 'Spessore superiore',
        inferior_thickness: 'Spessore inferiore',
        normal: 'Normale',
        glaucoma_alert: 'Allerta glaucoma',
        iop: 'Pressione intraoculare (IOP)',
        superior: 'Superiore',
        inferior: 'Inferiore',
      },
      hemisphere: {
        title: 'Asimmetria dello spessore GCL+ degli emisferi S-I',
        heading: "Asimmetria dell'emisfero",
        superior: 'Superiore',
        inferior: 'Inferiore',
        description: {
          description_items: {
            posterior_pole_asymmetry_analysis:
              "<strong>Analisi dell'Asimmetria del Polo Posteriore</strong> combina la mappatura dello spessore della retina del polo posteriore con l'analisi dell'asimmetria tra gli occhi e tra gli emisferi di ciascun occhio.",
            posterior_pole_retina_thickness_map:
              "<strong>Mappa dello Spessore della Retina del Polo Posteriore</strong> mostra lo spessore della retina su tutto il polo posteriore (scansione OCT a volume di 30° x 25°) per ogni occhio. Viene utilizzata una scala di colori compressa per localizzare anche le differenze più piccole nello spessore della retina. Mappa dell'Asimmetria OS-OD confronta l'occhio sinistro con l'occhio destro.",
            analysis_grid_8x8:
              "<strong>Griglia di Analisi 8x8</strong> La griglia 8x8 è posizionata in modo simmetrico rispetto all'asse fovea-discus. Per ogni cella della griglia viene indicato lo spessore medio della retina.",
            hemisphere_analysis_displays:
              "<strong>Visualizzazioni dell'Analisi degli Emisferi</strong> mostrano l'asimmetria tra l'emisfero superiore e quello inferiore. L'asse fovea-discus è la linea di simmetria orizzontale. Per ogni cella di un emisfero, lo spessore medio della retina viene confrontato con il valore nella cella corrispondente dell'emisfero opposto.",
          },
          title: '<strong>Descrizione</strong>',
          item1: 'Asimmetria dello spessore GCL+ degli emisferi S-I',
          item2:
            " Illustra l'asimmetria dello spessore GCL+ (RNFL+GCL+IPL) tra l'Emisfero Superiore e Inferiore.",
          item3: 'Una griglia 8x8',
          item4:
            " viene applicata su un'area maculare di 6x6 mm per illustrare l'asimmetria dello spessore GCL+ tra i quadranti simmetrici degli Emisferi Superiore e Inferiore.",
          item5:
            'La differenza di spessore GCL+ è riportata nei quadrati simmetrici.',
          item6: 'NB!',
          item7:
            ' Per una valutazione qualitativa dello spessore GCL+, è necessario tenere conto della presenza di alterazioni essudative nella zona maculare della retina e valutare i dati dello spessore GCL+ in un occhio controlaterale.',
        },
      },
      progression: {
        heading: 'Progresso del Rischio di Glaucoma',
        add_exam:
          'Aggiungi un altro esame per rivedere il progresso del glaucoma',
        add_all_exam: 'Tutti gli esami',
        swiper: {
          total_change: 'Cambiamento Totale',

          item: {
            edit: 'MODIFICA',
            normal: 'Normale',
            glaucoma_alert: 'Avviso glaucoma',
          },
          tooltip: {
            type_error: 'Il valore deve essere un numero',
            positive: 'Il valore deve essere maggiore di 0',
            max: 'Il valore è troppo alto',
          },
        },
        modal: {
          title: 'Seleziona gli esami che desideri aggiungere',
          description:
            'Hai più di 8 esami consentiti che possono essere aggiunti alla Progressione. Per favore, seleziona quali desideri aggiungere',
          select_exam: 'Seleziona tutti gli esami',
          test: 'Test',
          scans: 'Scansioni',
          selected: 'Selezionato',
          select_button: 'Seleziona',
        },
        graph: {
          superior_inferior_graph: {
            title: 'Spessore Superiore / Inferiore',
            tooltip: {
              asymmetry_index: 'Indice di Asimmetria',
              superior_thickness: 'Spessore Superiore',
              inferior_thickness: 'Spessore Inferiore',
            },
            legend: {
              superior: '- Superiore',
              inferior: '- Inferiore',
            },
          },
          ocular_graph: {
            title: 'Pressione Intraoculare (PIO)',
          },
        },
      },
    },
  },
  onboarding_page: {
    title: 'Benvenuto',
    all_scans: 'Tutte le scansioni',
    both_eyes: 'Entrambi gli occhi',
    comparison: 'Confronto',
    create_examination: 'Crea esame',
    create_patient: 'Crea paziente',
    header: 'Intestazione',
    measurements: 'Misurazioni',
    pathologies_detection: 'Rilevazione patologie',
    progression: 'Progressione',
    report: 'Referto',
    single_scan: 'Singola scansione',
  },
  register_user_page: {
    step_one: {
      sign_up_with_google: 'Registrati con Google',
      or: 'o',
      sing_up_with_email: 'Crea un account con e-mail',
      email_label: 'E-mail aziendale',
      website_label: "Sito web dell'istituto",
      first_name_label: 'Nome',
      last_name_label: 'Cognome',
      next: 'Avanti',
      or_sign_in: 'o Accedi',
    },
    step_two: {
      create_an_account: 'Crea un account',
      password_label: 'Crea una password',
      repeat_password_label: 'Ripeti la password',
      next: 'Avanti',
      back: 'Indietro',
    },
    step_three: {
      create_an_account: 'Crea un account',
      profession_placeholder: 'Scegli una professione',
      country_placeholder: 'Tuo paese',
      i_agree_with: 'Accetto',
      privacy_policy: 'Informativa sulla privacy',
      and: 'e',
      terms_and_conditions: 'Termini e condizioni',
      back: 'Indietro',
    },
    step_thankyou: {
      title: 'Complimenti!',
      subtitle: 'Il tuo account è stato creato correttamente!',
      button: 'Iniziamo',
    },
  },
  reset_password_page: {
    enter_new_password: 'Inserisci la tua nuova password',
    password_label: 'Crea una password',
    repeat_password_label: 'Ripeti la password',
    next: 'Avanti',
  },
  settings_page: {
    tabs: {
      profile: 'Profilo',
      report_settings: 'Impostazioni del rapporto',
      progression_settings: 'Impostazioni di progressione',
      functions: 'Funzioni',
      change_password: 'Modifica password',
      accounts_data: "Dati dell'account",
    },
    accounts_data: {
      title: "Dati dell'account",
      subtitle:
        'Crea una copia di sicurezza del contenuto del tuo account Altris AI.',
      no_examinations: 'Non hai esami creati per effettuare un backup',
      request_data: 'Richiedi dati personali',
      in_progress: 'I tuoi dati vengono creati. Attendi un momento',
      cancel_backup: 'Annulla creazione backup utente',
      download_data: 'Scarica dati',
      download_info:
        'Questi sono i tuoi dati, puoi scaricarli cliccando sul pulsante in alto. Questo archivio zip verrà salvato in {{date}}. Dopo questa data, potrai creare nuovamente un backup',
      delete_data: 'Elimina dati personali',
      delete_info:
        'Puoi anche eliminare questo archivio zip e creare nuovamente un backup',
      code_send: 'Il codice di conferma è stato inviato alla tua email',
      attempts_left: 'Tentativi rimanenti',
      example_short: 'Es.',
      confirm: 'Conferma',
      cancel: 'Annulla',
    },
    change_password: {
      password_label: 'Vecchia password',
      password_placeholder: 'Inserisci la password',
      new_password_label: 'Nuova password',
      new_password_placeholder: 'Inserisci la password',
      repeat_new_password_label: 'Ripeti password',
      repeat_new_password_placeholder: 'Inserisci la password',
      unable_to_change_password: 'Impossibile cambiare password',
      submit: 'Aggiorna password',
      code_send: 'Il codice di conferma è stato inviato alla tua email',
      attempts_left: 'Tentativi rimanenti',
      example_short: 'Es.',
      confirm: 'Conferma',
      cancel: 'Annulla',
    },
    functions_settings: {
      title: 'Impostazioni',
      subtitle: 'Modifica le impostazioni dal modulo sottostante',
      save: 'Salva impostazioni',
      on: 'on',
      off: 'off',
    },
    profile: {
      example_short: 'Es.',
      first_name: 'Nome',
      last_name: 'Cognome',
      email: 'Email',
      save: 'Salva modifiche',
      country: 'Paese',
      country_placeholder: 'Scegli il tuo paese',
      profession: 'Professione / specializzazione',
      profession_placeholder: 'Scegli una professione',
      language: 'Lingua',
    },
    progression_report: {
      title:
        'Selezionare le patologie da rimuovere dal monitoraggio nella progressione della patologia',
      subtitle:
        'Se si disattiva la patologia, non verrà visualizzata nella progressione della patologia.',
      on: 'attivo',
      off: 'disattivo',
      save_settings: 'Salva impostazioni',
    },
    report_settings: {
      clinic_name: 'Nome clinica',
      clinic_email: 'Email clinica',
      save: 'Salva modifiche',
    },
  },
  sign_in_page: {
    email_label: 'E-mail aziendale',
    password_label: 'Password',
    forgot_password: 'Password dimenticata?',
    sign_in: 'Accedi',
    or: 'o',
    sign_in_with_google: 'Accedi con Google',
  },
  subscription_page: {
    b2c: {
      subscription: 'Abbonamento',
      billing: 'Fatturazione',
      subscription_expired:
        "L'abbonamento attualmente utilizzato è scaduto. Si prega di effettuare l'upgrade a un nuovo",
      subscription_page: 'Pagina di abbonamento',
      unsubscribe: 'Puoi disiscriverti.',
      already_unsubscribed:
        'Hai già disdetto il tuo vecchio piano di abbonamento. Il tuo piano attuale sarà attivo fino a {{date}}',
      choose_plan:
        'Scegli il tuo piano perfetto. Nessun contratto. Nessun costo nascosto.',
    },
    common: {
      retina_layers_detected: 'Patologie retiniche rilevate da Altris AI',
    },
  },
  team_members_page: {
    title: 'Membri del team',
    invite_doctor: 'Invita medico',
    max_doctors:
      'Hai raggiunto il numero massimo di medici invitabili. Se vuoi invitare qualcun altro, devi prima eliminarne uno dal tuo account.',
  },
  components: {
    aside: {
      back: 'Indietro',
    },
    context_modal: {
      smart_report: {
        create_examination_report: 'Crea referto esame',
        standard: 'Standard',
        standard_plus: 'Standard+',
        advanced: 'Avanzato',
        custom: 'Personalizzato',
        progression: 'Progressione',
        creating_report: 'Creazione referto in corso...',
        loading_resources_for_report:
          'Caricamento risorse per referto {{percentage}}',
        cancel: 'Annulla',
        preview: 'Anteprima',
        components_loaded: 'Componenti caricati:',
        images_loaded: 'Immagini caricate:',
        heatmaps_loaded: 'Mappe termiche caricate:',
        progression_report: {
          description1:
            "Visualizzazione dell'evoluzione delle patologie nel tempo utilizzando l'area B-Scan, l'area En-face e i volumi. Si prega di scegliere le specifiche patologie di interesse da includere nel rapporto.",
          important: 'Nota importante:',
          description2:
            'Selezionare solo le patologie che sono punti di interesse per evitare che il rapporto diventi eccessivamente esteso.',
          select_pathologies: 'Seleziona patologie:',
          all: 'Tutte',
          on: 'ACCESO',
          off: 'SPENTO',
        },
        preset: {
          report_includes: 'Il referto include:',
          severity_detection: 'Rilevamento gravità',
          severity_detection_description:
            "Mostra quanti scan critici sono presenti nell'esame e la loro gravità.",
          oct_description: 'Descrizione analisi OCT Altris AI',
          oct_description_description:
            "Include una descrizione testuale dell'analisi dell'esame.",
          retina_layers: 'Segmentazione strati retinici',
          retina_layers_description:
            'Rappresenta tutti gli strati retinici, evidenziati in diversi colori per un uso agevole.',
          both_eyes: 'Visualizzazione entrambi gli occhi',
          both_eyes_description:
            'Mostra entrambi gli occhi per confrontare lo stato clinico.',
          both_eyes_unavailable:
            "È necessario aggiungere un secondo scan in 'Visualizzazione entrambi gli occhi' per abilitare questa funzione.",
          etdrs_calculations: 'Calcoli ETDRS',
          etdrs_calculations_description:
            'Include spessore medio a 1, 3, 6 mm; spessore di diversi strati (se selezionato sulla piattaforma); spessore dello strato selezionato (se selezionato sulla piattaforma).',
          comparison: 'Confronto spessore',
          comparison_description:
            'Include due esami dello stesso occhio e variazioni di spessore su ETDRS.',
          comparison_unavailable:
            "È necessario aggiungere un secondo scan in 'Confronto' per abilitare questa funzione.",
          auto_measurements: 'Misurazioni automatiche',
          auto_measurements_description:
            'Include misurazioni lineari delle patologie, calcolate automaticamente.',
          manual_measurements: 'Misurazioni manuali',
          manual_measurements_description:
            'Include misurazioni lineari delle patologie, calcolate manualmente (se selezionate sulla piattaforma).',
          segmentation_area: 'Area di segmentazione',
          segmentation_area_description:
            "Mostra l'elenco di tutte le patologie nell'esame e le loro aree B-Scan e En face.",
          segmentation_volume: 'Volume di segmentazione',
          segmentation_volume_description:
            "Mostra l'elenco di tutte le patologie nell'esame e il loro volume (in mm^3 o nL).",
          pathology_detection: 'Rilevamento patologie (3 scan più patologici)',
          pathology_detection_description:
            'Include i tre scan più patologici, tutte le segmentazioni e la classificazione per tali scan.',
          referral_urgency_score: 'Punteggio di urgenza per consulto',
          referral_urgency_score_description:
            "Mostra l'urgenza di un ulteriore consulto specialistico per questo caso, in base alle classificazioni riconosciute.",
          glaucoma: 'Analisi del rischio di glaucoma',
          glaucoma_description:
            "L'analisi del rischio di glaucoma è disponibile solo per gli esami in cui i segni patologici non influenzano il GCC.",
        },

        custom_report: {
          analyze_other_scan: 'Analizza un altro scan',
          check_scan_that_you_want_to_analyze:
            'Seleziona lo scan che vuoi analizzare.',
          etdrs_calculations_description:
            "È consentito solo per file Dicom che includono informazioni ETDRS. Attendi che l'analisi ETDRS sia completata correttamente per una visualizzazione corretta nel referto.",
          auto_measurements_description:
            "È necessario completare l'analisi delle misurazioni per abilitare questa funzione.",
          auto_measurements_unavailable:
            "Questo servizio non è disponibile con il tuo abbonamento. Esegui l'upgrade a Pro per utilizzarlo.",
          manual_measurements_description:
            "È necessario completare l'analisi delle misurazioni e tracciare una linea all'interno delle misurazioni manuali per abilitare questa funzione.",
          manual_measurements_unavailable:
            "Questo servizio non è disponibile con il tuo abbonamento. Esegui l'upgrade a Pro per utilizzarlo.",
          segmentation_area_description:
            "È necessario completare l'analisi delle misurazioni per abilitare questa funzione.",
          segmentation_area_unavailable:
            "Questo servizio non è disponibile con il tuo abbonamento. Esegui l'upgrade a Pro per utilizzarlo.",
          both_eyes: 'Entrambi gli occhi',
          segmentation_volume_description:
            "È necessario completare l'analisi delle misurazioni per abilitare questa funzione.",
          segmentation_volume_unavailable:
            "Questo servizio non è disponibile con il tuo abbonamento. Esegui l'upgrade a Pro per utilizzarlo.",
          comparison: 'Confronto',
          selected_scan: 'Scan selezionato:',
          select_other: 'Seleziona altro',
          added_scans: 'Scan aggiunti:',
          add_other: 'Aggiungi altro',
          no_selected_layers: 'Nessun layer selezionato.',
          select_layers: 'Seleziona layer',
        },
      },
    },
    countdown_timer: {
      days: 'Giorni',
      hours: 'Ore',
      minutes: 'Minuti',
      seconds: 'Secondi',
    },
    create_examination: {
      upload_archived_file:
        'Carica cartella archiviata (.zip) esportata dal tuo OCT. Rilevante principalmente per Heidelberg Engineering OCT.',
      create_patient_from_dicom: 'Crea un nuovo paziente da un DICOM',
      create_patient_from_dicom_description:
        'Le informazioni sul paziente verranno estratte dal file DICOM',
      notes_label: 'Es.: Mio appunto',
      notes_placeholder: 'Note per esame',
      file_label: 'Carica DICOM per completare',
      multiple_file_label: 'Carica fino a due file DICOM dello stesso paziente',
      multiple_file_description:
        "Assicurati che appartengano allo stesso paziente e includano l'occhio destro e sinistro",
      scan_width_label: 'Larghezza scansione',
      scan_width_placeholder: 'Larghezza',
      scan_width_message:
        "Assicurati di aver selezionato la larghezza di scansione corretta in quanto influisce sulle misurazioni nell'esame",
      eye_label: 'Occhio',
      eye_placeholder: 'Tipo',
      examination_date: 'Data esame',
      upload_images_to_complete: 'Carica le immagini per completare',
      right: 'Destro',
      left: 'Sinistro',
    },
    current_plan_info: {
      current_plan: 'Piano attuale',
      examinations_available: 'Esami disponibili',
      examinations_per_month: '{{count}} / Mese',
      start_date: 'Data inizio',
      expiration_date: 'Data di scadenza',
      period: 'Periodo',
      amount: 'Importo',
      status: 'Stato',
    },
    detailed_scan: {
      scan_detail: 'Dettaglio scansione',
    },
    drag_drop_list: {
      different_scan_count:
        'Due esami hanno un numero diverso di scansioni, quindi lo scorrimento non può essere sincronizzato',
      add: 'Aggiungi',
      examination: 'esame',
      no_scans_with_such_severity: 'Nessuna scansione con tale severità',
      average_thickness: 'Spessore medio {{metrics}} mm, μm',
    },
    error_fallback: {
      something_went_wrong: 'Qualcosa è andato storto',
      fix_problem: 'Risolvi il problema',
    },
    etdrs_target: {
      single_layer_thickness: 'Spessore di un singolo strato',
      multiple_layers_thickness: 'Spessore di più strati',
      gcc_thickness: 'Spessore GCC',
    },
    examination_list: {
      empty: {
        title: 'La tua lista progetti è vuota',
        subtitle: 'Puoi aggiungere un nuovo esame premendo',
        add_new_examination: 'Aggiungi nuovo esame',
      },
      header: {
        examinations: 'Esami',
        type: 'Tipo',
        date: 'Data',
      },
      item: {
        eye: 'Occhio',
        examination_failed: 'Esame fallito',
        right_short: 'D',
        left_short: 'S',
        image: 'Immagine',
        edit: 'Modifica',
        delete: 'Elimina',
      },
    },
    exam_stat_info: {
      no_severity: 'Nessuna gravità (Verde):',
      moderate_severity: 'Gravità moderata (Giallo):',
      severe: 'Grave (Rosso):',
      scans: 'Scansioni',
      severity: 'Gravità',
    },
    feedback: {
      title: 'Feedback dei clienti',
    },
    first_subscription: {
      subscription: 'Abbonamento',
      chose_plan:
        'Scegli il tuo piano perfetto. Nessun contratto. Nessun costo nascosto.',
    },
    form_components: {
      file_upload: {
        upload_your_date_here: 'Carica i tuoi dati qui',
        add_more_examinations: 'Aggiungi altri esami',
        remove_file: 'Rimuovi file{{ending}}',
        upload_file: 'carica file',
        you_added_files_one: 'Hai aggiunto {{count}} file',
        you_added_files_other: 'Hai aggiunto {{count}} file',
      },
    },
    form_wrap: {
      help: 'Aiuto',
    },
    header: {
      view_my_account: 'Visualizza il mio account',
      sign_out: 'Esci',
      subscriptions: 'Abbonamenti',
      examinations_left: 'Esami rimanenti',
      unlimited: 'Illimitati',
      add_new_patient: 'Aggiungi nuovo paziente',
      add_new_examination: 'Aggiungi nuovo esame',
      menuItems: {
        patients: 'Pazienti',
        settings: 'Impostazioni',
        subscription: 'Abbonamento',
        team_members: 'Membri del team',
        contact_us: 'Contattaci',
        onboarding: 'Introduzione',
      },
    },
    layers_filter: {
      hide: 'Nascondi',
      show_more: 'Mostra altro',
      no_items:
        "L'intelligenza artificiale non ha individuato alcuna area da segmentare.",
      all: 'Tutti',
      layers: 'Livelli:',
      segmentations: 'Segmentazioni:',
      highlight: 'Evidenzia',
    },
    layout: {
      private_layout: {
        skip: 'Salta',
        demo_banner_title:
          'Inizia un periodo di prova gratuito di 14 giorni dopo la presentazione video',
        book_a_demo: 'Prenota una chiamata',
      },
      simple_layout: {
        sign_out: 'Esci',
      },
    },
    modal: {
      analyze_scans: {
        severity: 'Gravità',
        analyze_all_scans: 'Analizza tutte le scansioni',
        no_scans: 'Nessuna scansione con questi parametri.',
        try_another_filter: 'Prova con altri filtri.',
        submit: 'Invia',
      },
      booking_meeting: {
        title: 'Siamo sicuri di poter trovare la soluzione perfetta per te!',
        book_a_demo: 'Prenota una demo',
      },
      buy_additional_items: {
        buy_additional_examinations: 'Acquista {{number}} esami aggiuntivi',
        will_be_cost: '{{description}} avrà un costo di {{symbol}}{{price}}',
      },
      create_examination: {
        title1:
          'Compila il modulo sottostante per aggiungere un nuovo esame. Carica ',
        title2: 'SOLO B-Scan orizzontali della zona maculare',
        title3: ' (Immagine del fundus, scansioni del disco ottico ',
        title4: 'NON SONO',
        title5: ' supportate)',
        select_exam_label: "Seleziona l'esame",
        patient_id_label: 'ID paziente',
        patient_id_placeholder: 'Il paziente verrà creato dal file DICOM',
        remaining_examinations: 'Esami rimanenti: {{number}}',
        unlimited: 'Illimitati',
        images: 'Immagini',
        folder: 'Cartella',
        add_examination: 'Aggiungi esame',
        right: 'Destro',
        left: 'Sinistro',
        label:
          '{{name}} / esame: {{data}} / Occhio: {{eye}} / scansione: {{scan}}',
      },
      create_patient: {
        no_exam_left1: 'Nessun esame rimanente. Puoi ',
        no_exam_left2: 'aggiornare il tuo piano',
        no_exam_left3: ' per aggiungere altri esami',
        create_new_patient_from_dicom: 'Crea un nuovo paziente da un DICOM',
        create_new_patient_from_dicom_description:
          'Le informazioni sul paziente verranno estratte dal file DICOM',
        patient_id_label: 'ID paziente',
        example_short: 'Es',
        first_name_label: 'Nome del paziente',
        last_name_label: 'Cognome del paziente',
        birth_date_label: 'Data di nascita del paziente',
        gender_label: 'Sesso',
        gender_placeholder: 'Scegli...',
        notes_label: 'Note',
        add_examination: 'Aggiungi esame',
        add_patient: 'Aggiungi paziente',
        add_patient_and_examination: 'Aggiungi paziente ed esame',
        male: 'Maschio',
        female: 'Femmina',
        other: 'Altro',
      },
      current_plan: {
        annual: 'Annuale',
        monthly: 'Mensile',
        days_left: 'Giorni rimanenti:',
        examinations_left: 'Esami rimanenti:',
        unlimited: 'Illimitati',
        invitations_left: 'Inviti rimanenti:',
        no_scheduled_payment:
          'Non hai nessun pagamento programmato in quanto non hai sottoscritto alcun piano',
        next_payment_related_to_your_upcoming_plan:
          'Il tuo prossimo pagamento è relativo al tuo piano futuro ed è di ',
        it_will_automatically_charge: 'Verrà automaticamente addebitato il ',
        your_next_payment_is: 'Il tuo prossimo pagamento è ',
        to_be_charged: ', con addebito su ',
        payment_will_be_charged_automatically:
          'Il pagamento verrà addebitato automaticamente',
        you_successfully_unsubscribed:
          'Hai effettuato correttamente la disiscrizione da un ',
        plan: 'piano',
        your_subscription_is_active: 'La tua iscrizione è attiva fino al ',
        subscribe: 'Iscriviti',
        unsubscribe: 'Disiscriviti',
        continue_subscriptions: 'Continua abbonamenti',
      },
      delete_exam: {
        cancel: 'Annulla',
        delete: 'Elimina',
      },
      delete_patient: {
        cancel: 'Annulla',
        delete: 'Elimina',
      },
      delete_team_member: {
        cancel: 'Annulla',
        delete: 'Elimina',
      },
      download_dcm: {
        dont_have_dicom_file: 'Non hai un file DICOM in questo momento?',
        use_this: 'Usa questo!',
        download: 'Scarica',
        i_have_dicom: 'Ho DICOM sul mio PC',
        download_dicom: 'Scarica DICOM',
      },
      edit_scan_description: {
        oct_description_title: "Descrizione dell'analisi OCT di Altris AI",
        save: 'Salva modifiche',
      },
      export_json: {
        dicom_presentation_state: 'Stato di presentazione DICOM',
        file: 'File',
      },
      glaucoma_disclaimer: {
        title: 'Disclaimer',
        i_acknowledged: 'Ho letto e compreso',
      },
      image_cropper: {
        cancel: 'Annulla',
        crop: 'Ritaglia',
      },
      invite_team_member: {
        email_label: 'Email',
        first_name_label: 'Nome del dottore',
        last_name_label: 'Cognome del dottore',
        example_short: 'Es',
        send_the_invitation: "Invia l'invito",
      },
      no_objects_left: {
        examinations: {
          title: 'Non puoi aggiungere altri esami',
          subtitle:
            'Non è possibile aggiungere nuovi esami fino a {{endData}} in quanto il limite di esami per il tuo piano di abbonamento è stato superato per questo mese',
          subtext:
            'A partire da {{endData}} potrai nuovamente aggiungere nuovi esami.',
        },
        plan: 'piano',
        annual: 'Annuale',
        monthly: 'Mensile',
        days_left: 'Giorni rimanenti:',
        renewal_starts_at: '(Rinnovo inizia a: {{endData}})',
        examinations_left: 'Esami rimanenti:',
        invitations_left: 'Inviti rimanenti:',
        go_to_contact_page: 'Vai alla pagina dei contatti',
        please_contact_account_holder1: 'Contatta il tuo ',
        please_contact_account_holder2: "Titolare dell'account",
        please_contact_account_holder3: ' per assistenza.',
        b2c: {
          buy_examinations_or_upgrade_plan1: 'Puoi anche ',
          buy_examinations_or_upgrade_plan2: 'acquistare esami aggiuntivi',
          buy_examinations_or_upgrade_plan3: ' o ',
          buy_examinations_or_upgrade_plan4: 'aggiornare il tuo piano',
          upgrade_plan1: 'Puoi ',
          upgrade_plan2: 'aggiornare il tuo piano',
          buy_plan: 'Acquista {{name}}',
          upgrade_to: 'Passa al piano {{name}} con {{count}} esami al mese',
          renew_subscription: 'Rinnova Abbonamento',
          upgrade_to_custom_plan: 'Passa a un piano personalizzato',
        },
      },
      onboarding_tour: {
        cancel: 'Annulla (conosco già Altris AI)',
        submit: 'Fai un giro veloce',
      },
      renew_subscribe_reject: {
        please_contact_account_holder1: 'Contatta il tuo ',
        please_contact_account_holder2: "Titolare dell'account",
        please_contact_account_holder3: ' per assistenza.',
        not_renew_subscription1:
          "Se non rinnovi l'abbonamento, ti preghiamo di inviare una richiesta",
        not_renew_subscription2: 'scarica i tuoi dati',
        not_renew_subscription3:
          ' per non perderli. Per qualsiasi domanda, non esitare a contattarci via email: ',
        not_renew_subscription4: 'support@altris.ai',
      },
      renew_subscribe_warning: {
        please_contact_account_holder1: 'Contatta il tuo ',
        please_contact_account_holder2: "Titolare dell'account",
        please_contact_account_holder3: ' per assistenza.',
        not_renew_subscription1:
          "Se non rinnovi l'abbonamento, ti preghiamo di inviare una richiesta",
        not_renew_subscription2: 'scarica i tuoi dati',
        not_renew_subscription3:
          ' per non perderli. Per qualsiasi domanda, non esitare a contattarci via email: ',
        not_renew_subscription4: 'support@altris.ai',
      },
      resend_team_member: {
        cancel: 'Annulla',
        send_the_invitation: "Invia l'invito",
      },
      select_layers: {
        layers_grayscale: 'Livelli in scala di grigi',
        layers_grayscale_description:
          "La tua scansione all'interno del report sarà in grigio.",
        confirm: 'Conferma',
      },
      subscribe_plan: {
        subscription_update: 'Aggiornamento abbonamento',
        are_you_sure_subscribe_free_plan:
          'Sei sicuro di voler sottoscrivere un piano gratuito?',
        no: 'No',
        yes: 'Si',
        subscribe_with: 'Iscriviti',
        with_5_free_days: 'con 5 giorni gratuiti',
        plan_charge:
          '{{interval}}mente verrà addebitato un costo di {{symbol}}{{price}}{{additional_item}}',
        monthly_plan_description:
          '*Lo sconto è valido solo per i primi sei {{interval}} di abbonamento',
        daily_plan_description:
          '*Lo sconto è valido solo per il primo {{interval}} di abbonamento',
      },
      survey_unsubscribe: {
        reason: 'Motivo',
        leave_your_feedback: 'Lascia il tuo feedback',
        submit: 'Invia',
      },
      change_single_scan_tab: {
        dont_show_hint:
          'Non mostrare questo suggerimento ogni volta che apro la scheda Scansione singola (questa opzione può essere modificata nelle impostazioni)',
        later: 'Più tardi',
        open_pathologies_detection_tab: 'Apri la scheda Rilevamento patologie',
      },
      trial_period_end: {
        title: 'Periodo di prova scaduto',
        subtitle:
          "Il tuo periodo di prova è terminato il <0>{{date}}</0>. Per discutere l'acquisto della versione completa del prodotto o per ulteriori informazioni, contatta il nostro <1>team commerciale</1>.",
        button: 'Ho preso atto',
      },
      update_exam: {
        patient_id: 'Paziente',
        width: 'Larghezza',
        scan_width: 'Larghezza scansione',
        right: 'Destro',
        left: 'Sinistro',
        scan_width_message:
          "Assicurati di aver selezionato la larghezza di scansione corretta in quanto influisce sulle misurazioni nell'esame",
        eye: 'Occhio',
        type: 'Tipo',
        examination_date: 'Data esame',
        notes_label: 'Note',
        example_short: 'Es',
        update_examination: 'Aggiorna esame',
      },
      update_patient: {
        patient_id_label: 'ID paziente',
        example_short: 'Es',
        first_name_label: 'Nome del paziente',
        last_name_label: 'Cognome del paziente',
        birth_date_label: 'Data di nascita del paziente',
        gender_label: 'Sesso',
        gender_placeholder: 'Scegli...',
        notes_label: 'Note',
        add_examination: 'Aggiungi esame',
        update_patient: 'Aggiorna paziente',
        male: 'Maschio',
        female: 'Femmina',
        other: 'Altro',
      },
    },
    no_pathology: {
      title: 'Tutto a posto!',
      subtitle: 'Nessuna patologia rilevata',
    },
    opacity_changer: {
      opacity: 'Opacità',
    },
    open_exam_button: {
      text: 'Apri questo esame',
    },
    pathologies_progression: {
      title: 'Progressione delle patologie',
      add_another_examination:
        'Aggiungi un altro esame per analizzare la progressione delle patologie',
      no_pathologies:
        'Non è possibile mostrare la progressione delle patologie perché non ne sono state rilevate',
      selected_pathology: 'Patologia selezionata',
      volume: 'Volume',
      units_in_etdrs: 'Unità in ETDRS',
      b_scan_area: 'Area B-Scan',
      en_face_area: 'Area En Face',
      total_change: 'Variazione totale',
      search_pathologies: 'Cerca patologie',
      number_of_pathological: 'Numero di aree patologiche: {{number}}',
    },
    pay_by_stripe: {
      add_new_card: 'o aggiungi una nuova carta di credito/debito',
      pay_via: 'Paga con carta di credito/debito',
    },
    payment_info: {
      no_payments: 'Non ci sono ancora pagamenti',
      you_can_subscribe:
        'Puoi abbonarti a un piano cliccando sul pulsante sottostante',
      subscribe_plan: 'Iscriviti al piano',
      description: 'Descrizione',
      date: 'Data',
      time: 'Ora',
      charged: 'Addebitato',
      status: 'Stato',
    },
    range_filter: {
      left: 'Sinistra',
      right: 'Destra',
      horizontal: 'Orizzontale',
    },
    referral_score: {
      title: 'Punteggio di urgenza per la visita specialistica',
      good_message: 'Visita specialistica non necessaria',
      bad_message: 'Visita specialistica urgente necessaria',
    },
    scan_description: {
      title: "Descrizione dell'analisi OCT di Altris AI",
      left: 'Sinistra',
      right: 'Destra',
      eye: 'Occhio',
      average_rnfl_rpe:
        'Spessore medio RNFL-RPE in un anello foveale centrale con diametro di 1mm',
      following_pathological_signs:
        'Sono stati visualizzati i seguenti segni patologici',
      changes_could_be_classified_as:
        'Questi cambiamenti possono essere classificati come',
      edit: 'Modifica',
      copy: 'Copia',
      show_more: 'Mostra di più',
      hide: 'Nascondi',
    },
    scan_overlay: {
      length: 'Lunghezza',
    },
    segmentation_table: {
      low_confidence: 'Bassa affidabilità',
      low_confidence_description:
        'Altris AI ha rilevato questa classificazione con bassa affidabilità.',
      no_classification: 'Nessuna classificazione per questa scansione.',
      banner_tooltip:
        'Vengono visualizzate solo le malattie coperte dal tuo piano. Tuttavia, sono state identificate diverse altre patologie. Se vuoi evidenziarle, aggiorna il tuo piano.',
      more_pathologies_found:
        'Sono state trovate altre patologie che mancano al tuo piano. Per visualizzarle, aggiorna il tuo piano.',
      upgrade_plan: 'Aggiorna il mio piano',
    },
    severity_level: {
      no_severity: "Nessuna severità nell'esame",
      no_severity_plural: 'Nessuna severità negli esami',
      yellow_severity_scan: "L'esame include una scansione di severità Gialla",
      yellow_severity_scan_plural: 'Scansione di severità Gialla negli esami',
      red_severity_scan: "L'esame include una scansione di severità Rossa",
      red_severity_scan_plural: 'Scansione di severità Rossa negli esami',
    },
    severity_select: {
      sort: 'Ordina',
      sort_by: 'Ordina per',
      high_low: 'Severità: Alto - Basso',
      low_high: 'Severità: Basso - Alto',
      in_order: 'In ordine',
    },
    single_scan: {
      add_to_report: 'Aggiungi al referto',
      review_other_scans: 'Esamina altre scansioni',
      scan_quality_tooltip1:
        'Nota che solo le scansioni con qualità 4 o superiore possono essere considerate scansioni di qualità.',
      scan_quality_tooltip2:
        'Altris AI è comunque in grado di classificarle e segmentarle, ma i risultati potrebbero essere inaccurati.',
      central_scan: 'Scansione centrale',
      severity: 'Severità',
      severity_tooltip:
        'Le scansioni sono contrassegnate in base alla gravità dei segni patologici. Verde - retina normale, Giallo - severità moderata e Rosso -  severa.',
      yellow: 'giallo',
      red: 'rosso',
      green: 'verde',
      draw_a_line: 'Traccia una linea',
      draw_a_line_info: 'Premi il tasto sinistro del mouse e trascina',
      segmentations: 'Segmentazioni',
      highlight: 'Evidenzia',
    },
    smart_report: {
      average_thickness: 'Spessore medio 1/3/6 mm, μm',
      gcc_thickness: 'Spessore GCC',
      both_eyes_view: 'Vista di entrambi gli occhi',
      thickness_comparison: 'Confronto dello spessore',
      retina_thickness_difference: 'Differenza di spessore retinico',
      etdrs_calculations: 'Calcoli ETDRS',
      from: 'Da',
      to: 'A',
      etdrs_titles: {
        average_thickness: 'Spessore medio',
        single_layer_thickness: 'Spessore singolo strato',
        multiple_layer_thickness: 'Spessore multistrato',
        gcc_thickness: 'Spessore GCC',
      },
      glaucoma: 'Glaucoma',
      auto: 'Automatico',
      auto_measurements: 'Misurazioni automatiche',
      manual: 'Manuale',
      manual_measurements: 'Misurazioni manuali',
      segmentation_area_title:
        'Area di segmentazione | Scansione: {{number}}/{{total}}',
      segmentation_volume_title:
        'Volume di segmentazione | Scansione: {{number}}/{{total}}',
      retina_layers: 'Strati retinici',
      segmentation: 'Segmentazione',
      classification: 'Classificazione',
      single_scan: 'Singola scansione',
      right: 'Destra',
      left: 'Sinistra',
      report_results: 'Risultati referto',
      clinic: 'Nome clinica',
      clinic_email: 'Email clinica',
      doctor: 'Dottore',
      patient_name: 'Nome paziente',
      birth_day: 'Data di nascita',
      gender: 'Sesso',
      eye: 'Occhio',
      exam_date: 'Data esame',
      total_scans: 'Scansioni totali',
      pathology_progression: 'Progressione della patologia',
      selected_pathology: 'Patologia selezionata:',
      en_face_area: 'Area en face',
      b_scan_area: 'Area di scansione B',
      volume: 'Volume',
      total_change: 'Cambiamento totale',
    },
    spinner_svg: {
      retry: 'Riprova',
    },
    subscription_conditions: {
      back: 'Indietro',
      detection:
        'Rilevazione di segni patologici con Intelligenza Artificiale (Segmentazione)',
      detection_and_visualization:
        'Rilevazione e visualizzazione di segni patologici con Intelligenza Artificiale (Segmentazione)',
      view_all_pathological_signs: 'Visualizza tutti i segni patologici',
    },
    subscription_plan_card: {
      need_better_solution: 'Serve una soluzione migliore per la tua clinica?',
      personal_assistant: 'Assistente Personale',
      unlimited_examinations: 'Esami Illimitati',
      unlimited_patients: 'Pazienti illimitati',
      separate_accounts_for_doctors: 'Account separati per i medici',
      onboarding_trainings_for_the_whole_team:
        'Formazione iniziale per tutto il team',
      platform_customization:
        'Personalizzazione della piattaforma in base alle tue esigenze e altro ancora.',
      contact: 'Contattaci',
      month: 'Mese',
      you_saving: 'Risparmi',
      starting_from: 'A partire da',
      details: 'Dettagli',
      current_plan: 'Piano attuale',
      active_till: 'Attivo fino al',
      subscribe: 'Iscriviti',
      days_free_trial: 'Giorni di prova gratuita {{number}}',
      best_choice: 'Scelta migliore',
      monthly_description:
        '*Lo sconto è valido solo per i primi sei {{pricingType}} di abbonamento',
      daily_description:
        '*Lo sconto è valido solo per il primo {{pricingType}} di abbonamento',
      unlimited: 'Illimitato',
      examinations_for_ai_screening:
        'Esami per lo screening IA, segmentazione IA degli strati',
      pathological_signs_detection:
        'Rilevazione e visualizzazione dei segni patologici con Intelligenza Artificiale',
      try_for_free: 'Prova gratuita',
      month_small: 'mese',
      year_small: 'anno',
    },
    subscription_plans: {
      subscription: 'Abbonamento',
      price: 'Prezzo',
      examinations_available: 'Esami disponibili',
      detect: 'Rileva',
      retina_conditions: 'Patologie della retina',
      tooltip_text:
        'Questa funzionalità è attualmente in fase di sviluppo e apparirà sulla piattaforma quando sarà pronta',
      services: 'Servizi',
      service: 'Servizio',
    },
    team_member_list: {
      doctors: 'Medici',
      email: 'Email',
      status: 'Stato',
      access_level: 'Livello di accesso',
      examination_created: 'Esame creato',
      actions: 'Azioni',
      no_doctors: 'Nessun medico è stato ancora invitato',
      you_can_add_team_member:
        'Puoi aggiungere un nuovo membro del team premendo',
      invite_doctor: 'Invita medico',
      me: 'IO',
      no_actions: 'Nessuna azione',
      resend_invitation: "Invia nuovamente l'invito",
      delete: 'Elimina',
      active: 'ATTIVO',
      pending: 'IN ATTESA',
      invite_expired: 'INVITO SCADUTO',
    },
    titled_switcher: {
      on: 'ON',
      off: 'OFF',
    },
    upload_image: {
      upload_photo: 'Carica foto',
      info: 'Formato: {{formats}}. La dimensione massima del file è {{size}}.',
    },
    welcome: {
      title:
        "Rendere facile l'interpretazione delle scansioni OCT con la piattaforma Altris AI",
      subtitle1:
        "La piattaforma Altris AI funziona solo su Windows o Mac (NON è un'app mobile).",
      subtitle2:
        'Sebbene la registrazione possa avvenire tramite il tuo cellulare',
      list_item_title1:
        'Differenziazione tra scansioni b patologiche e non patologiche in 1 minuto',
      list_item_title2:
        'Rilevamento di oltre 70 patologie retiniche, comprese quelle rare',
      list_item_title3: 'Analisi dello spessore degli strati retinici',
      how_it_works: 'Come funziona?',
      description1:
        "Altris AI aderisce completamente al GDPR, garantendo la conformità. Tutti i dati utente sono crittografati e inaccessibili a terzi. Abbiamo un certificato CE e l'autorizzazione FDA 510, a conferma del nostro impegno per gli standard normativi.",
      description2:
        'La piattaforma è stata creata da un team di esperti della retina sotto la supervisione del Direttore Medico, PhD in Oftalmologia.',
      description3:
        'Altris AI è presente su Ophthalmology Times, Eyewire, Eyes on Eyecare, The Ophthalmologist ed è supportata dalla comunità oculistica.',
      features_item_title1: 'Formato.',
      features_item_title2: 'Comunicazione.',
      features_item_title3: 'Integrazione.',
      features_item_text1:
        'Il sistema funziona con tutti i formati di dati, come DICOM, jpg e png, rendendo il sistema di gestione delle immagini oftalmiche più sicuro ed efficiente.',
      features_item_text2:
        "Gli specialisti della cura degli occhi possono condividere immagini all'interno del sistema, discuterle lasciando commenti e lavorare insieme sulle stesse immagini.",
      features_item_text3:
        'Il sistema può essere integrato anche con il sistema EHR o può funzionare autonomamente come applicazione web.',
      disclaimer:
        "Disclaimer: Altris AI è disponibile come software di sola ricerca negli USA. L'autorizzazione FDA è in corso.",
      i_acknowledged: 'Ho letto e capito',
      learn_more: 'Ulteriori informazioni',
      back: 'Indietro',
      next: 'Avanti',
      slide_one: {
        title: 'Benvenuto su Altris AI',
        subtitle:
          "Analizza l'esame OCT con Altris AI. Risparmia tempo e non perdere mai patologie rare, lievi o precoci",
        desktop_available:
          "La piattaforma Altris AI funziona solo su Windows o Mac. Non è un'app mobile.",
        registration_with_phone:
          'Sebbene la registrazione possa avvenire tramite il tuo cellulare',
        description:
          "Completamente conforme a GDPR, i dati sono crittografati e non possono essere raggiunti da terzi. Abbiamo un certificato CE e l'autorizzazione FDA è in corso.",
      },
      slide_two: {
        title: 'Gravità scansioni Gravità',
        subtitle:
          'Analizza la gravità di fino a 512 scansioni patologiche in un minuto',
        low_severity: 'Bassa gravità',
        medium_severity: 'Media gravità',
        high_severity: 'Alta gravità',
        green: 'Verde',
        yellow: 'Giallo',
        red: 'Rosso',
        description:
          "Non è necessario perdere tempo a rivedere tutte le scansioni per trovare l'area di interesse",
      },

      slide_three: {
        title: 'Rilevazione e visualizzazione delle patologie',
        subtitle:
          "Altria AI rileva oltre 70 condizioni retiniche per ogni scansione dell'esame",
        segmentation: 'Segmentazione',
        classification: 'Classificazione',
      },
      slide_four: {
        title: 'E molto altro per sfruttare al meglio la piattaforma',
        item_header1: 'Segmentazione degli strati retinici',
        item_header2: 'Mappa EDTRS e dello spessore della retina',
        item_header3: "Punteggio di urgenza dell'invio",
        item_header4: 'Misurazioni automatiche delle patologie',
        item_header5: 'Area di segmentazione',
        item_header6: 'Volume di segmentazione',
        item_header7: 'Referti intelligenti',
        item_text2:
          'Spessore medio 1/3/6 mm e mappa dello spessore della retina',
        item_text3:
          "Altris AI suggerisce il punteggio di urgenza dell'invio a seconda dei segni patologici rilevati. Va da 'Nessun invio necessario' a 'Invio urgente necessario'",
        item_text4:
          "Altris AI calcola automaticamente l'area di tutte le segmentazioni",
        item_text5:
          'Altris AI calcola automaticamente il volume di tutte le segmentazioni',
        item_text6:
          'Altris AI calcola automaticamente il volume di tutte le segmentazioni',
      },
    },
  },
  constants: {
    severity_filter_options: {
      all: 'tutte',
      green: 'verde',
      yellow: 'giallo',
      red: 'rosso',
    },
  },
  modals: {
    glaucoma_disclaimer:
      "Si prega di notare che l'analisi del rischio di glaucoma funziona solo su esami OCT dove la patologia non cambia lo spessore del complesso delle cellule gangliari",
    sign_out: {
      title: 'Stai uscendo',
      subtitle: 'Sei sicuro?',
      yes: 'Si',
      cancel: 'Annulla',
    },
    add_patient: {
      title: 'Aggiungi nuovo paziente',
      subtitle:
        "Compila il modulo sottostante per aggiungere una nuova scheda paziente all'elenco",
    },
    add_examination: 'Aggiungi nuovo esame',
    add_eye_examination: {
      title: 'Aggiungi esame oculare',
      subtitle:
        "Compila il modulo sottostante per aggiungere un nuovo esame all'elenco",
    },
    invite_team_member: {
      title: 'Invita un medico',
      subtitle:
        "Compila il modulo sottostante per aggiungere un nuovo medico all'elenco",
    },
    delete_team_member: {
      title: 'Elimina',
      subtitle: 'Vuoi davvero eliminare questo account?',
    },
    reinvite_team_member: {
      title: "Invia nuovamente l'invito",
      subtitle: "Vuoi davvero inviare nuovamente l'invito a questo account?",
    },
    delete_patient: {
      title: 'Elimina',
      subtitle: 'Vuoi davvero eliminare questo paziente?',
    },
    update_patient: {
      title: 'Modifica paziente',
      subtitle:
        "Compila il modulo sottostante per modificare la scheda paziente nell'elenco",
    },
    unsubscribe: {
      title: "Stai provando a cancellarti dall'iscrizione",
      subtitle: 'Sei sicuro?',
      yes: 'Si',
      cancel: 'Annulla',
    },
    subscribe_fake: {
      title: 'Aggiornamento sottoscrizione',
      subtitle: 'Verrai iscritto al piano gratuito',
      yes: 'Si',
      cancel: 'Annulla',
    },
    renew_subscribe_warning: {
      title1: 'Rinnova sottoscrizione',
      subtitle1:
        "Purtroppo, non abbiamo ricevuto il pagamento. La sottoscrizione che stai utilizzando è scaduta. Effettua l'upgrade a un nuovo piano di sottoscrizione per Altris AI, altrimenti il tuo accesso a Altris AI verrà rimosso",
      button_text1: 'Rinnova sottoscrizione',
      title2: 'Rinnova sottoscrizione',
      subtitle2:
        'Purtroppo, non abbiamo ricevuto il pagamento e il tuo piano è scaduto. Rinnova la sottoscrizione, altrimenti il tuo accesso a Altris AI verrà rimosso',
      button_text2: 'Rinnova',
    },
    renew_subscribe_warning_b2b: {
      title: 'Rinnova sottoscrizione',
      subtitle:
        'Purtroppo, non abbiamo ricevuto il pagamento e il tuo piano è scaduto. Rinnova la sottoscrizione, altrimenti il tuo accesso a Altris AI verrà rimosso',
      button_text: 'Vai alla pagina dei contatti',
    },
    renew_subscribe_reject: {
      title1: 'Rinnova sottoscrizione',
      subtitle1:
        "Purtroppo, non abbiamo ricevuto il pagamento. La sottoscrizione che stai utilizzando è scaduta. Effettua l'upgrade a un nuovo piano di sottoscrizione. I tuoi dati verranno conservati fino a {{date}}. Dopodiché saremo costretti ad eliminarli.",
      button_text1: 'Rinnova sottoscrizione',
      title2: 'Rinnova sottoscrizione',
      subtitle2:
        'Purtroppo, non abbiamo ricevuto il pagamento e il tuo piano è scaduto. Rinnova la sottoscrizione. I tuoi dati verranno conservati fino a {{date}}. Dopodiché saremo costretti ad eliminarli.',
      button_text2: 'Rinnova',
    },
    renew_subscribe_reject_b2b: {
      title: 'Rinnova sottoscrizione',
      subtitle:
        'Purtroppo, non abbiamo ricevuto il pagamento e il tuo piano è scaduto. Rinnova la sottoscrizione. I tuoi dati verranno conservati fino a {{date}}. Dopodiché saremo costretti ad eliminarli.',
      button_text: 'Vai alla pagina Contatti',
    },
    analyze_scans: {
      title: 'Analizza altre scansioni',
      subtitle: 'Seleziona le scansioni che desideri analizzare',
    },
    select_retina_layers:
      'Seleziona gli strati retinici da aggiungere alla relazione',
    delete_examination: {
      title: 'Elimina',
      subtitle: 'Vuoi davvero eliminare questo esame?',
    },
    update_examination: {
      title: 'Modifica',
      subtitle:
        "Compila il modulo sottostante per modificare la scheda esame nell'elenco",
    },
    onboarding_tour: {
      title: 'Benvenuto su Altris AI',
      subtitle: 'Cosa puoi aspettarti da Altris AI?',
    },
    json_exam_export: {
      title: 'Esporta i dati del tuo esame',
      subtitle: 'Scegli quali dati esportare',
    },
    unsubscribe_survey: {
      title: 'Cosa è andato storto?',
      subtitle:
        'Abbiamo notato che ti sei disiscritto da Altris Pro. Per favore, facci sapere perché?',
    },
    booking_meeting: {
      title: 'Possiamo risolverlo!',
      subtitle:
        'Prenota un breve incontro gratuito con il nostro esperto, con 15 anni di esperienza in OCT, che può rispondere a tutte le tue domande su Altris AI',
    },
    download_dicom: {
      title: 'Crea il tuo primo paziente e il tuo primo esame!',
      subtitle:
        "Per creare un esame, utilizza un file DICOM o un'immagine esportata dal tuo OCT",
    },
    download_dicom_success: {
      title: 'Download DICOM iniziato!',
      subtitle:
        "Attendi fino al termine... Una volta scaricato, clicca su 'Avanti' per creare un nuovo paziente. Successivamente, crea un nuovo esame e carica il file DICOM per l'analisi di Altris AI",
    },
    change_single_scan_tab:
      "Non dimenticare di rivedere l'individuazione delle patologie (con tecnologia AI!)",
  },
  validation: {
    required: 'Obbligatorio',
    data_invalid: 'Dati forniti non validi',
    email_invalid: 'Email fornita non valida',
    letters_numbers_spaces_hyphens:
      'In questo campo sono consentite solo lettere (en, ua, ru), numeri,punti, spazi e trattini (-)',
    min_chars: 'Deve contenere almeno {{number}} caratteri',
    max_chars: 'Deve contenere massimo {{number}} caratteri',
    password_latin_lowercase:
      'La password deve contenere lettere minuscole latine',
    password_latin_uppercase:
      'La password deve contenere lettere maiuscole latine',
    password_number: 'La password deve contenere un numero',
    password_match: 'Le password devono coincidere',
  },
  notifications: {
    you_can_add_only_2_dicom: 'Puoi aggiungere solo 2 file DICOM',
    your_data_was_added_successfully: 'Dati aggiunti correttamente',
    you_cant_add_file_of_this_format:
      'Impossibile aggiungere un file in questo formato',
    patient_was_added_successfully: 'Paziente aggiunto correttamente',
    characters_limit_exceeded: 'Limite {{number}} caratteri superato',
    download_will_started_automatically:
      'Il download verrà avviato automaticamente quando tutti i dati saranno pronti.',
    please_enter_your_card: 'Inserisci la tua carta',
    image_size_changed_successfully:
      'Dimensioni immagine modificate correttamente',
    description_copied: 'Descrizione copiata',
    description_not_copied: 'Descrizione non copiata',
    you_already_add_this_scan_form_segmentation_report:
      'Hai già aggiunto questa scansione al rapporto di segmentazione',
    scan_was_successfully_added_to_the_report:
      'Scansione aggiunta correttamente al rapporto',
    image_was_not_uploaded:
      "Immagine non caricata perché troppo grande. Carica un'immagine inferiore a 3 MB",
    resent_confirmation_email: 'Email di conferma inviata nuovamente',
    something_went_wrong: 'Qualcosa è andato storto',
    examination_etdrs_calculation_finished:
      'Esame: {{id}}. Calcolo ETDRS terminato',
    examination_etdrs_calculation_failed:
      'Esame: {{id}}. Calcolo ETDRS fallito',
    segmentation_layers_calculation_finished:
      'Esame: {{id}}. Calcolo degli strati di segmentazione terminato',
    segmentation_layers_calculation_failed:
      'Esame: {{id}}. Calcolo degli strati di segmentazione fallito',
    measurements_calculation_finished:
      'Esame: {{id}}. Calcolo delle misurazioni terminato',
    measurements_calculation_failed:
      'Esame: {{id}}. Calcolo delle misurazioni fallito',
    measurements_manual_updated: 'Misurazioni aggiornate manualmente',
    link_is_copied: 'Link copiato',
    link_not_copied: 'Link non copiato',
    your_request_sent_successfully: 'Richiesta inviata correttamente',
    you_dont_have_available_pathology_classes:
      'Non ci sono classi patologiche disponibili',
    examination_created_successfully: 'Esame creato correttamente',
    examination_deleted_successfully: 'Esame eliminato correttamente',
    examination_failed: 'Esame fallito',
    examination_available: 'Esame disponibile',
    examination_updated_successfully: 'Esame aggiornato correttamente',
    scan_added_to_favorites: 'Scansione aggiunta ai preferiti',
    scan_removed_from_favorites: 'Scansione rimossa dai preferiti',
    measurements_reset_successfully:
      'Misurazioni per questa scansione azzerate',
    measurements_updated: 'Misurazioni aggiornate',
    you_tried_upload_same_eye: 'Hai provato a caricare lo stesso occhio',
    you_tried_upload_opposite_eye: "Hai provato a caricare l'occhio opposto",
    limit_examination_is_reached: 'Limite esami raggiunto',
    examinations_have_not_enough_data:
      "L'esame non dispone di dati sufficienti",
    patient_deleted_successfully: 'Paziente eliminato correttamente',
    patient_updated_successfully:
      'Informazioni del paziente aggiornate correttamente',
    you_successfully_buy_additional_examination:
      'Esame aggiuntivo acquistato correttamente',
    settings_saved: 'Impostazioni salvate',
    subscription_plan_updated: 'Piano di abbonamento aggiornato',
    subscription_canceled: 'Abbonamento cancellato',
    doctor_invited: 'Medico invitato',
    password_changed: 'La password è stata modificata',
    backup_request_created:
      "Richiesta creata, riceverai un'email quando i tuoi dati saranno pronti",
    data_deleted: 'I tuoi dati sono stati eliminati',
    attempts_over: 'Tentativi esauriti, riprova più tardi',
    company_profile_updated: 'Profilo aziendale aggiornato',
    profile_updated: 'Profilo aggiornato correttamente',
    session_expired: 'Sessione scaduta, accedi di nuovo',
    site_under_maintenance:
      'Si prega di notare che il sito è attualmente sotto manutenzione ordinaria e tutti i servizi potrebbero funzionare temporaneamente in modo impreciso',
    progression_settings_updated:
      'Le impostazioni di progressione sono state aggiornate correttamente.',
    file_folder_is_too_large: 'Questo file/cartella è troppo grande',
  },
  user_popup_menu_links: {
    subscription: 'Abbonamento',
    contact_support: 'Contattare il supporto',
  },
  profile_links: {
    subscriptions: 'Abbonamenti',
    team_members: 'Membri del team',
    contact_us: 'Contattaci',
    documentation: 'Documentazione',
    join_altris_education: 'Unisciti a Altris Education',
    settings: 'Impostazioni',
    onboarding: 'Onboarding',
    storybook: 'Storybook',
  },
  onboarding_links: {
    complete_onboarding_again: "Completa di nuovo l'onboarding",
    download_test_dicom: 'Scarica il test DICOM',
    create_patient: 'Creare paziente',
    create_examintaion: 'Creare esame',
    header: 'Intestazione',
    single_scan: 'Scansione singola',
    pathologies_detection: 'Rilevamento delle patologie',
    measurements: 'Misure',
    all_scans: 'Tutte le scansioni',
    both_eyes: 'Entrambi gli occhi',
    comparison: 'Confronto',
    progression: 'Progressione',
    report: 'Report',
  },
  onboarding: {
    create_examination: {
      add_new_examination: 'Aggiungi nuovo esame',
      next: 'Avanti',
      examination_list: 'Elenco esami',
      step_1_text_1: 'Fare clic',
      step_1_text_2: 'per aggiungere un nuovo esame OCT al paziente',
      step_1_text_3: 'Fare clic per passare al successivo',
      step_2_text_1: 'Il primo passo sarebbe',
      step_2_text_2: 'Selezionare il paziente',
      step_3_text:
        'Oppure selezionare per Creare esame E paziente usando DICOM',
      step_4_text_1: "Cos'è DICOM?",
      step_4_text_2: 'Sono familiare con DICOM',
      step_4_text_3: 'È possibile creare un esame caricando',
      step_4_text_4: 'DICOM (preferito), cartella DICOM',
      step_4_text_5: 'o Immagini',
      step_4_text_6:
        "Importante! Assicurarsi di sapere cos'è DICOM e come esportarlo dal proprio OCT",
      step_5_text_1:
        'Trascina il DICOM nella finestra per selezionarlo per il caricamento',
      step_5_text_2: 'Carica DICOM per passare al successivo',
      step_6_text_1:
        'Dopo che il file DICOM/Immagini è stato aggiunto, è possibile fare clic su',
      step_6_text_2: 'Fare clic per passare al successivo',
      step_7_text:
        "E ora l'esame è stato aggiunto e sarà accessibile dopo che il sistema lo avrà analizzato. È possibile rivedere l'elenco di tutti gli esami del paziente dopo averci cliccato sopra.",
      step_8_text_1: "Rivediamo come appare l'esame",
      step_8_text_2: 'Fare clic per passare al successivo',
      step_dicom_text_1: 'Ho preso atto',
      step_dicom_text_2: "Cos'è un file DICOM/DCOM/.dcm?",
      step_dicom_text_3: 'DICOM/DCOM/.dcm',
      step_dicom_text_4: 'è un formato di file, che è possibile selezionare',
      step_dicom_text_5: "per esportare l'esame",
      step_dicom_text_6: 'dal proprio OCT.',
      step_dicom_text_7: 'Include:',
      step_dicom_text_8: "Tutte le scansioni dall'esame OCT",
      step_dicom_text_9:
        'È possibile selezionare quante scansioni si desidera esportare in DICOM sul proprio OCT',
      step_dicom_text_10: "Dati dell'esame necessari",
      step_dicom_text_11:
        "Suggeriamo di utilizzare DICOM invece di immagini. Poiché le immagini non includono tutti i dati dell'esame necessari, ciò comporta che diversi componenti principali del sistema non saranno in grado di rappresentare dati convenienti",
      step_dicom_text_12: 'Cosa non sarà disponibile se utilizzo le immagini?',
      step_dicom_text_13: 'Dati necessari sul paziente',
      step_dicom_text_14:
        'Se si utilizza DICOM invece di immagine, possiamo estrarre tutti i dati necessari sul paziente e crearli automaticamente',
      step_dicom_text_15: 'Come posso creare un paziente usando DICOM?',
      step_dicom_text_16: 'Cartella DICOM',
      step_dicom_text_17:
        "Se il proprio OCT può esportare dati solo in una cartella (ad es. Heidelberg Engineering OCT) è possibile archiviare questa cartella utilizzando il proprio archiviatore standard e caricare un file .zip durante la creazione di un esame. NON modificare la struttura della cartella o archiviare alcuna cartella separata all'interno di quella principale. La cartella deve essere archiviata interamente.",
      step_dicom_text_18:
        'Come esportare la cartella DICOM da Heidelberg Engineering OCT?',
      step_dicom_text_19: 'Come posso esportare il file DICOM dal mio OCT?',
      step_dicom_text_20: 'Come esportare DICOM da Nidek OCT?',
      step_dicom_text_21: 'Come esportare DICOM da Topcon OCT?',
      step_dicom_text_22: 'Come esportare DICOM da Optopol OCT?',
      step_scans_text_1: 'Solo scansioni di qualità!',
      step_scans_text_2:
        "Assicurarsi che le scansioni che si stanno caricando siano di qualità sufficiente. Altrimenti, il risultato dell'analisi non sarà soddisfacente.",
    },
    create_patient: {
      add_new_patient: 'Aggiungi nuovo paziente',
      next: 'Avanti',
      step_1_text_1: 'Iniziamo aggiungendo un nuovo paziente!',
      step_1_text_2: 'per creare un nuovo paziente',
      step_1_text_3: 'Fare clic qui per passare al successivo',
      step_2_text_1:
        'È possibile creare immediatamente Paziente ed Esame caricando il file DICOM',
      step_2_text_2: 'Basta selezionare la casella!',
      step_3_text_1:
        'Se hai selezionato di non caricare il paziente da DICOM, devi compilare tutte le informazioni sul paziente.',
      step_3_text_2:
        'Dopo aver completato tutti i dati del paziente, fare clic',
      step_4_text_1:
        'Dopo aver caricato il DICOM o compilato tutte le informazioni sul paziente, è possibile fare clic',
      step_4_text_2: 'per salvarlo.',
      step_4_text_3: 'Fare clic per passare al successivo',
      step_5_text_1: 'Elenco pazienti',
      step_5_text_2:
        'E ora il tuo paziente è stato aggiunto, quindi puoi creare un esame',
      step_5_text_3:
        'Nota: Se hai caricato il paziente usando DICOM, questo passaggio verrà automaticamente saltato',
    },
    create_report: {
      create_report: 'Crea report',
      next: 'Avanti',
      step_1_text_1: "Dopo aver scoperto l'esame, il medico può creare un",
      step_1_text_2: 'Report',
      step_1_text_3: 'Fare clic per passare al successivo',
      step_2_text_1: 'Passo 1',
      step_2_text_2:
        'Seleziona la scansione che vuoi mostrare come principale (Ultima revisione per impostazione predefinita)',
      step_2_text_3: 'Passo 2',
      step_2_text_4: 'Seleziona quali componenti vuoi includere nel report',
      step_2_text_5: 'Passo 3',
      step_2_text_6:
        'Seleziona una o più scansioni per mostrare la rilevazione delle patologie',
      step_3_text_1: 'Stampa o Salva PDF',
      step_3_text_2: 'Passo 4',
      step_3_text_3: 'Fare clic',
      step_3_text_4: 'Recensione',
      step_3_text_5:
        "per visualizzare l'anteprima del report generato e stamparlo/salvarlo in PDF",
      step_3_text_6: 'Fare clic per passare al successivo',
      step_4_text_1: 'Congratulazioni!',
      step_4_text_2: "Hai completato l'onboarding!",
      step_4_text_3: 'Sentiti libero di creare il tuo primo paziente!',
      step_4_text_4: 'Puoi sempre rivedere questa istruzione di nuovo in',
      step_4_text_5: 'Impostazioni',
    },
    general: {
      skip_onboarding: 'Salta Onboarding',
      are_you_sure_want_to_skip: "Sei sicuro di voler saltare l'onboarding?",
      continue_onboarding: 'Continua Onboarding',
    },
    show_examination: {
      tab_menu: 'Menu a schede',
      next: 'Avanti',
      all_scans_text_1: 'Continua con',
      all_scans_text_2: 'Tutte le scansioni',
      all_scans_text_3: 'Fai clic per passare al successivo',
      all_scans_text_4: 'Tutte le scansioni',
      all_scans_text_5:
        'Puoi rivedere tutte le scansioni e ordinarle per livello di gravità.',
      all_scans_text_6:
        'Inoltre, puoi selezionare di visualizzare solo le scansioni che sono state categorizzate come',
      all_scans_text_7: 'verdi',
      all_scans_text_8: 'gialle',
      all_scans_text_9: 'o',
      all_scans_text_10: 'rosse',
      all_scans_text_11: 'livello di gravità.',
      both_eyes_text_1: 'Continua con',
      both_eyes_text_2: 'Entrambi gli occhi',
      both_eyes_text_3: 'Fai clic per passare al successivo',
      both_eyes_text_4: 'Entrambi gli occhi',
      both_eyes_text_5:
        "Puoi aggiungere il secondo occhio per rivedere l'immagine completa",
      comparison_text_1: 'Continua con',
      comparison_text_2: 'Confronto',
      comparison_text_3: 'Fai clic per passare al successivo',
      comparison_text_4: 'Confronto',
      comparison_text_5:
        "Aggiungi lo stesso occhio, diagnosticato in precedenza, per confrontarlo con l'esame attuale",
      measurements_text_1: 'Continua con',
      measurements_text_2: 'Misure',
      measurements_text_3: 'Fai clic per passare al successivo',
      measurements_text_4: 'Misure',
      measurements_text_5: 'La sezione Misure consente ai medici di:',
      measurements_text_6: 'Rivedi',
      measurements_text_7: 'Altezza',
      measurements_text_8: 'e',
      measurements_text_9: 'Lunghezza',
      measurements_text_10: 'delle segmentazioni, misurate automaticamente',
      measurements_text_11: 'Disegna',
      measurements_text_12: "Linee per calcolare l'area di interesse",
      measurements_text_13: 'Rivedi le aree di segmentazione',
      measurements_text_14: 'Rivedi i volumi di segmentazione',
      measurements_text_15:
        'Puoi rivedere Altezza e Peso delle segmentazioni rilevate',
      measurements_text_16: 'Misure automatiche',
      measurements_text_17:
        'Avvia le misurazioni e rivedi Altezza e Lunghezza delle segmentazioni',
      measurements_text_18: 'Evidenzia o Deseleziona su scansioni',
      measurements_text_19: 'Modifica la linea per ricalcolare',
      measurements_text_20: 'Elimina',
      measurements_text_21: 'Continua con',
      measurements_text_22: 'Misure automatiche',
      measurements_text_23: 'Continua con',
      measurements_text_24: 'Misurazione manuale',
      measurements_text_25: 'Fai clic per passare al successivo',
      measurements_text_26: 'Continua con',
      measurements_text_27: 'Area di segmentazione',
      measurements_text_28: 'Fai clic per passare al successivo',
      measurements_text_29: 'Misure manuali',
      measurements_text_30:
        'Puoi anche disegnare una linea per calcolare la distanza tra due punti sulla scansione.',
      measurements_text_31: 'Area di segmentazione',
      measurements_text_32: 'Rivedi le aree segmentate:',
      measurements_text_33: 'B-Scan',
      measurements_text_34: 'En-face',
      measurements_text_35: 'Continua con',
      measurements_text_36: 'Volume di segmentazione',
      measurements_text_37: 'Fai clic per passare al successivo',
      measurements_text_38: 'Misure',
      measurements_text_39: 'Rivedi il volume della segmentazione:',
      pathologies_detection_text_1: 'Continua con',
      pathologies_detection_text_2: 'Rilevamento delle patologie',
      pathologies_detection_text_3: 'Fai clic per passare al successivo',
      pathologies_detection_text_4: 'Segmentazione e classificazione',
      pathologies_detection_text_5:
        "Rivedi l'ultima scansione selezionata e evidenzia o deseleziona",
      pathologies_detection_text_6: 'segmentazione',
      pathologies_detection_text_7: 'Segmentazione e classificazione',
      pathologies_detection_text_8:
        'In base al livello di gravità, Altris AI ti suggerisce uno',
      pathologies_detection_text_9: 'Punteggio di urgenza della segnalazione',
      pathologies_detection_text_10: 'Questa opzione può essere disattivata',
      pathologies_detection_text_11: 'Segmentazione',
      pathologies_detection_text_12:
        'Altris AI analizza la scansione e suggerisce tutte le',
      pathologies_detection_text_13: 'Segmentazioni',
      pathologies_detection_text_14: 'rilevate sulla scansione selezionata.',
      pathologies_detection_text_15:
        'Puoi rivederle sulla scansione e evidenziare/deselezionare',
      pathologies_detection_text_16: 'Rivedi altre scansioni',
      pathologies_detection_text_17: 'Puoi',
      pathologies_detection_text_18: 'Selezionare altre scansioni',
      pathologies_detection_text_19: 'da visualizzare e',
      pathologies_detection_text_20: 'Aggiungi al report',
      pathologies_detection_text_21: 'quella corrente',
      pathologies_detection_text_22: 'Classificazione',
      pathologies_detection_text_23:
        'Altris AI analizza la scansione e suggerisce tutte le',
      pathologies_detection_text_24: 'Classificazioni',
      pathologies_detection_text_25: 'rilevate sulla scansione selezionata',
      progression_text_1: 'Continua con',
      progression_text_2: 'Progressione',
      progression_text_3: 'Fai clic per passare al successivo',
      progression_text_4: 'Progressione',
      progression_text_5:
        'Aggiungi fino a 8 esami per rivedere come progrediscono le Segmentazioni',
      single_scan_text_1: 'Informazioni del paziente/esame',
      single_scan_text_2:
        'In cima allo schermo puoi rivedere informazioni generali su Paziente ed Esame',
      single_scan_text_3:
        'Sotto puoi vedere il menu a schede con tutti i componenti del sistema',
      single_scan_text_4: 'Scansione singola',
      single_scan_text_5: 'Sul lato destro puoi vedere:',
      single_scan_text_6: 'Immagine della scansione',
      single_scan_text_7: 'Qualità della scansione',
      single_scan_text_8: 'Numero della scansione',
      single_scan_text_9: 'Livello di gravità della scansione',
      single_scan_text_10: 'Pulsante di scala',
      single_scan_text_11: 'Pulsante per selezionare la scansione centrale',
      single_scan_text_12:
        "Pulsante per contrassegnare la scansione come 'Selezionata'",
      single_scan_text_13: 'Scansione singola',
      single_scan_text_14: 'Sul lato sinistro puoi vedere:',
      single_scan_text_15: 'Tipo di scansione',
      single_scan_text_16: 'Altris supporta solo B-scans orizzontali',
      single_scan_text_17: 'Occhio',
      single_scan_text_18: 'Livelli di gravità e navigazione',
      single_scan_text_19:
        "Fai clic sull'area selezionata per rivedere la scansione o trascina la linea",
      single_scan_text_20: 'Filtra per gravità',
      single_scan_text_21: 'Numero di scansioni di ogni livello di gravità',
      single_scan_text_22: 'Pulsante per selezionare la scansione centrale',
      single_scan_text_23: 'Scansione singola',
      single_scan_text_24: 'Puoi vedere gli',
      single_scan_text_25: 'Strati della retina',
      single_scan_text_26: 'segmentazione',
      single_scan_text_27: 'per la scansione selezionata.',
      single_scan_text_28: 'Puoi',
      single_scan_text_29: 'Evidenziare',
      single_scan_text_30: 'e Deselezionare qualsiasi',
      single_scan_text_31: 'Mappa di spessore della retina e ETDRS',
      single_scan_text_32:
        'Seleziona la vista della mappa di spessore della retina o la vista standard',
      single_scan_text_33: 'Spessore medio di 1/3/6 mm',
      single_scan_text_34: 'Scansione singola',
      single_scan_text_35: 'Spessore GCC',
      single_scan_text_36: 'Spessore del livello DA / A',
      single_scan_text_37: 'Spessore del singolo strato',
      single_scan_text_38: 'Cominciamo con',
      single_scan_text_39: 'Scansione singola',
    },
  },
  common: {
    eyeType: {
      OS: 'Left (OS)',
      OD: 'Right (OD)',
    },
  },
};

export default translation;
