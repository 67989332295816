import { memo, useRef } from 'react';

import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { customSelect } from './customSelect';

import DefaultAvatarIcon from 'assets/icons/avatar.svg';
import { ReactComponent as CancelIcon } from 'assets/icons/cancel.svg';
import Button from 'components/Button';
import LanguageDropdown from 'components/LanguageDropdown';
import Picture from 'components/Picture';
import Text from 'components/Text';
import { useIsDemo } from 'hooks/useIsDemo';
import { ROUTES } from 'utils/constants';
import { ga } from 'utils/helpers';
import { signOutModal } from 'utils/openModals';

import styles from './UserPanel.module.scss';

interface UserPanelProps {
  closeHandler: () => void;
  profileData: any;
}

const UserPanel = memo(({ closeHandler, profileData }: UserPanelProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'components.header' });

  const bodyRef = useRef(null);

  const isDemo = useIsDemo();

  return (
    <div className={cn(styles.wrapper)}>
      <div
        className={styles.backdrop}
        onClick={(e) => {
          e.stopPropagation();
          closeHandler();
        }}
      />

      <div className={styles.body} ref={bodyRef}>
        <div className={styles.row}>
          <div className={styles['avatar-wrapper']}>
            <Picture
              className={styles.photo}
              src={profileData.data?.avatar || DefaultAvatarIcon}
              alt='user avatar'
            />
          </div>
          <Text className={styles.name} align='center'>
            {profileData.data?.name} {profileData.data?.surname}
          </Text>
          <Button
            className={styles.button}
            to={isDemo ? undefined : ROUTES.PROFILE.MAIN}
            appearance='light'
            round
            disabled={isDemo}
            onClick={() => {
              ga(`Open_page_Profile_Header`);
              closeHandler();
            }}
          >
            {t('view_my_account')}
          </Button>
        </div>

        <div className={styles.row}>
          <LanguageDropdown
            className={styles.select}
            selectStyles={customSelect}
          />
        </div>

        <div className={styles.row}>
          <Button
            className={styles.logout}
            appearance='ghost'
            onClick={signOutModal}
          >
            {t('sign_out')}
          </Button>
        </div>
      </div>

      <CancelIcon />
    </div>
  );
});

UserPanel.displayName = 'UserPanel';

export default UserPanel;
