import { AxiosRequestConfig } from 'axios';

import BaseRESTClient from '../../../BaseRESTClient';
import { TResponseError, TResponseSuccess } from '../../types';

import { TGlaucomaExam } from 'types/glaucoma/TGlaucomaExam';

/**
 * @function getGlaucomaProgState
 * @alias getGlaucomaProgState
 * @see https://github.com/axios/axios
 */

export type TParams = {
  examId: string;
};
export type TError = TResponseError;
export type TSuccess = TResponseSuccess<TGlaucomaExam[]>;

export async function getGlaucomaProgState(
  this: BaseRESTClient,
  params: TParams,
  config?: AxiosRequestConfig
): Promise<TSuccess> {
  if (!params.examId) throw 'Empty exam id';

  try {
    const { data } = await this.client.get<TSuccess>(
      `/examination/glaucoma/${params.examId}/get-state`,
      config
    );
    return data;
  } catch (error: any | TError) {
    if (error.data.status === 'Error') throw error.data;
    else
      throw {
        message: error?.message,
        status: error?.response.status,
      };
  }
}
