import { memo } from 'react';

import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, NavLink, useNavigate } from 'react-router-dom';

import MenuDropdown from './MenuDropdown';
import UserPopUp from './UserPopUp';

import { ReactComponent as ArrowBackIcon } from 'assets/icons/arrow-down-3101.svg';
import { ReactComponent as CrownIcon } from 'assets/icons/crown.svg';
import { ReactComponent as LogoIcon } from 'assets/icons/logo-white.svg';
import { ExamCounter } from 'components/Header/ExamCounter/ExamCounter';
import Text from 'components/Text';
import useIsUserTrialExpired from 'hooks/useIsUserTrialExpired';
import {
  currentPlanSelector,
  profileRoleSelector,
  userProfileSelector,
} from 'redux/selectors/userProfile';
import { BASE_URL, ROUTES, USER_ROLES } from 'utils/constants';

import styles from './Header.module.scss';

interface HeaderProps {
  className?: string;
}

const Header = memo((props: HeaderProps) => {
  const { className } = props;
  const userRole = useSelector(profileRoleSelector);
  const userData = useSelector(userProfileSelector);
  const userCurrentPlan = useSelector(currentPlanSelector);
  const navigate = useNavigate();
  const isTrialExpired = useIsUserTrialExpired();

  const { t } = useTranslation(undefined, { keyPrefix: 'components.header' });

  return (
    <header className={cn(styles.header, className)}>
      <div className={cn(styles.column, styles['desk-button'])}>
        <MenuDropdown />
      </div>
      <div className={cn(styles.column, styles['mobile-demo-back-button'])}>
        <button
          className={styles['back-button-wrapper']}
          onClick={() => navigate(-1)}
        >
          <ArrowBackIcon className={cn(styles.arrow)} />
        </button>
      </div>

      {process.env.REACT_APP_ENV !== 'production' && (
        <>
          <Text>{process.env.REACT_APP_ENV}</Text>
        </>
      )}

      <div className={cn(styles.logo, styles.column)}>
        <Link to={BASE_URL} title='Altris Ai Logo'>
          <LogoIcon />
        </Link>
      </div>
      <div className={cn(styles.column, styles['right-container'])}>
        <div className={styles.counter}>
          {!isTrialExpired && <ExamCounter />}
        </div>
        {userRole.model === USER_ROLES.B2C &&
          (userCurrentPlan?.slug === 'free' ? (
            <NavLink
              className={styles['plan-name']}
              to={ROUTES.PROFILE.SUBSCRIPTION_PLANS}
            >
              {t('subscriptions')}
            </NavLink>
          ) : (
            <NavLink to={ROUTES.PROFILE.SUBSCRIPTION_PLANS}>
              <Text color='dark-gray' className={styles['plan-name']}>
                <CrownIcon className={styles.crown} />
                {userCurrentPlan?.slug}
              </Text>
            </NavLink>
          ))}
        <div className={styles['wrapper-profile']}>
          {/*// @ts-ignore*/}
          {userData && <UserPopUp userData={userData} />}
        </div>
      </div>
    </header>
  );
});

Header.displayName = 'Header';

export default Header;
