import { AxiosRequestConfig } from 'axios';

import BaseRESTClient from '../../../BaseRESTClient';
import { TResponseError, TResponseSuccess } from '../../types';

import { TGlaucomaExam } from 'types/glaucoma/TGlaucomaExam';

/**
 * @function getData
 * @alias getData
 * @see https://github.com/axios/axios
 */

export interface TGetGlaucomaExaminationsParams {
  patientId: string | number;
}

export type TGetGlaucomaExaminationsError = TResponseError;

export type TGetGlaucomaExaminationsSuccess = TResponseSuccess<TGlaucomaExam[]>;

export async function getGlaucomaExaminations(
  this: BaseRESTClient,
  params: TGetGlaucomaExaminationsParams,
  config?: AxiosRequestConfig
): Promise<TResponseSuccess<TGlaucomaExam[]>> {
  try {
    const { data } = await this.client.get<TResponseSuccess<TGlaucomaExam[]>>(
      `/examination/glaucoma/get-patient-all-examinations/${params.patientId}`,
      {
        ...config,
      }
    );
    // get eyes array

    return data;
  } catch (error: any | TResponseError) {
    if (error.data.status === 'Error') throw error.data;
    else
      throw {
        message: error?.message,
        status: error?.response.status,
      };
  }
}
