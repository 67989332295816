import { Language } from '../i18n';

const translation: Language = {
  dashboard: {
    error: {
      try_again: 'Intentar nuevamente',
    },
    banner: {
      title1: 'Por favor, contacta al',
      link: 'Equipo de ventas de Altris',
      title2: 'para ampliar tu suscripción',
    },
    empty_data: {
      title: 'No se agregaron pacientes',
      subtitle: 'Puede agregar un nuevo paciente haciendo clic en',
      add_new_patient: 'agregar nuevo paciente',
    },
    header: {
      close_filter: 'Cerrar filtro',
      open_filter: 'Filtrar',
      search_placeholder: 'Buscar paciente',
      examination_filter: 'Filtro de exámenes',
      filter_form: {
        show_scan_by_date: 'Mostrar escaneo por fecha',
        all: 'Todos',
        today: 'Hoy',
        range: 'Rango',
        from: 'Desde',
        to: 'Hasta',
        created_by: 'Filtro de pacientes',
        doctor_select: 'Seleccionar doctor',
        apply: 'Aplicar',
        clear: 'Limpiar filtro',
      },
      add_new_patient: 'Agregar nuevo paciente',
      add_new_examination: 'Agregar nuevo examen',
      back: 'Atrás',
    },
    patient_list: {
      header: {
        patient_id: 'ID del paciente',
        patient_name: 'Nombre del paciente',
        birth_date: 'Fecha de nacimiento',
        gender: 'Género',
        last_upload: 'Última carga',
        severity_level: 'Nivel de gravedad',
        description: 'Descripción',
      },
      item: {
        patient_id: 'ID del paciente',
        patient_name: 'Nombre del paciente',
        birth_date: 'Fecha de nacimiento',
        gender: 'Género',
        last_upload: 'Última carga',
        severity_level: 'Nivel de gravedad',
        description: 'Descripción',
        edit: 'Editar',
        delete: 'Eliminar',
        back: 'Volver',
        show_examinations: 'Mostrar exámenes',
      },
    },
  },
  options: {
    buttons: {
      add: 'añadir',
      examination: ' examen',
    },
  },
  contact_us_page: {
    contact_us: 'Contáctenos',
    fill_the_form: 'Por favor complete el siguiente formulario',
    example_short: 'Ej.',
    first_name: 'Nombre',
    email: 'Correo electrónico',
    message: 'Su mensaje',
    submit_form: 'Enviar formulario',
    attach_file: 'Adjuntar archivo',
    attach_screenshot:
      'Puede adjuntar una captura de pantalla a su mensaje para mostrar cualquier dificultad que haya encontrado. Esto podría ayudar a nuestro equipo de soporte a encontrar la opción correcta para usted más rápidamente.',
  },
  demo_user_registration: {
    back: 'Volver',
    banner_text_1: 'Cualquier OCT',
    banner_text_2: 'Explora cómo AI analiza OCT:\nlos escaneos están adentro',
    banner_text_3: 'Escaneo OCT con Altris AI',
    first_step: {
      welcome: '¡Bienvenido a Altris AI',
      email_label: 'Ingrese su correo electrónico para validar:',
      email: 'Correo electrónico',
      info: 'Continuar implica su consentimiento automático para que Altris AI retenga su correo electrónico con el fin de enviarle material de marketing.',
      submit_form: 'Enviar confirmación',
    },
    second_step: {
      enter_security_code: 'Ingrese código de seguridad',
      send_to: 'Este código de seguridad se envió a:',
      submit: 'Confirmar',
      resend_code: 'Enviar código nuevamente',
      seconds_short: 'seg',
    },
    third_step: {
      add_some_info: 'Agregar información',
      full_name_label: 'Ingrese su nombre completo',
      full_name_placeholder: 'Nombre completo',
      country_label: 'País',
      country_placeholder: 'Elegir país',
      oct_label: 'Sistema OCT',
      oct_placeholder: 'Seleccione su sistema OCT',
      profession_label: 'Profesión',
      profession_placeholder: 'Elija una profesión',
      decision_maker_label:
        '¿Es usted un tomador de decisiones en su clínica/institución?',
      promo_code_label: 'Código promocional (opcional)',
      promo_code_info:
        'Contacto con el distribuidor de Altris o el equipo de ventas de Altris',
      promo_code_placeholder: 'Ingrese el código promocional',
      submit: 'Completar',
    },
  },
  education: {
    title: 'Únete a Altris Education',
    subtitle1:
      'Altris Education OCT se convertirá en tu guía en la educación de interpretación de OCT. Esta aplicación móvil es interactiva, gratuita y está disponible en cualquier momento y lugar. Los métodos educativos tradicionales, como seminarios web, conferencias y atlas, no son suficientes para los estudiantes modernos: es hora de utilizar aplicaciones móviles gratuitas.',
    education: 'Educación',
    subtitle2:
      'En Altris Education OCT tendrás acceso a millones de escaneos OCT etiquetados y serás parte de una comunidad de especialistas en el cuidado de los ojos al mismo tiempo. Tendrás la oportunidad única de subir escaneos OCT controvertidos y discutirlos con colegas de todo el mundo. Con Altris Education OCT, establecemos estándares de diagnóstico más altos que conducen a mejores resultados para los pacientes.',
    learn_more: 'Aprende más',
    download: 'Descargar aplicación Altris Education',
    oct_scans: 'Escáneres OCT',
    experts: 'Expertos',
    retina_experts: 'Los expertos en retina etiquetan cada escaneo',
    eye_care_specialists: 'Especialistas en cuidado ocular en la comunidad',
    pathological_signs: 'signos patológicos',
    pathologies: 'patologías',
  },
  forgot_password_page: {
    first_step: {
      enter_email: 'Ingresa tu correo electrónico',
      email: 'Correo electrónico',
      confirm: 'Confirmar',
      or_sign_in: 'o Iniciar sesión',
    },
    second_step: {
      congratulations: '¡Felicidades!',
      check_email: 'Por favor revise su correo electrónico',
      back_to_sign_in: 'Volver al inicio de sesión',
    },
  },
  maintenance_mode: {
    title: '¡Sitio web en mantenimiento!',
    subtitle:
      'Este sitio web se encuentra actualmente en mantenimiento programado. Deberíamos estar de vuelta en breve.',
    refresh_page: 'Actualizar página',
  },
  mobile_availability: {
    title: '¡Bienvenido a Altris AI',
    subtitle:
      'Altris AI solo está disponible para ser utilizado desde una PC. Por favor, abra la plataforma en su computadora personal para obtener la experiencia completa.',
    link: 'Enlace',
    copy_link: 'Copiar enlace',
    mobile_subscription: {
      title: 'Suscripciones',
      subtitle: 'Elige tu plan de suscripción para comenzar a usar Altris AI.',
      services: 'Servicios',
    },
  },
  offline: {
    title: '¡Tu conexión se ha perdido!',
    subtitle: 'Por favor, revise su conexión a Internet...',
  },
  examination: {
    unavailable: {
      title: 'Datos insuficientes',
      subtitle:
        'No hay suficientes datos en DICOM para revisar el examen. Por favor, complete el formulario vacío en la parte superior de la página.',
    },
    header: {
      patient_id: 'ID del paciente',
      patient_name: 'Nombre del paciente',
      patient_name_placeholder: 'Nombre',
      patient_surname: 'Apellido del paciente',
      patient_surname_placeholder: 'Apellido',
      birth_date: 'Fecha de nacimiento',
      gender: 'Género',
      gender_placeholder: 'Elegir',
      examination_date: 'Fecha del examen',
      notes: 'Notas',
      report_unavailable:
        'Desafortunadamente, los informes no están disponibles.',
      select_plan: 'Seleccionar plan',
      to_use_this_feature: 'para usar esta función.',
      report: 'Informe',
      export_unavailable:
        'Desafortunadamente, la exportación no está disponible para esta exploración.',
      export: 'Exportar',
      save: 'Guardar',
      patient_info: 'Información del paciente',
      male: 'Masculino',
      female: 'Femenino',
      other: 'Otro',
      other_patient_examinations: 'Exámenes de otros pacientes',
      examination_label:
        '{{name}} / examen: {{data}} / Ojo: {{eye}} / escaneo: {{scan}}',
    },
    tabs: {
      single_scan: 'ESCANEO ÚNICO',
      pathologies_detection: 'DETECCIÓN DE PATOLOGÍAS',
      measurements: 'MEDICIONES',
      all_scans: 'VISTA DE TODOS LOS ESCANEOS',
      both_eyes: 'VISTA DE AMBOS OJOS',
      comparison: 'COMPARACIÓN DE GROSOR',
      progression: 'PROGRESIÓN DE LA PATOLOGÍA',
      glaucoma: 'ANÁLISIS DE RIESGO DE GLAUCOMA',
    },
    single_scan: {
      severity: 'Gravedad',
      off: 'Desactivado',
      thickness_map_off: 'Mapa de grosor desactivado',
      on: 'Activado',
      thickness_map_on: 'Mapa de grosor activado',
      average_thickness: '{{metrics}} mm grosor promedio, μm',
      layers_failed: {
        title: 'Algo salió mal mientras analizábamos las capas.',
        subtitle: 'Por favor, informe este problema al soporte.',
      },
      retina_thickness_map: 'Mapa de grosor de la retina',
    },
    pathologies_detection: {
      classification: 'Clasificación',
      no_access:
        'No tiene acceso a las segmentaciones. Utilice el formulario de contacto dentro de su perfil.',
    },
    measurements: {
      not_available:
        'Las mediciones no están disponibles para el examen actual.',
      failed:
        'Las mediciones fallaron, por favor informe este problema al soporte.',
      in_progress:
        'Mediciones en curso, vuelva a consultar esta página un poco más tarde.',
      no_plan:
        'Su plan no incluye el análisis de mediciones. Actualice su plan.',
      scan: {
        measurements: 'Mediciones',
        auto: 'Auto',
        manual: 'Manual',
        segmentation: 'Segmentación',
        length: 'Largo',
        add_measurements: 'Agregar mediciones',
        no_lines: 'No hay líneas para el escaneo actual',
        highlight: 'Resaltar',
        horizontal: 'Horizontal',
        vertical: 'Vertical',
        reset_measurements: 'Reiniciar mediciones',
        no_pathologies: 'No hay patologías para el escaneo actual',
      },
      table: {
        segmentation_area: 'Área de segmentación',
        segmentation_volume: 'Volumen de segmentación',
        combined: 'Combinado',
        separate: 'Separado',
        area_headings: {
          all_segmentations: 'Todas las segmentaciones',
          b_scan_area: 'Área de B-scan',
          b_scan_area_view: 'Vista del área de B-scan',
          en_face_area_view:
            '<span>Vista de frente (mm<sup>2</sup> * 1000)</span>',
        },
        volume_headings: {
          all_segmentations: 'Todas las segmentaciones',
          measured_volume_wrap: 'Volumen medido<br />(ETDRS {{number}}mm)',
          measured_volume: 'Volumen medido (ETDRS {{number}}mm)',
          en_face_area_view:
            '<span>Vista de frente<br> (mm<sup>3</sup> * 1000)</span>',
          en_face_area_view_nl: 'Vista de frente ',
        },
        no_pathology: 'No hay patologías para el escaneo actual',
      },
    },
    all_scans: {
      no_scans: {
        title: 'No hay escaneos con tales parámetros.',
        subtitle: 'Por favor, pruebe con otros filtros.',
      },
      severity: 'Gravedad',
    },
    both_eyes: {
      add_fellow_eye: 'Agregar ojo contralateral',
      no_plan:
        'Su plan no incluye el análisis de ambos ojos. Actualice su plan.',
      severity: 'Gravedad',
      left_eye: 'Horizontal, Izquierdo (OS)',
      right_eye: 'Horizontal, Derecho (OD)',
      average_thickness: '{{metrics}} mm grosor promedio, μm',
      sync: 'Sincronizar',
      different_scan_number:
        'Dos exámenes tienen un número diferente de escaneos, por lo que el desplazamiento no se puede sincronizar.',
    },
    comparison: {
      add_examination: 'Agregar examen para comparar',
      no_plan:
        'Su plan no incluye el análisis de comparación. Actualice su plan.',
      retina_thickness_difference: 'Diferencia de grosor de la retina',
      retina_thickness_difference_tooltip:
        'Muestra la diferencia de grosor de la retina entre los exámenes, del examen más reciente al más antiguo.',
      average_thickness: '{{metrics}} mm grosor promedio, μm',
      sync: 'Sincronizar',
      different_scan_number:
        'Dos exámenes tienen un número diferente de escaneos, por lo que el desplazamiento no se puede sincronizar.',
      severity: 'Gravedad',
    },
    progression: {
      no_plan:
        'Su plan no incluye el análisis de progresión. Actualice su plan.',
      severity: 'Gravedad',
      exam_does_not_have_date: 'Uno de los exámenes no tiene fecha.',
      oldest_to_newest: 'De más antiguo a más reciente',
      newest_to_oldest: 'De más reciente a más antiguo',
      sort_by_date: 'Ordenar por fecha',
    },
    glaucoma: {
      add_fellow_eye: {
        button: '+ Añadir ojo compañero',
        tooltip:
          'No hay ojo compañero en el examen del paciente. Por favor, añádalo antes de continuar',
      },
      add_examination: 'Añadir otro examen',
      unavailable: {
        heading:
          'El análisis de riesgo de glaucoma está<span> no disponible para este examen.</span> Esto podría deberse a:',
        pathologies_present:
          'Patologías presentes< en las exploraciones que afectan el complejo GCC, lo que hace imposible un cálculo preciso.',
        other_factors: 'Otros factores que impiden un análisis preciso.',
        add_exam: 'Añadir examen',
      },

      alert: {
        red: {
          title: 'Alto nivel de riesgo de glaucoma',
          description:
            'La diferencia notable en el grosor del GCC entre los hemisferios superior (S) e inferior (I) indica posibles signos de glaucoma.',
        },
        yellow: {
          title: 'Nivel medio de riesgo de glaucoma',
          description:
            'Si bien las variaciones detectadas en el grosor del GCC de los hemisferios superior (S) e inferior (I) no son lo suficientemente significativas como para causar preocupación, se recomienda un examen y monitoreo adicionales para una comprensión integral del riesgo.',
        },
        green: {
          title: 'Sin riesgo de glaucoma',
          description:
            'La diferencia de grosor calculada entre el grosor del GCC de los hemisferios superior (S) e inferior (I) se encuentra dentro de los parámetros clínicamente aceptables.',
        },
      },
      target: {
        gcc_analysis: 'Análisis GCC',
        gcc_thickness: 'Grosor del GCC',
        superior_inferior: 'Superior / Inferior',
      },
      info: {
        gcc_asymmetry: 'Asimetría GCC',
        od: 'OD (Derecho)',
        os: 'OS (Izquierdo)',
        tooltip: {
          title1: 'La sección de Análisis de Asimetría GCC',
          title2: ' evalúa dos indicadores:',
          first_item1:
            'Índice de Asimetría: Este parámetro mide el desequilibrio entre los sectores Superior e Inferior y cambia de color según la Proporción S/I:',
          first_item2: 'Verde',
          first_item3: ' - Sin asimetría',
          first_item4: 'Amarillo',
          first_item5: ' - Asimetría moderada',
          first_item6: 'Rojo',
          first_item7: ' - Asimetría',
          second_item1: 'El ',
          second_item2: 'grosor de los sectores Superior e Inferior',
          second_item3: ' por separado.',
          description:
            'A partir de una desviación de estos indicadores de los rangos esperados, calculamos y mostramos la alerta de riesgo de Glaucoma.',
        },
        asymmetry_index: 'Índice de asimetría',
        superior_thickness: 'Grosor superior',
        inferior_thickness: 'Grosor inferior',
        normal: 'Normal',
        glaucoma_alert: 'Alerta de glaucoma',
        iop: 'Presión intraocular (PIO)',
        superior: 'Superior',
        inferior: 'Inferior',
      },
      hemisphere: {
        title: 'S-I Hemi esfera GCC asimetría del grosor',
        heading: 'Asimetría del hemisferio',
        superior: 'Superior',
        inferior: 'Inferior',
        description: {
          description_items: {
            posterior_pole_asymmetry_analysis:
              '<strong>Análisis de la Asimetría del Polo Posterior</strong> combina el mapeo del grosor de la retina en el polo posterior con el análisis de la asimetría entre los ojos y entre los hemisferios de cada ojo.',
            posterior_pole_retina_thickness_map:
              '<strong>Mapa del Grosor de la Retina en el Polo Posterior</strong> muestra el grosor de la retina en todo el polo posterior (escaneo OCT de volumen de 30° x 25°) para cada ojo. Se utiliza una escala de colores comprimida para localizar incluso las diferencias más pequeñas en el grosor de la retina. Mapa de Asimetría OS-ODcompara el ojo izquierdo con el ojo derecho.',
            analysis_grid_8x8:
              '<strong>Cuadrícula de Análisis 8x8</strong> La cuadrícula 8x8 está posicionada de manera simétrica con respecto al eje fovea-disco. Para cada celda de la cuadrícula se indica el grosor medio de la retina.',
            hemisphere_analysis_displays:
              '<strong>Visualizaciones del Análisis de Hemisferios</strong> muestran la asimetría entre el hemisferio superior e inferior. El eje fovea-disco es la línea de simetría horizontal. Para cada celda de un hemisferio, el grosor medio de la retina se compara con el valor en la celda correspondiente del hemisferio opuesto.',
          },

          title: '<strong>Descripción</strong>',

          item1: 'S-I Hemi esfera GCC asimetría del grosor',
          item2:
            'Ilustra la asimetría del grosor del GCC (RNFL+GCL+IPL) entre la Hem esfera Superior y la Inferior.',
          item3: 'Una cuadrícula de 8x8',
          item4:
            ' se superpone en un área macular de 6x6 mm para ilustrar la asimetría del grosor del GCC entre los cuadrantes simétricos de las Hem esferas Superior e Inferior.',
          item5:
            'La diferencia en el grosor del GCC se da en los cuadrados simétricos.',
          item6: '¡NB!',
          item7:
            ' Para una evaluación cualitativa del grosor del GCC, es necesario tener en cuenta la presencia de cambios exudativos en la zona macular de la retina y evaluar los datos del grosor del GCC en el ojo contralateral.',
        },
      },
      progression: {
        heading: 'Progresión del Riesgo de Glaucoma',
        add_exam: 'Añadir otro examen para revisar la progresión del glaucoma',
        add_all_exam: 'Todas las exámenes',
        swiper: {
          total_change: 'Cambio Total',

          item: {
            edit: 'EDITAR',
            normal: 'Normal',
            glaucoma_alert: 'Alerta de glaucoma',
          },
          tooltip: {
            type_error: 'El valor debe ser un número',
            positive: 'El valor debe ser mayor que 0',
            max: 'El valor es demasiado alto',
          },
        },
        modal: {
          title: 'Selecciona los exámenes que deseas agregar',
          description:
            'Tienes más de 8 exámenes permitidos que pueden ser agregados a la Progresión. Por favor, selecciona cuáles deseas agregar',
          select_exam: 'Seleccionar todos los exámenes',
          test: 'Prueba',
          scans: 'Escaneos',
          selected: 'Seleccionado',
          select_button: 'Seleccionar',
        },
        graph: {
          superior_inferior_graph: {
            title: 'Espesor Superior / Inferior',
            tooltip: {
              asymmetry_index: 'Índice de Asimetría',
              superior_thickness: 'Espesor Superior',
              inferior_thickness: 'Espesor Inferior',
            },
            legend: {
              superior: '- Superior',
              inferior: '- Inferior',
            },
          },
          ocular_graph: {
            title: 'Presión Intraocular (PIO)',
          },
        },
      },
    },
  },
  onboarding_page: {
    title: 'Registro inicial',
    all_scans: 'Todos los escaneos',
    both_eyes: 'Ambos ojos',
    comparison: 'Comparación',
    create_examination: 'Crear examen',
    create_patient: 'Crear paciente',
    header: 'Encabezado',
    measurements: 'Mediciones',
    pathologies_detection: 'Detección de patologías',
    progression: 'Progresión',
    report: 'Informe',
    single_scan: 'Escaneo único',
  },
  register_user_page: {
    step_one: {
      sign_up_with_google: 'Registrarse con Google',
      or: 'o',
      sing_up_with_email: 'Crear una cuenta con correo electrónico',
      email_label: 'Correo electrónico corporativo',
      website_label: 'Sitio web de la institución',
      first_name_label: 'Nombre',
      last_name_label: 'Apellido',
      next: 'Siguiente',
      or_sign_in: 'o Iniciar sesión',
    },
    step_two: {
      create_an_account: 'Crear una cuenta',
      password_label: 'Crear una contraseña',
      repeat_password_label: 'Repetir contraseña',
      next: 'Siguiente',
      back: 'Atrás',
    },
    step_three: {
      create_an_account: 'Crear una cuenta',
      profession_placeholder: 'Elegir una profesión',
      country_placeholder: 'Tu país',
      i_agree_with: 'Estoy de acuerdo con la',
      privacy_policy: 'Política de privacidad',
      and: 'y',
      terms_and_conditions: 'Términos y condiciones',
      back: 'Atrás',
    },
    step_thankyou: {
      title: '¡Felicitaciones!',
      subtitle: '¡Su cuenta ha sido creada exitosamente!',
      button: 'Comencemos',
    },
  },
  reset_password_page: {
    enter_new_password: 'Ingrese su nueva contraseña',
    password_label: 'Crear una contraseña',
    repeat_password_label: 'Repetir contraseña',
    next: 'Siguiente',
  },
  settings_page: {
    tabs: {
      profile: 'Perfil',
      report_settings: 'Configuración del informe',
      progression_settings: 'Configuración de progresión',
      functions: 'Funciones',
      change_password: 'Cambiar contraseña',
      accounts_data: 'Datos de la cuenta',
    },
    accounts_data: {
      title: 'Datos de la cuenta',
      subtitle:
        'Cree una copia del contenido de su cuenta de Altris AI para realizar una copia de seguridad.',
      no_examinations:
        'No tiene exámenes creados para crear una copia de seguridad',
      request_data: 'Solicitar datos personales',
      in_progress: 'Sus datos se están creando. Espere un momento',
      cancel_backup:
        'Cancelar la creación de una copia de seguridad del usuario',
      download_data: 'Descargar datos',
      download_info:
        'Aquí están sus datos, puede descargarlos haciendo clic en el botón de arriba. Este archivo zip se guardará hasta el {{date}}. Después de esta fecha, puede crear una copia de seguridad una vez más.',
      delete_data: 'Eliminar datos personales',
      delete_info:
        'También puede eliminar este archivo zip y crear una copia de seguridad una vez más',
      code_send: 'Se envió un código de confirmación a su correo electrónico.',
      attempts_left: 'Intentos restantes',
      example_short: 'Ej.',
      confirm: 'Confirmar',
      cancel: 'Cancelar',
    },
    change_password: {
      password_label: 'Su contraseña anterior',
      password_placeholder: 'Ingrese su contraseña',
      new_password_label: 'Tu nueva contraseña',
      new_password_placeholder: 'Ingrese su contraseña',
      repeat_new_password_label: 'Repetir contraseña',
      repeat_new_password_placeholder: 'Ingrese su contraseña',
      unable_to_change_password: 'Lo sentimos, no puede cambiar su contraseña',
      submit: 'Actualizar mi contraseña',
      code_send: 'Se envió un código de confirmación a su correo electrónico.',
      attempts_left: 'Intentos restantes',
      example_short: 'Ej.',
      confirm: 'Confirmar',
      cancel: 'Cancelar',
    },
    functions_settings: {
      title: 'Ajustes',
      subtitle: 'Cambie la configuración desde el siguiente formulario',
      save: 'Guardar ajustes',
      on: 'Activado',
      off: 'Desactivado',
    },
    profile: {
      example_short: 'Ej.',
      first_name: 'Nombre',
      last_name: 'Apellido',
      email: 'Correo electrónico',
      save: 'Guardar cambios',
      country: 'País',
      country_placeholder: 'Tu país',
      profession: 'Profesión / especialidad',
      profession_placeholder: 'Elegir una profesión',
      language: 'Idioma',
    },
    progression_report: {
      title:
        'Seleccione las patologías que desea eliminar del seguimiento en la progresión de la patología',
      subtitle:
        'Si desactiva la patología, no se mostrará en la progresión de la patología.',
      on: 'encendido',
      off: 'apagado',
      save_settings: 'Guardar ajustes',
    },
    report_settings: {
      clinic_name: 'Nombre de la clínica',
      clinic_email: 'Correo electrónico de la clínica',
      save: 'Guardar cambios',
    },
  },
  sign_in_page: {
    email_label: 'Correo electrónico corporativo',
    password_label: 'Contraseña',
    forgot_password: '¿Olvidó su contraseña?',
    sign_in: 'Iniciar sesión',
    or: 'o',
    sign_in_with_google: 'Iniciar sesión con Google',
  },
  subscription_page: {
    b2c: {
      subscription: 'Suscripción',
      billing: 'Facturación',
      subscription_expired:
        'La suscripción que está utilizando actualmente ha expirado. Actualice a una nueva',
      subscription_page: 'Página de suscripción',
      unsubscribe: 'Puede cancelar la suscripción.',
      already_unsubscribed:
        'Ya ha cancelado su antiguo plan de suscripción. Su plan actual estará activo hasta {{date}}',
      choose_plan:
        'Elige tu plan perfecto. Sin contratos. Sin tarifas sorpresa.',
    },
    common: {
      retina_layers_detected:
        'Condiciones de la retina detectadas por Altris AI',
    },
  },
  team_members_page: {
    title: 'Miembros del equipo',
    invite_doctor: 'Invitar a un doctor',
    max_doctors:
      'Hay un número máximo de médicos que puede invitar. Si desea invitar a alguien nuevo, primero debe eliminar a uno de su cuenta.',
  },
  components: {
    aside: {
      back: 'Back',
    },
    context_modal: {
      smart_report: {
        create_examination_report: 'Crear informe de examen',
        standard: 'Estándar',
        standard_plus: 'Estándar+',
        advanced: 'Avanzado',
        progression: 'Progresión',
        custom: 'Personalizado',
        creating_report: 'Creando informe...',
        loading_resources_for_report:
          'Cargando recursos para el informe {{percentage}}',
        cancel: 'Cancelar',
        preview: 'Previsualizar',
        components_loaded: 'Componentes cargados:',
        images_loaded: 'Imágenes cargadas:',
        heatmaps_loaded: 'Mapas de calor cargados:',
        progression_report: {
          description1:
            'Visualización de la evolución de las patologías con el tiempo utilizando el área de B-Scan, el área En-face y los volúmenes. Por favor, elija las patologías específicas de interés para incluir en el informe.',
          important: 'Nota importante:',
          description2:
            'Seleccione solo las patologías que sean puntos de interés para evitar que el informe se vuelva excesivamente extenso.',
          select_pathologies: 'Seleccionar patologías:',
          all: 'Todas',
          on: 'ENCENDIDO',
          off: 'APAGADO',
        },
        preset: {
          report_includes: 'El informe incluye:',
          severity_detection: 'Detección de severidad',
          severity_detection_description:
            'Muestra cuántos escaneos graves hay en el examen y cuál es su gravedad.',
          oct_description: 'Descripción del análisis OCT de Altris AI',
          oct_description_description:
            'Incluye una descripción textual del análisis del examen.',
          retina_layers: 'Segmentación de capas de la retina',
          retina_layers_description:
            'Representa todas las capas de la retina, resaltadas en diferentes colores para un uso cómodo.',
          both_eyes: 'Vista de ambos ojos',
          both_eyes_description:
            'Muestra ambos ojos para comparar el estado clínico.',
          both_eyes_unavailable:
            'Debe agregar un segundo escaneo dentro de "Vista de ambos ojos" para habilitar esto.',
          etdrs_calculations: 'Cálculos ETDRS',
          etdrs_calculations_description:
            'Incluye grosor promedio de 1, 3 y 6 mm; Grosor de varias capas (si se selecciona en la plataforma); Grosor de la capa seleccionada (si se selecciona en la plataforma).',
          comparison: 'Comparación de grosor',
          comparison_description:
            'Incluye dos exámenes del mismo ojo y los cambios de grosor en ETDRS.',
          comparison_unavailable:
            'Debe agregar un segundo escaneo dentro de "Comparación" para habilitar esto.',
          auto_measurements: 'Mediciones automáticas',
          auto_measurements_description:
            'Incluye mediciones lineales de patologías, calculadas automáticamente.',
          manual_measurements: 'Mediciones manuales',
          manual_measurements_description:
            'Incluye mediciones lineales de patologías, calculadas manualmente (si se selecciona en la plataforma).',
          segmentation_area: 'Área de segmentación',
          segmentation_area_description:
            'Muestra la lista de todas las patologías en el examen y sus áreas B-Scan y En face.',
          segmentation_volume: 'Volumen de segmentación',
          segmentation_volume_description:
            'Muestra la lista de todas las patologías en el examen y su volumen (en mm^3 o nL).',
          pathology_detection:
            'Detección de patología (3 escaneos más patológicos)',
          pathology_detection_description:
            'Incluye los tres escaneos más patológicos, todas las segmentaciones y la clasificación de esos escaneos.',
          referral_urgency_score: 'Puntaje de urgencia de derivación',
          referral_urgency_score_description:
            'Muestra la urgencia con la que se necesita una nueva derivación para este caso según las clasificaciones reconocidas.',
          glaucoma: 'Análisis de riesgo de Glaucoma',
          glaucoma_description:
            'El análisis de riesgo de glaucoma solo está disponible para exámenes donde los signos patológicos no afectan el GCC',
        },
        custom_report: {
          analyze_other_scan: 'Analizar otro escaneo',
          check_scan_that_you_want_to_analyze:
            'Marque el escaneo que desea analizar.',
          etdrs_calculations_description:
            'Solo está permitido para archivos Dicom que incluyen información ETDRS. Espere hasta que el análisis ETDRS se haya completado con éxito para una vista correcta en el informe.',
          auto_measurements_description:
            'Su análisis de medición debe estar terminado para habilitar esto.',
          auto_measurements_unavailable:
            'Este servicio no está disponible en su suscripción. Por favor, actualice a Pro para usar',
          manual_measurements_description:
            'Su análisis de medición debe estar terminado, y debe dibujar una línea dentro de las mediciones manuales para habilitar esto.',
          manual_measurements_unavailable:
            'Este servicio no está disponible en su suscripción. Por favor, actualice a Pro para usar',
          segmentation_area_description:
            'Su análisis de medición debe estar terminado para habilitar esto.',
          segmentation_area_unavailable:
            'Este servicio no está disponible en su suscripción. Por favor, actualice a Pro para usar',
          both_eyes: 'Ambos ojos',
          segmentation_volume_description:
            'Su análisis de medición debe estar terminado para habilitar esto.',
          segmentation_volume_unavailable:
            'Este servicio no está disponible en su suscripción. Por favor, actualice a Pro para usar',
          comparison: 'Comparación',
          selected_scan: 'Escaneo seleccionado:',
          select_other: 'Seleccionar otro',
          added_scans: 'Escaneos agregados:',
          add_other: 'Agregar otro',
          no_selected_layers: 'No hay capas seleccionadas.',
          select_layers: 'Seleccionar capas',
        },
      },
    },
    countdown_timer: {
      days: 'Días',
      hours: 'Horas',
      minutes: 'Minutos',
      seconds: 'Segundos',
    },
    create_examination: {
      upload_archived_file:
        'Cargar carpeta archivada (.zip) exportada desde su OCT. Principalmente relevante para Heidelberg Engineering OCT.',
      create_patient_from_dicom: 'Crear un paciente nuevo desde un DICOM',
      create_patient_from_dicom_description:
        'La información sobre el paciente se extraerá del archivo DICOM',
      notes_label: 'Ej.: Mi nota',
      notes_placeholder: 'Notas para el examen',
      file_label: 'Subir DICOM para completar',
      multiple_file_label: 'Subir hasta dos archivos DICOM del mismo paciente',
      multiple_file_description:
        'Asegúrese de que pertenezcan a un paciente e incluyan los ojos derecho e izquierdo.',
      scan_width_label: 'Ancho de escaneo',
      scan_width_placeholder: 'Ancho',
      scan_width_message:
        'Asegúrese de haber seleccionado el ancho de escaneo correcto, ya que afecta las mediciones en el examen.',
      eye_label: 'Ojo',
      eye_placeholder: 'Escriba',
      examination_date: 'Fecha de examen',
      upload_images_to_complete: 'Subir imágenes para completar',
      right: 'Derecho',
      left: 'Izquierdo',
    },
    current_plan_info: {
      current_plan: 'Plan actual',
      examinations_available: 'Exámenes disponibles',
      examinations_per_month: '{{count}} / Mes',
      start_date: 'Fecha de inicio',
      expiration_date: 'Fecha de vencimiento',
      period: 'Periodo',
      amount: 'Cantidad',
      status: 'Estado',
    },
    detailed_scan: {
      scan_detail: 'Detalle del escaneo',
    },
    drag_drop_list: {
      different_scan_count:
        'Dos exámenes tienen un número diferente de escaneos, por lo que el desplazamiento no se puede sincronizar.',
      add: 'Añadir',
      examination: 'examen',
      no_scans_with_such_severity: 'No hay escaneos con esa gravedad',
      average_thickness: '{{metrics}} mm de grosor promedio, μm',
    },
    error_fallback: {
      something_went_wrong: 'Algo salió mal',
      fix_problem: 'Solucionar el problema',
    },
    etdrs_target: {
      single_layer_thickness: 'Espesor de una capa',
      multiple_layers_thickness: 'Espesor de múltiples capas',
      gcc_thickness: 'Grosor del GCC',
    },
    examination_list: {
      empty: {
        title: 'Tu lista de proyectos está vacía',
        subtitle: 'Puedes agregar un nuevo examen presionando',
        add_new_examination: 'Agregar nuevo examen',
      },
      header: {
        examinations: 'Exámenes',
        type: 'Tipo',
        date: 'Fecha',
      },
      item: {
        eye: 'Ojo',
        examination_failed: 'Examen fallido',
        right_short: 'OD',
        left_short: 'OI',
        image: 'Imagen',
        edit: 'Editar',
        delete: 'Eliminar',
      },
    },
    exam_stat_info: {
      no_severity: 'Sin gravedad (Verde):',
      moderate_severity: 'Severidad moderada (Amarillo):',
      severe: 'Severo (Rojo):',
      scans: 'Escaneos',
      severity: 'Severidad',
    },
    feedback: {
      title: 'Comentarios de clientes',
    },
    first_subscription: {
      subscription: 'Suscripción',
      chose_plan:
        'Elige tu plan perfecto. Sin contratos. Sin tarifas sorpresa.',
    },
    form_components: {
      file_upload: {
        upload_your_date_here: 'Sube tus datos aquí',
        add_more_examinations: 'Agregar más exámenes',
        remove_file: 'Eliminar archivo{{ending}}',
        upload_file: 'subir archivo',
        you_added_files_one: 'Ha agregado {{count}} archivo',
        you_added_files_other: 'Ha agregado {{count}} archivos',
      },
    },
    form_wrap: {
      help: 'Ayuda',
    },
    header: {
      view_my_account: 'Ver mi cuenta',
      sign_out: 'Cerrar sesión',
      subscriptions: 'Suscripciones',
      examinations_left: 'Exámenes restantes',
      unlimited: 'Ilimitado',
      add_new_patient: 'Agregar nuevo paciente',
      add_new_examination: 'Agregar nuevo examen',
      menuItems: {
        patients: 'Pacientes',
        settings: 'Ajustes',
        subscription: 'Suscripción',
        team_members: 'Miembros del equipo',
        contact_us: 'Contáctenos',
        onboarding: 'Capacitación inicial',
      },
    },
    layers_filter: {
      hide: 'Ocultar',
      show_more: 'Mostrar más',
      no_items: 'La IA no ha definido ninguna área para segmentar.',
      all: 'Todas',
      layers: 'Capas:',
      segmentations: 'Segmentaciones:',
      highlight: 'Resaltar',
    },
    layout: {
      private_layout: {
        skip: 'Omitir',
        demo_banner_title:
          'Comience la prueba gratuita de 14 días después de la presentación de video',
        book_a_demo: 'Reserve una llamada',
      },
      simple_layout: {
        sign_out: 'Cerrar sesión',
      },
    },
    modal: {
      analyze_scans: {
        severity: 'Severidad',
        analyze_all_scans: 'Analizar todos los escaneos',
        no_scans: 'No hay escaneos con esos parámetros.',
        try_another_filter: 'Por favor, pruebe con otros filtros.',
        submit: 'Enviar',
      },
      booking_meeting: {
        title:
          '¡Estamos seguros de que podemos encontrar una solución perfecta para usted!',
        book_a_demo: 'Reserve una demo',
      },
      buy_additional_items: {
        buy_additional_examinations: 'Comprar {{number}} exámenes adicionales',
        will_be_cost: '{{description}} costará {{symbol}}{{price}}',
      },
      create_examination: {
        title1:
          'Complete el siguiente formulario para agregar un nuevo examen. Subir',
        title2: 'SOLO escaneos B horizontales de la zona macular',
        title3: ' (Imagen de fondo de ojo, escaneos del disco óptico',
        title4: 'NO son',
        title5: ' compatibles)',
        select_exam_label: 'Seleccionar examen',
        patient_id_label: 'ID de paciente',
        patient_id_placeholder:
          'El paciente se creará a partir del archivo DICOM',
        remaining_examinations: 'Exámenes restantes: {{number}}',
        unlimited: 'Ilimitado',
        images: 'Imágenes',
        folder: 'Carpeta',
        add_examination: 'Agregar examen',
        right: 'Derecho',
        left: 'Izquierdo',
        label: '{{name}} / examen: {{data}} / Ojo: {{eye}} / escaneo: {{scan}}',
      },
      create_patient: {
        no_exam_left1: 'No quedan exámenes. Puedes',
        no_exam_left2: 'actualizar tu plan',
        no_exam_left3: ' para agregar más exámenes',
        create_new_patient_from_dicom: 'Crear un paciente nuevo desde un DICOM',
        create_new_patient_from_dicom_description:
          'La información sobre el paciente se extraerá del archivo DICOM',
        patient_id_label: 'ID de paciente',
        example_short: 'Ej',
        first_name_label: 'Nombre del paciente',
        last_name_label: 'Apellido del paciente',
        birth_date_label: 'Fecha de nacimiento del paciente',
        gender_label: 'Género',
        gender_placeholder: 'Elegir...',
        notes_label: 'Notas',
        add_examination: 'Agregar examen',
        add_patient: 'Agregar paciente',
        add_patient_and_examination: 'Agregar paciente y examen',
        male: 'Masculino',
        female: 'Femenino',
        other: 'Otro',
      },
      current_plan: {
        annual: 'Anual',
        monthly: 'Mensual',
        days_left: 'Días restantes:',
        examinations_left: 'Exámenes restantes:',
        unlimited: 'Ilimitado',
        invitations_left: 'Invitaciones restantes:',
        no_scheduled_payment:
          'No tiene un próximo pago programado porque no se ha suscrito a ningún plan.',
        next_payment_related_to_your_upcoming_plan:
          'Su próximo pago está relacionado con su próximo plan y es de ',
        it_will_automatically_charge: 'Se cargará automáticamente el ',
        your_next_payment_is: 'Su próximo pago es ',
        to_be_charged: ', a debitar el ',
        payment_will_be_charged_automatically:
          'El pago se cargará automáticamente',
        you_successfully_unsubscribed:
          'Ha cancelado la suscripción correctamente de un ',
        plan: 'plan',
        your_subscription_is_active: 'Su suscripción está activa hasta ',
        subscribe: 'Suscribirse',
        unsubscribe: 'Cancelar suscripción',
        continue_subscriptions: 'Continuar suscripciones',
      },
      delete_exam: {
        cancel: 'Cancelar',
        delete: 'Eliminar',
      },
      delete_patient: {
        cancel: 'Cancelar',
        delete: 'Eliminar',
      },
      delete_team_member: {
        cancel: 'Cancelar',
        delete: 'Eliminar',
      },
      download_dcm: {
        dont_have_dicom_file: '¿No tiene el archivo DICOM ahora mismo?',
        use_this: '¡Use esto!',
        download: 'Descargar',
        i_have_dicom: 'Tengo DICOM en mi PC',
        download_dicom: 'Descargar DICOM',
      },
      edit_scan_description: {
        oct_description_title: 'Descripción del análisis OCT de Altris AI',
        save: 'Guardar cambios',
      },
      export_json: {
        dicom_presentation_state: 'Estado de presentación DICOM',
        file: 'Archivo',
      },
      glaucoma_disclaimer: {
        title: 'Descargo de responsabilidad',
        i_acknowledged: 'Reconozco',
      },
      image_cropper: {
        cancel: 'Cancelar',
        crop: 'Recortar',
      },
      invite_team_member: {
        email_label: 'Correo electrónico',
        first_name_label: 'Nombre del doctor',
        last_name_label: 'Apellido del doctor',
        example_short: 'Ej',
        send_the_invitation: 'Enviar la invitación',
      },
      no_objects_left: {
        examinations: {
          title: 'No puede agregar más exámenes',
          subtitle:
            'No se puede agregar un nuevo examen hasta {{endData}} ya que el límite de exámenes para su plan de suscripción se ha excedido para este mes.',
          subtext:
            'A partir de {{endData}} podrá agregar nuevos exámenes nuevamente.',
        },
        plan: 'plan',
        annual: 'Anual',
        monthly: 'Mensual',
        days_left: 'Días restantes:',
        renewal_starts_at: ' (Renovación comienza en: {{endData}})',
        examinations_left: 'Exámenes restantes:',
        invitations_left: 'Invitaciones restantes:',
        go_to_contact_page: 'Ir a la página de contacto',
        please_contact_account_holder1:
          'Por favor, póngase en contacto con su ',
        please_contact_account_holder2: 'Titular de la cuenta',
        please_contact_account_holder3: ' para obtener ayuda.',
        b2c: {
          buy_examinations_or_upgrade_plan1: 'También puede ',
          buy_examinations_or_upgrade_plan2: 'comprar exámenes adicionales',
          buy_examinations_or_upgrade_plan3: ' o ',
          buy_examinations_or_upgrade_plan4: 'actualizar su plan',
          upgrade_plan1: 'Puede ',
          upgrade_plan2: 'actualizar su plan',
          buy_plan: 'Comprar {{name}}',
          upgrade_to: 'Actualizar al plan {{name}} con {{count}} Examen / Mes',
          renew_subscription: 'Renovar suscripción',
          upgrade_to_custom_plan: 'Actualizar a un plan personalizado',
        },
      },
      onboarding_tour: {
        cancel: 'Cancelar (estoy familiarizado con Altris AI)',
        submit: 'Hacer un recorrido rápido',
      },
      renew_subscribe_reject: {
        please_contact_account_holder1:
          'Por favor, póngase en contacto con su ',
        please_contact_account_holder2: 'Titular de la cuenta',
        please_contact_account_holder3: ' para obtener ayuda.',
        not_renew_subscription1:
          'Si no va a renovar la suscripción, envíe una solicitud,',
        not_renew_subscription2: 'descargue sus datos ',
        not_renew_subscription3:
          'para no perderlos. Si tiene alguna pregunta, no dude en contactarnos por correo electrónico:',
        not_renew_subscription4: 'support@altris.ai',
      },
      renew_subscribe_warning: {
        please_contact_account_holder1:
          'Por favor, póngase en contacto con su ',
        please_contact_account_holder2: 'Titular de la cuenta',
        please_contact_account_holder3: ' para obtener ayuda.',
        not_renew_subscription1:
          'Si no va a renovar la suscripción, envíe una solicitud,',
        not_renew_subscription2: 'descargue sus datos ',
        not_renew_subscription3:
          'para no perderlos. Si tiene alguna pregunta, no dude en contactarnos por correo electrónico:',
        not_renew_subscription4: 'support@altris.ai',
      },
      resend_team_member: {
        cancel: 'Cancelar',
        send_the_invitation: 'Enviar la invitación',
      },
      select_layers: {
        layers_grayscale: 'Capas en escala de grises',
        layers_grayscale_description:
          'Su escaneo dentro del informe estará en gris.',
        confirm: 'Confirmar',
      },
      subscribe_plan: {
        subscription_update: 'Actualización de suscripción',
        are_you_sure_subscribe_free_plan:
          '¿Está seguro de que desea suscribirse a un plan gratuito?',
        no: 'No',
        yes: 'Sí',
        subscribe_with: 'Suscribirse',
        with_5_free_days: 'con 5 días gratis',
        plan_charge:
          'Se aplicará un cargo {{interval}} de {{symbol}}{{price}}{{additional_item}}',
        monthly_plan_description:
          '*El descuento es válido solo para los seis {{interval}} de suscripción',
        daily_plan_description:
          '*El descuento es válido solo para el primer {{interval}} de suscripción',
      },
      survey_unsubscribe: {
        reason: 'Razón',
        leave_your_feedback: 'Deje su comentario',
        submit: 'Enviar',
      },
      change_single_scan_tab: {
        dont_show_hint:
          'No mostrar sugerencia cada vez que abra la pestaña Escaneo único (esta opción se puede cambiar en la configuración)',
        later: 'Luego',
        open_pathologies_detection_tab:
          'Abrir la pestaña Detección de patologías',
      },
      trial_period_end: {
        title: 'Período de prueba caducado',
        subtitle:
          'Su período de prueba ha finalizado el <0>{{date}}</0>. Para discutir la compra de la versión completa del producto o para obtener más información, por favor contacte a nuestro <1>equipo de ventas</1>.',
        button: 'Lo he reconocido',
      },
      update_exam: {
        patient_id: 'Paciente',
        width: 'Ancho',
        scan_width: 'Ancho del escaneo',
        right: 'Derecho',
        left: 'Izquierdo',
        scan_width_message:
          'Asegúrese de seleccionar el ancho de escaneo correcto, ya que afecta las mediciones en el examen',
        eye: 'Ojo',
        type: 'Tipo',
        examination_date: 'Fecha de examen',
        notes_label: 'Notas',
        example_short: 'Ej',
        update_examination: 'Actualizar examen',
      },
      update_patient: {
        patient_id_label: 'ID de paciente',
        example_short: 'Ej',
        first_name_label: 'Nombre del paciente',
        last_name_label: 'Apellido del paciente',
        birth_date_label: 'Fecha de nacimiento del paciente',
        gender_label: 'Género',
        gender_placeholder: 'Elegir...',
        notes_label: 'Notas',
        add_examination: 'Agregar examen',
        update_patient: 'Actualizar paciente',
        male: 'Masculino',
        female: 'Femenino',
        other: 'Otro',
      },
    },
    no_pathology: {
      title: '¡Todo está bien!',
      subtitle: 'No se detectó ninguna patología',
    },
    opacity_changer: {
      opacity: 'Opacidad',
    },
    open_exam_button: {
      text: 'Abrir este examen',
    },
    pathologies_progression: {
      title: 'Progresión de patologías',
      add_another_examination:
        'Agregar otro examen para revisar la progresión de patologías',
      no_pathologies:
        'No hay opción para mostrar la progresión de patologías ya que no se detectaron patologías',
      selected_pathology: 'Patología seleccionada',
      volume: 'Volumen',
      units_in_etdrs: 'Unidades en ETDRS',
      b_scan_area: 'Área de B-Scan',
      en_face_area: 'Área en Face',
      total_change: 'Cambio total',
      search_pathologies: 'Buscar patologías',
      number_of_pathological: 'Número de áreas patológicas: {{number}}',
    },
    pay_by_stripe: {
      add_new_card: 'o agregar una nueva tarjeta de crédito / débito',
      pay_via: 'Pagar con tarjeta de crédito / débito',
    },
    payment_info: {
      no_payments: 'Aún no hay pagos',
      you_can_subscribe:
        'Puede suscribirse al plan haciendo clic en el botón a continuación',
      subscribe_plan: 'Suscribirse al plan',
      description: 'Descripción',
      date: 'Fecha',
      time: 'Hora',
      charged: 'Cargado',
      status: 'Estado',
    },
    range_filter: {
      left: 'Izquierdo',
      right: 'Derecho',
      horizontal: 'Horizontal',
    },
    referral_score: {
      title: 'Puntaje de urgencia de derivación',
      good_message: 'No se necesita derivación',
      bad_message: 'Se necesita derivación urgente',
    },
    scan_description: {
      title: 'Descripción del análisis OCT de Altris AI',
      left: 'Izquierdo',
      right: 'Derecho',
      eye: 'Ojo',
      average_rnfl_rpe:
        'Grosor promedio de RNFL-RPE en un anillo foveal central con un diámetro de 1 mm',
      following_pathological_signs:
        'Se han visualizado los siguientes signos patológicos',
      changes_could_be_classified_as: 'Estos cambios se pueden clasificar como',
      edit: 'Editar',
      copy: 'Copiar',
      show_more: 'Mostrar más',
      hide: 'Ocultar',
    },
    scan_overlay: {
      length: 'Longitud',
    },
    segmentation_table: {
      low_confidence: 'Baja confianza',
      low_confidence_description:
        'Altris AI detectó esta clasificación con baja confianza.',
      no_classification: 'No hay clasificación para este escaneo.',
      banner_tooltip:
        'Solo se muestran las enfermedades cubiertas por su plan. Sin embargo, se han identificado varias otras patologías. Si desea resaltarlas, actualice su plan.',
      more_pathologies_found:
        'Se encontraron más patologías que faltan en su plan. Para revisarlos, actualice su plan.',
      upgrade_plan: 'Actualizar mi plan',
    },
    severity_level: {
      no_severity: 'Sin severidad en el examen',
      no_severity_plural: 'Sin severidad en los exámenes',
      yellow_severity_scan:
        'El examen incluye un escaneo de severidad amarilla',
      yellow_severity_scan_plural:
        'Escaneo de severidad amarilla en los exámenes',
      red_severity_scan: 'El examen incluye un escaneo de severidad roja',
      red_severity_scan_plural: 'Escaneo de severidad roja en los exámenes',
    },
    severity_select: {
      sort: 'Ordenar',
      sort_by: 'Ordenar por',
      high_low: 'Gravedad: Alto - Bajo',
      low_high: 'Gravedad: Bajo - Alto',
      in_order: 'En orden',
    },
    single_scan: {
      add_to_report: 'Agregar al informe',
      review_other_scans: 'Revisar otros escaneos',
      scan_quality_tooltip1:
        'Tenga en cuenta que solo los escaneos con calidad 4 o superior pueden considerarse escaneos de calidad.',
      scan_quality_tooltip2:
        'Altris AI aún puede clasificarlos y segmentarlos, pero los resultados pueden ser inexactos.',
      central_scan: 'Escaneo central',
      severity: 'Severidad',
      severity_tooltip:
        'Los escaneos se marcan según la gravedad de los signos patológicos. Verde: retina normal, Amarillo: severidad moderada y Rojo: severo.',
      yellow: 'amarillo',
      red: 'rojo',
      green: 'verde',
      draw_a_line: 'Dibujar una línea',
      draw_a_line_info: 'Presione el botón izquierdo del mouse y arrastre',
      segmentations: 'Segmentaciones',
      highlight: 'Resaltar',
    },
    smart_report: {
      average_thickness: 'Espesor promedio de 1/3/6 mm, μm',
      gcc_thickness: 'Grosor GCC',
      both_eyes_view: 'Vista de ambos ojos',
      thickness_comparison: 'Comparación de grosor',
      retina_thickness_difference: 'Diferencia de grosor de la retina',
      etdrs_calculations: 'Cálculos ETDRS',
      from: 'De',
      to: 'a',
      etdrs_titles: {
        average_thickness: 'Espesor promedio',
        single_layer_thickness: 'Espesor de una capa',
        multiple_layer_thickness: 'Espesor de varias capas',
        gcc_thickness: 'Grosor GCC',
      },
      glaucoma: 'Glaucoma',
      auto: 'Auto',
      auto_measurements: 'Mediciones automáticas',
      manual: 'Manual',
      manual_measurements: 'Mediciones manuales',
      segmentation_area_title:
        'Área de segmentación | Escaneo: {{number}}/{{total}}',
      segmentation_volume_title:
        'Volumen de segmentación | Escaneo: {{number}}/{{total}}',
      retina_layers: 'Capas de la retina',
      segmentation: 'Segmentación',
      classification: 'Clasificación',
      single_scan: 'Escaneo único',
      right: 'Derecho',
      left: 'Izquierdo',
      report_results: 'Resultados del informe',
      clinic: 'Nombre de la Clínica',
      clinic_email: 'Correo electrónico de la Clínica',
      doctor: 'Doctor',
      patient_name: 'Nombre del paciente',
      birth_day: 'Fecha de nacimiento',
      gender: 'Género',
      eye: 'Ojo',
      exam_date: 'Fecha del examen',
      total_scans: 'Total de escaneos',
      pathology_progression: 'Progresión de patología',
      selected_pathology: 'Patología seleccionada:',
      en_face_area: 'Área en face',
      b_scan_area: 'Área de escaneo B',
      volume: 'Volumen',
      total_change: 'Cambio total',
    },
    spinner_svg: {
      retry: 'Reintentar',
    },
    subscription_conditions: {
      back: 'Back',
      detection: 'Pathological signs detection with AI (Segmentation)',
      detection_and_visualization:
        'Pathological signs detection and visualization with AI (Segmentation)',
      view_all_pathological_signs: 'View all pathological signs',
    },
    subscription_plan_card: {
      need_better_solution: 'Need better solution for your clinic?',
      personal_assistant: 'Personal Assistant',
      unlimited_examinations: 'Unlimited Examinations',
      unlimited_patients: 'Unlimited Patients',
      separate_accounts_for_doctors: 'Separate accounts for doctors',
      onboarding_trainings_for_the_whole_team:
        'Onboarding trainings for the whole team',
      platform_customization:
        'Platform customization up to your needs and more.',
      contact: 'Contact',
      month: 'Month',
      you_saving: "You're saving",
      starting_from: 'Starting from',
      details: 'Details',
      current_plan: 'Current plan',
      active_till: 'Active till',
      subscribe: 'Subscribe',
      days_free_trial: '{{number}} days free trial',
      best_choice: 'Best choice',
      monthly_description:
        '*The discount is valid only for the six {{pricingType}} of subscription',
      daily_description:
        '*The discount is valid only for the first {{pricingType}} of subscription',
      unlimited: 'Unlimited',
      examinations_for_ai_screening:
        'Examinations for AI SCREENING, AI LAYERS SEGMENTATION',
      pathological_signs_detection:
        'Pathological signs detection and visualization with AI',
      try_for_free: 'Try for free',
      month_small: 'month',
      year_small: 'year',
    },
    subscription_plans: {
      subscription: 'Subscription',
      price: 'Price',
      examinations_available: 'Examinations available',
      detect: 'Detect',
      retina_conditions: 'Retina Conditions',
      tooltip_text:
        'This functionality is currently in development and will appear in the platform when it`s ready',
      services: 'Services',
      service: 'Service',
    },
    team_member_list: {
      doctors: 'Doctores',
      email: 'Correo electrónico',
      status: 'Estado',
      access_level: 'Nivel de acceso',
      examination_created: 'Examen creado',
      actions: 'Acciones',
      no_doctors: 'Aún no se ha invitado a ningún médico',
      you_can_add_team_member:
        'Puede agregar un nuevo miembro del equipo presionando',
      invite_doctor: 'Invitar doctor',
      me: 'YO',
      no_actions: 'Ninguna acción',
      resend_invitation: 'Reenviar invitación',
      delete: 'Borrar',
      active: 'ACTIVO',
      pending: 'PENDIENTE',
      invite_expired: 'INVITACIÓN CADUCADA',
    },
    titled_switcher: {
      on: 'ENCENDIDO',
      off: 'APAGADO',
    },
    upload_image: {
      upload_photo: 'Subir foto',
      info: 'Formato: {{formats}}. El tamaño máximo del archivo es {{size}}.',
    },
    welcome: {
      title:
        'Facilite la interpretación del escaneo OCT con la plataforma Altris AI',
      subtitle1:
        'La plataforma Altris AI solo funciona en Windows o Mac (NO es una aplicación móvil).',
      subtitle2:
        'Aunque el registro puede realizarse a través de su teléfono móvil',
      list_item_title1:
        'Diferenciación entre escaneos b-patológicos y no patológicos en 1 minuto',
      list_item_title2:
        'Detección de más de 70 patologías de retina, incluidas patologías raras',
      list_item_title3: 'Análisis del grosor de las capas de la retina',
      how_it_works: '¿Cómo funciona?',
      description1:
        'Altris AI se adhiere completamente al RGPD, lo que garantiza el cumplimiento. Todos los datos del usuario están encriptados y son inaccesibles para terceros. Contamos con un certificado CE y la autorización FDA 510, lo que confirma nuestro compromiso con los estándares regulatorios.',
      description2:
        'La plataforma fue creada por un equipo de expertos en retina bajo la supervisión del Director Médico, Doctor en Filosofía en Oftalmología.',
      description3:
        'Altris AI aparece en Ophthalmology Times, Eyewire, Eyes on Eyecare, The Ophthalmologist y está respaldado por la comunidad del cuidado ocular.',
      features_item_title1: 'Formato.',
      features_item_title2: 'Comunicación.',
      features_item_title3: 'Integración.',
      features_item_text1:
        'El sistema funciona con todos los formatos de datos, como DICOM, jpg y png, lo que hace que el sistema de gestión de imágenes oftálmicas sea más seguro y eficiente.',
      features_item_text2:
        'Los especialistas en cuidado ocular pueden compartir imágenes dentro del sistema, discutirlas dejando comentarios y trabajar en las mismas imágenes juntos.',
      features_item_text3:
        'El sistema también se puede integrar con el sistema EHR o también puede funcionar de forma autónoma como una aplicación web.',
      disclaimer:
        'Descargo de responsabilidad: Altris AI está disponible como software solo para investigación en los EE. UU. El proceso de autorización de la FDA está en curso.',
      i_acknowledged: 'Lo reconozco',
      learn_more: 'Aprende más',
      back: 'Volver',
      next: 'Siguiente',
      slide_one: {
        title: '¡Bienvenido a Altris AI',
        subtitle:
          'Analice el examen OCT con Altris AI. Ahorre tiempo y no se pierda nunca una patología rara, menor o temprana',
        desktop_available:
          'La plataforma Altris AI solo funciona en Windows o Mac. No es una aplicación móvil.',
        registration_with_phone:
          'Aunque el registro puede realizarse a través de su teléfono móvil',
        description:
          'Totalmente compatible con GDPR, los datos están encriptados y no pueden ser accedidos por ningún tercero. Tenemos un certificado CE y la autorización de la FDA está en proceso.',
      },
      slide_two: {
        title: 'Gravedad de escaneos por gravedad',
        subtitle:
          'Analice la gravedad de hasta 512 escaneos patológicos en un minuto',
        low_severity: 'Baja severidad',
        medium_severity: 'Severidad media',
        high_severity: 'Alta severidad',
        green: 'verde',
        yellow: 'amarillo',
        red: 'rojo',
        description:
          'No necesita perder tiempo revisando todos los escaneos para encontrar el área de interés',
      },
      slide_three: {
        title: 'Detección y visualización de patologías',
        subtitle:
          'Altria AI detecta más de 70 afecciones de retina para cada escaneo en el examen',
        segmentation: 'Segmentación',
        classification: 'Clasificación',
      },
      slide_four: {
        title: 'Y mucho más para aprovechar al máximo la plataforma',
        item_header1: 'Segmentación de capas de retina',
        item_header2: 'Mapa de grosor de ETDRS y retina',
        item_header3: 'Puntaje de urgencia de derivación',
        item_header4: 'Mediciones automáticas de patologías',
        item_header5: 'Área de segmentación',
        item_header6: 'Volumen de segmentación',
        item_header7: 'Informes inteligentes',
        item_text2: 'Espesor promedio de 1/3/6 mm y mapa de grosor de retina',
        item_text3:
          'Altris AI sugiere un puntaje de urgencia de derivación según los signos patológicos descubiertos. Va desde No se necesita derivación hasta Derivación urgente requerida.',
        item_text4:
          'Altris AI sugiere un puntaje de urgencia de derivación según los signos patológicos descubiertos. Va desde No se necesita derivación hasta Derivación urgente requerida.',
        item_text5:
          'Altris AI calcula automáticamente el área de todas las segmentaciones',
        item_text6:
          'Altris AI calcula automáticamente el volumen de todas las segmentaciones',
      },
    },
  },
  constants: {
    severity_filter_options: {
      all: 'todos',
      green: 'verde',
      yellow: 'amarillo',
      red: 'rojo',
    },
  },
  modals: {
    glaucoma_disclaimer:
      'Tenga en cuenta que el análisis de riesgo de glaucoma solo funciona en exámenes de OCT donde la patología no cambia el grosor del complejo de células ganglionares',
    sign_out: {
      title: 'Estás cerrando sesión',
      subtitle: '¿Estás seguro?',
      yes: 'Sí',
      cancel: 'Cancelar',
    },
    add_patient: {
      title: 'Agregar nuevo paciente',
      subtitle:
        'Complete el formulario a continuación para agregar la tarjeta del nuevo paciente a la lista',
    },
    add_examination: 'Agregar nuevo examen',
    add_eye_examination: {
      title: 'Agregar examen ocular',
      subtitle:
        'Complete el formulario a continuación para agregar un nuevo examen a la lista',
    },
    invite_team_member: {
      title: 'Invitar a un doctor',
      subtitle:
        'Complete el formulario a continuación para agregar un nuevo doctor a la lista',
    },
    delete_team_member: {
      title: 'Borrar',
      subtitle: '¿Realmente desea eliminar esta cuenta?',
    },
    reinvite_team_member: {
      title: 'Reenviar invitación',
      subtitle: '¿Realmente desea reenviar la invitación a esta cuenta?',
    },
    delete_patient: {
      title: 'Borrar',
      subtitle: '¿Realmente desea eliminar a este paciente?',
    },
    update_patient: {
      title: 'Editar paciente',
      subtitle:
        'Complete el formulario a continuación para editar la tarjeta del paciente en la lista',
    },
    unsubscribe: {
      title: 'Estás cancelando la suscripción',
      subtitle: '¿Estás seguro?',
      yes: 'Sí',
      cancel: 'Cancelar',
    },
    subscribe_fake: {
      title: 'Actualización de suscripción',
      subtitle: 'Se suscribirá al plan gratuito',
      yes: 'Sí',
      cancel: 'Cancelar',
    },
    renew_subscribe_warning: {
      title1: 'Renovar suscripción',
      subtitle1:
        'Desafortunadamente, no recibimos el pago. La suscripción que está utilizando actualmente ha expirado. Actualice a un nuevo plan de suscripción a Altris AI o su acceso a Altris AI se eliminará',
      button_text1: 'Renovar suscripción',
      title2: 'Renovar suscripción',
      subtitle2:
        'Desafortunadamente, no recibimos el pago y su plan ha expirado. Renueve su suscripción o su acceso a Altris AI se eliminará',
      button_text2: 'Renovar',
    },
    renew_subscribe_warning_b2b: {
      title: 'Renovar suscripción',
      subtitle:
        'Desafortunadamente, no recibimos el pago y su plan ha expirado. Renueve su suscripción o su acceso a Altris AI se eliminará',
      button_text: 'Ir a la página de contacto',
    },
    renew_subscribe_reject: {
      title1: 'Renovar suscripción',
      subtitle1:
        'Desafortunadamente, no recibimos el pago. La suscripción que está utilizando actualmente ha expirado. Actualice a un nuevo plan de suscripción. Almacenaremos sus datos hasta {{date}}. Después de eso, tendremos que eliminarlos.',
      button_text1: 'Renovar suscripción',
      title2: 'Renovar suscripción',
      subtitle2:
        'Desafortunadamente, no recibimos el pago y su plan ha expirado. Renueve su suscripción. Almacenaremos sus datos hasta {{date}}. Después de eso, tendremos que eliminarlos.',
      button_text2: 'Renovar',
    },
    renew_subscribe_reject_b2b: {
      title: 'Renovar suscripción',
      subtitle:
        'Desafortunadamente, no recibimos el pago y su plan ha expirado. Renueve su suscripción. Almacenaremos sus datos hasta {{date}}. Después de eso, tendremos que eliminarlos.',
      button_text: 'Ir a la página Contáctenos',
    },
    analyze_scans: {
      title: 'Analizar otros escaneos',
      subtitle: 'Marque los escaneos que desea analizar.',
    },
    select_retina_layers:
      'Seleccione las capas de retina para agregar al informe',
    delete_examination: {
      title: 'Borrar',
      subtitle: '¿Realmente desea eliminar este examen?',
    },
    update_examination: {
      title: 'Editar',
      subtitle:
        'Complete el formulario a continuación para editar la tarjeta del examen en la lista',
    },
    onboarding_tour: {
      title: '¡Bienvenido a Altris AI',
      subtitle: '¿Qué puede esperar en Altris AI?',
    },
    json_exam_export: {
      title: 'Exporte los datos de su examen.',
      subtitle: 'Elija qué datos desea exportar.',
    },
    unsubscribe_survey: {
      title: '¿Qué salió mal?',
      subtitle:
        'Nos dimos cuenta de que se dio de baja de Altris Pro. Por favor, ¿avísenos por qué?',
    },
    booking_meeting: {
      title: '¡Podemos resolverlo!',
      subtitle:
        'Reserve una reunión corta gratuita con nuestro experto, que tiene 15 años en OCT y puede responder todas sus preguntas sobre Altris AI',
    },
    download_dicom: {
      title: 'Crea tu primer paciente y examen!',
      subtitle:
        'Para crear un examen, use un archivo DICOM o una imagen exportada de su OCT',
    },
    download_dicom_success: {
      title: '¡Descarga DICOM iniciada!',
      subtitle:
        'Espere hasta que... Una vez descargado, haga clic en "Siguiente" para crear un nuevo paciente. Luego, cree un nuevo examen y cargue el archivo DICOM para que Altris AI lo analice.',
    },
    change_single_scan_tab:
      '¡No olvide revisar la detección de patologías (¡Impulsado por IA!)',
  },
  validation: {
    required: 'Obligatorio',
    data_invalid: 'Los datos proporcionados no son válidos',
    email_invalid: 'El correo electrónico proporcionado no es válido',
    letters_numbers_spaces_hyphens:
      'Solo se permiten letras (en, ua, ru), números, espacios, puntos y guiones ("-") en este campo',
    min_chars: 'Debe contener un mínimo de {{number}} caracteres',
    max_chars: 'Debe contener un máximo de {{number}} caracteres',
    password_latin_lowercase:
      'La contraseña debe contener una letra minúscula latina',
    password_latin_uppercase:
      'La contraseña debe contener una letra mayúscula latina',
    password_number: 'La contraseña debe contener un número',
    password_match: 'Las contraseñas deben coincidir',
  },
  notifications: {
    you_can_add_only_2_dicom: 'Solo puede agregar 2 archivos DICOM',
    your_data_was_added_successfully: 'Sus datos se agregaron correctamente',
    you_cant_add_file_of_this_format:
      'No puede agregar un archivo de este formato',
    patient_was_added_successfully: 'Paciente agregado con éxito',
    characters_limit_exceeded: 'Se superó el límite de {{number}} caracteres',
    download_will_started_automatically:
      'La descarga se iniciará automáticamente cuando todos los datos estén listos.',
    please_enter_your_card: 'Por favor ingrese su tarjeta',
    image_size_changed_successfully:
      'Tamaño de la imagen cambiado exitosamente',
    description_copied: 'Descripción copiada',
    description_not_copied: 'Descripción no copiada',
    you_already_add_this_scan_form_segmentation_report:
      'Ya agregó este escaneo del informe de segmentación',
    scan_was_successfully_added_to_the_report:
      'El escaneo se agregó correctamente al informe',
    image_was_not_uploaded:
      'La imagen no se cargó porque su tamaño es demasiado grande. Cargue una imagen de menos de 3 mb',
    resent_confirmation_email: 'Correo electrónico de confirmación reenviado',
    something_went_wrong: 'Algo salió mal',
    examination_etdrs_calculation_finished:
      'Examen: {{id}}. Cálculo ETDRS finalizado',
    examination_etdrs_calculation_failed:
      'Examen: {{id}}. Error en el cálculo de ETDRS',
    segmentation_layers_calculation_finished:
      'Examen: {{id}}. Cálculo de capas de segmentación finalizado',
    segmentation_layers_calculation_failed:
      'Examen: {{id}}. Error en el cálculo de capas de segmentación',
    measurements_calculation_finished:
      'Examen: {{id}}. Cálculo de medidas finalizado',
    measurements_calculation_failed:
      'Examen: {{id}}. Error en el cálculo de las medidas',
    measurements_manual_updated: 'Medidas actualizadas manualmente',
    link_is_copied: 'Enlace copiado',
    link_not_copied: 'Enlace no copiado',
    your_request_sent_successfully: 'Su solicitud se envió correctamente',
    you_dont_have_available_pathology_classes:
      'No tiene clases de patología disponibles',
    examination_created_successfully: 'Examen creado exitosamente',
    examination_deleted_successfully: 'Examen eliminado exitosamente',
    examination_failed: 'Falló su examen',
    examination_available: 'Su examen está disponible',
    examination_updated_successfully: 'Examen actualizado exitosamente',
    scan_added_to_favorites: 'Escaneo agregado a favoritos',
    scan_removed_from_favorites: 'Escaneo eliminado de favoritos',
    measurements_reset_successfully:
      'Las medidas para este escaneo se han restablecido',
    measurements_updated: 'Medidas actualizadas',
    you_tried_upload_same_eye: 'Ha intentado cargar el mismo ojo',
    you_tried_upload_opposite_eye: 'Ha intentado cargar el ojo opuesto',
    limit_examination_is_reached: 'Se alcanzó el límite de exámenes',
    examinations_have_not_enough_data: 'Su examen no tiene suficientes datos',
    patient_deleted_successfully: 'Paciente eliminado exitosamente',
    patient_updated_successfully:
      'La información del paciente se actualizó correctamente',
    you_successfully_buy_additional_examination:
      'Ha comprado un examen adicional exitosamente',
    settings_saved: 'Su configuración ha sido guardada',
    subscription_plan_updated: 'Se actualizó su plan de suscripción',
    subscription_canceled: 'Su suscripción ha sido cancelada',
    doctor_invited: 'El doctor ha sido invitado',
    password_changed: 'Se ha cambiado su contraseña',
    backup_request_created:
      'Se ha creado la solicitud, recibirá un correo electrónico cuando sus datos estén listos',
    data_deleted: 'Sus datos han sido eliminados',
    attempts_over: 'Se acabaron los intentos, vuelva a intentarlo más tarde',
    company_profile_updated: 'Se actualizó el perfil de su empresa',
    profile_updated: 'Su perfil se actualizó correctamente',
    session_expired: 'Su sesión ha expirado, inicie sesión',
    site_under_maintenance:
      'Tenga en cuenta que actualmente el sitio se encuentra en mantenimiento regular, y es posible que todos los servicios funcionen de manera inexacta temporalmente',
    progression_settings_updated:
      'La configuración de la progresión se ha actualizado correctamente.',
    file_folder_is_too_large: 'Ese archivo/carpeta es demasiado grande',
  },
  user_popup_menu_links: {
    subscription: 'Suscripción',
    contact_support: 'Contactar con el soporte',
  },
  profile_links: {
    subscriptions: 'Suscripciones',
    team_members: 'Miembros del equipo',
    contact_us: 'Contáctenos',
    documentation: 'Documentación',
    join_altris_education: 'Únete a Altris Education',
    settings: 'Ajustes',
    onboarding: 'Introducción',
    storybook: 'Storybook',
  },
  onboarding_links: {
    complete_onboarding_again: 'Completar la introducción nuevamente',
    download_test_dicom: 'Descargar DICOM de prueba',
    create_patient: 'Crear paciente',
    create_examintaion: 'Crear examen',
    header: 'Encabezado',
    single_scan: 'Escaneo único',
    pathologies_detection: 'Detección de patologías',
    measurements: 'Medidas',
    all_scans: 'Todos los escaneos',
    both_eyes: 'Ambos ojos',
    comparison: 'Comparación',
    progression: 'Progresión',
    report: 'Informe',
  },
  onboarding: {
    create_examination: {
      add_new_examination: 'Agregar nuevo examen',
      next: 'Siguiente',
      examination_list: 'Lista de exámenes',
      step_1_text_1: 'Haga clic en',
      step_1_text_2: 'para agregar un nuevo examen OCT a su paciente',
      step_1_text_3: 'Haga clic para continuar',
      step_2_text_1: 'El primer paso sería',
      step_2_text_2: 'Seleccionar el paciente',
      step_3_text: 'O seleccione para Crear examen Y Paciente usando DICOM',
      step_4_text_1: '¿Qué es DICOM?',
      step_4_text_2: 'Estoy familiarizado con DICOM',
      step_4_text_3: 'Puede crear un examen cargando',
      step_4_text_4: 'DICOM (Preferido), carpeta DICOM',
      step_4_text_5: 'o Imágenes',
      step_4_text_6:
        '¡Importante! Asegúrese de saber qué es DICOM y cómo exportarlo desde su OCT',
      step_5_text_1:
        'Arrastre DICOM a la ventana para seleccionarlo para cargar',
      step_5_text_2: 'Cargar DICOM para continuar',
      step_6_text_1:
        'Después de que se agregó el archivo DICOM/Imágenes, puede hacer clic en',
      step_6_text_2: 'Haga clic para continuar',
      step_7_text:
        'Y ahora el examen se agregó y estará accesible después de que el sistema lo analice. Puede revisar la lista de todos los exámenes del paciente después de hacer clic en él.',
      step_8_text_1: 'Revisemos cómo se ve el examen',
      step_8_text_2: 'Haga clic para continuar',
      step_dicom_text_1: 'Reconocí',
      step_dicom_text_2: '¿Qué es un archivo DICOM/DCOM/.dcm?',
      step_dicom_text_3: 'DICOM/DCOM/.dcm',
      step_dicom_text_4: 'es un formato de archivo que puede seleccionar',
      step_dicom_text_5: 'para exportar el examen',
      step_dicom_text_6: 'de su OCT',
      step_dicom_text_7: 'Incluye:',
      step_dicom_text_8: 'Todos los escaneos del examen OCT',
      step_dicom_text_9:
        'Puede seleccionar cuántos escaneos desea exportar a DICOM en su OCT',
      step_dicom_text_10: 'Datos necesarios del examen',
      step_dicom_text_11:
        'Le recomendamos usar DICOM en lugar de imágenes. Como las imágenes no incluyen todos los datos necesarios del examen, lo que hace que varios componentes principales del sistema no puedan representar datos convenientes',
      step_dicom_text_12: '¿Qué no estará disponible si uso imágenes?',
      step_dicom_text_13: 'Datos necesarios sobre el paciente',
      step_dicom_text_14:
        'Si usa DICOM en lugar de imagen, podemos extraer todos los datos necesarios sobre el paciente y crearlos automáticamente',
      step_dicom_text_15: '¿Cómo puedo crear un paciente usando DICOM?',
      step_dicom_text_16: 'Carpeta DICOM',
      step_dicom_text_17:
        'Si su OCT solo puede exportar datos en una carpeta (por ejemplo, Heidelberg Engineering OCT), puede archivar esta carpeta usando su archivador estándar y cargar un archivo .zip mientras crea un examen. NO cambie la estructura de la carpeta ni archive ninguna carpeta separada dentro de la principal. La carpeta debe archivarse por completo.',
      step_dicom_text_18:
        '¿Cómo exportar la carpeta DICOM desde Heidelberg Engineering OCT?',
      step_dicom_text_19: '¿Cómo puedo exportar un archivo DICOM desde mi OCT?',
      step_dicom_text_20: '¿Cómo exportar DICOM de Nidek OCT?',
      step_dicom_text_21: '¿Cómo exportar DICOM de Topcon OCT?',
      step_dicom_text_22: 'Cómo exportar DICOM desde Optopol OCT?',
      step_scans_text_1: '¡Solo escaneos de calidad!',
      step_scans_text_2:
        'Por favor, asegúrese de que los escaneos que está cargando sean de calidad suficiente. De lo contrario, el resultado del análisis no será satisfactorio.',
    },
    create_patient: {
      add_new_patient: 'Agregar nuevo paciente',
      next: 'Siguiente',
      step_1_text_1: '¡Comencemos agregando un paciente nuevo!',
      step_1_text_2: 'para crear un paciente nuevo',
      step_1_text_3: 'Haga clic aquí para continuar',
      step_2_text_1:
        'Puede crear inmediatamente un paciente y un examen cargando un archivo DICOM',
      step_2_text_2: '¡Simplemente seleccione la casilla de verificación!',
      step_3_text_1:
        'Si seleccionó no cargar el paciente desde DICOM, debe completar toda la información sobre el paciente.',
      step_3_text_2:
        'Una vez que se hayan completado todos los datos del paciente, haga clic en',
      step_4_text_1:
        'Después de cargar el DICOM o completar toda la información sobre el paciente, puede hacer clic en',
      step_4_text_2: 'para guardarlo.',
      step_4_text_3: 'Haga clic para continuar',
      step_5_text_1: 'Lista de pacientes',
      step_5_text_2:
        'Y ahora se agregó su paciente, por lo que puede crear un examen',
      step_5_text_3:
        'Nota: Si cargó al paciente usando DICOM, este paso se omitirá automáticamente',
    },
    create_report: {
      create_report: 'Crear informe',
      next: 'Siguiente',
      step_1_text_1:
        'Una vez que se descubre el examen, el médico puede crear un',
      step_1_text_2: 'Informe',
      step_1_text_3: 'Haga clic para continuar',
      step_2_text_1: 'Paso 1',
      step_2_text_2:
        'Seleccione la exploración que desea mostrar como la principal (Revisada por última vez de forma predeterminada)',
      step_2_text_3: 'Paso 2',
      step_2_text_4:
        'Seleccione qué componentes desea que se incluyan en el informe',
      step_2_text_5: 'Paso 3',
      step_2_text_6:
        'Seleccione una o varias exploraciones para mostrar la detección de patologías',
      step_3_text_1: 'Imprimir o guardar PDF',
      step_3_text_2: 'Paso 4',
      step_3_text_3: 'Haga clic en',
      step_3_text_4: 'Revisar',
      step_3_text_5:
        'para ver la vista previa del informe generado e Imprimirlo / Guardarlo en PDF',
      step_3_text_6: 'Haga clic para continuar',
      step_4_text_1: '¡Felicidades!',
      step_4_text_2: '¡Ha completado la incorporación!',
      step_4_text_3: '¡No dude en crear su primer paciente!',
      step_4_text_4: 'Siempre puede revisar estas instrucciones nuevamente en',
      step_4_text_5: 'Ajustes',
    },
    general: {
      skip_onboarding: 'Omitir incorporación',
      are_you_sure_want_to_skip:
        '¿Está seguro de que desea omitir la incorporación?',
      continue_onboarding: 'Continuar incorporación',
    },
    show_examination: {
      tab_menu: 'Menú de pestañas',
      next: 'Siguiente',
      all_scans_text_1: 'Continuemos con',
      all_scans_text_2: 'Todos los escaneos',
      all_scans_text_3: 'Haga clic para continuar',
      all_scans_text_4: 'Todos los escaneos',
      all_scans_text_5:
        'Puede revisar todos los escaneos y ordenarlos por Nivel de gravedad.',
      all_scans_text_6:
        'También puede seleccionar ver solo los escaneos que se han categorizado como',
      all_scans_text_7: 'verde',
      all_scans_text_8: 'amarillo',
      all_scans_text_9: 'o',
      all_scans_text_10: 'rojo',
      all_scans_text_11: 'Nivel de severidad.',
      both_eyes_text_1: 'Continuemos con',
      both_eyes_text_2: 'Ambos ojos',
      both_eyes_text_3: 'Haga clic para continuar',
      both_eyes_text_4: 'Ambos ojos',
      both_eyes_text_5:
        'Puede agregar un segundo ojo para revisar la imagen completa',
      comparison_text_1: 'Continuemos con',
      comparison_text_2: 'Comparación',
      comparison_text_3: 'Haga clic para continuar',
      comparison_text_4: 'Comparación',
      comparison_text_5:
        'Agregue el mismo ojo, diagnosticado anteriormente, para compararlo con el examen actual',
      measurements_text_1: 'Continuemos con',
      measurements_text_2: 'Mediciones',
      measurements_text_3: 'Haga clic para continuar',
      measurements_text_4: 'Mediciones',
      measurements_text_5: 'La sección de Mediciones permite a los médicos:',
      measurements_text_6: 'Revisar',
      measurements_text_7: 'Altura',
      measurements_text_8: 'y',
      measurements_text_9: 'Largo',
      measurements_text_10: 'de las segmentaciones, medidas automáticamente',
      measurements_text_11: 'Dibujar',
      measurements_text_12: 'Líneas para calcular el área de interés',
      measurements_text_13: 'Revisar las áreas de segmentación',
      measurements_text_14: 'Revisar los volúmenes de segmentación',
      measurements_text_15:
        'Puede revisar la altura y el peso de la segmentación detectada',
      measurements_text_16: 'Mediciones automáticas',
      measurements_text_17:
        'Iniciar mediciones y revisar la altura y el largo de la segmentación',
      measurements_text_18: 'Resaltarlas o quitar el resalte en los escaneos',
      measurements_text_19: 'Editar línea para recalcular',
      measurements_text_20: 'Eliminar',
      measurements_text_21: 'Continuemos con',
      measurements_text_22: 'Mediciones automáticas',
      measurements_text_23: 'Continuemos con',
      measurements_text_24: 'Medición manual',
      measurements_text_25: 'Haga clic para continuar',
      measurements_text_26: 'Continuemos con',
      measurements_text_27: 'Área de segmentación',
      measurements_text_28: 'Haga clic para continuar',
      measurements_text_29: 'Mediciones manuales',
      measurements_text_30:
        'También puede dibujar una línea para calcular la distancia entre dos puntos cualquiera del escaneo.',
      measurements_text_31: 'Área de segmentación',
      measurements_text_32: 'Revisar áreas segmentadas:',
      measurements_text_33: 'B-Scan',
      measurements_text_34: 'En-face',
      measurements_text_35: 'Continuemos con',
      measurements_text_36: 'Volumen de segmentación',
      measurements_text_37: 'Haga clic para continuar',
      measurements_text_38: 'Mediciones',
      measurements_text_39: 'Revisar el volumen de la segmentación:',
      pathologies_detection_text_1: 'Continuemos con',
      pathologies_detection_text_2: 'Detección de patologías',
      pathologies_detection_text_3: 'Haga clic para continuar',
      pathologies_detection_text_4: 'Segmentación y clasificación',
      pathologies_detection_text_5:
        'Revise el último escaneo seleccionado y resalte o quite el resalte de la',
      pathologies_detection_text_6: 'segmentación',
      pathologies_detection_text_7: 'Segmentación y clasificación',
      pathologies_detection_text_8:
        'Dependiendo del nivel de gravedad, Altris AI le sugiere un',
      pathologies_detection_text_9: 'Puntaje de urgencia de derivación',
      pathologies_detection_text_10: 'Esta opción se puede desactivar',
      pathologies_detection_text_11: 'Segmentación',
      pathologies_detection_text_12:
        'Altris AI analiza el escaneo y sugiere todas las',
      pathologies_detection_text_13: 'Segmentaciones',
      pathologies_detection_text_14: 'detectadas en el escaneo seleccionado.',
      pathologies_detection_text_15:
        'Puede revisarlas en el escaneo y Resaltar / Quitar resalte',
      pathologies_detection_text_16: 'Revisar otros escaneos',
      pathologies_detection_text_17: 'Usted puede',
      pathologies_detection_text_18: 'Seleccionar otros escaneos',
      pathologies_detection_text_19: 'para que se muestren y',
      pathologies_detection_text_20: 'Agregar al informe',
      pathologies_detection_text_21: 'actual',
      pathologies_detection_text_22: 'Clasificación',
      pathologies_detection_text_23:
        'Altris AI analiza el escaneo y sugiere todas las',
      pathologies_detection_text_24: 'Clasificaciones',
      pathologies_detection_text_25: 'detectadas en el escaneo seleccionado',
      progression_text_1: 'Continuemos con',
      progression_text_2: 'Progresión',
      progression_text_3: 'Haga clic para continuar',
      progression_text_4: 'Progresión',
      progression_text_5:
        'Agregue hasta 8 exámenes para revisar cómo progresa la segmentación',
      single_scan_text_1: 'Información del paciente / examen',
      single_scan_text_2:
        'En la parte superior de la pantalla puede revisar la información general sobre el paciente y el examen',
      single_scan_text_3:
        'Debajo puede ver el menú de pestañas con todos los componentes del sistema',
      single_scan_text_4: 'Escaneo único',
      single_scan_text_5: 'En el lado derecho puede ver:',
      single_scan_text_6: 'Imagen del escaneo',
      single_scan_text_7: 'Calidad del escaneo',
      single_scan_text_8: 'Número de escaneo',
      single_scan_text_9: 'Nivel de severidad del escaneo',
      single_scan_text_10: 'Botón de escala',
      single_scan_text_11: 'Botón para seleccionar el escaneo central',
      single_scan_text_12: 'Botón para marcar el escaneo como "Seleccionado"',
      single_scan_text_13: 'Escaneo único',
      single_scan_text_14: 'En el lado izquierdo puede ver:',
      single_scan_text_15: 'Tipo de escaneo',
      single_scan_text_16: 'Altris solo admite B-scan horizontales',
      single_scan_text_17: 'Ojo',
      single_scan_text_18: 'Niveles de severidad y navegación',
      single_scan_text_19:
        'Haga clic en el área seleccionada para revisar el escaneo o arrastre la línea',
      single_scan_text_20: 'Filtrar por severidad',
      single_scan_text_21: 'Número de escaneos de cada nivel de gravedad',
      single_scan_text_22: 'Botón para seleccionar el escaneo central',
      single_scan_text_23: 'Escaneo único',
      single_scan_text_24: 'Puede ver la',
      single_scan_text_25: 'segmentación',
      single_scan_text_26: 'de las capas retinianas',
      single_scan_text_27: 'para el escaneo seleccionado.',
      single_scan_text_28: 'Puede',
      single_scan_text_29: 'Resaltar',
      single_scan_text_30: 'y Desmarcar cualquier',
      single_scan_text_31: 'mapa de grosor de ETDRS y retina',
      single_scan_text_32:
        'Seleccionar vista del mapa de grosor de retina o vista estándar',
      single_scan_text_33: 'grosor promedio de 1/3/6 mm',
      single_scan_text_34: 'Escaneo único',
      single_scan_text_35: 'Grosor GCC',
      single_scan_text_36: 'Grosor de capa DE / A',
      single_scan_text_37: 'Grosor de capa único',
      single_scan_text_38: 'Empecemos con',
      single_scan_text_39: 'Escaneo único',
    },
  },
  common: {
    eyeType: {
      OS: 'Left (OS)',
      OD: 'Right (OD)',
    },
  },
};

export default translation;
