import React from 'react';

import cn from 'classnames';

import { ReactComponent as ErrorIcon } from '../icons/concern_level_red.svg';

import styles from 'pages/DemoUserRegistration/FirstStep/FirstStep.module.scss';

import s from './ErrorComponent.module.scss';

interface Props {
  className?: string;
  children?: React.ReactNode;
  message?: string;
}

export default function ErrorComponent({ className = '', children }: Props) {
  return (
    <div className={cn(s.ErrorComponent, className)}>
      <ErrorIcon width={12} height={12} />
      <span className={cn(styles.Message)}>{children}</span>
    </div>
  );
}
